export function getFormattedPrevYrRank(prevYrRankString) {
    if (prevYrRankString) {
        const yrRankArray = prevYrRankString.split(',');
        const res = yrRankArray.map(item => {
            const [year, rank] = item.split(':');
            return {
                year: parseInt(year, 10),
                rank: !isNaN(rank)? parseInt(rank, 10) : "None"
            }
        })
        return res;
    }
    return [];
}