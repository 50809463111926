import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import Button from "@mui/material/Button";

export default function ConfirmationDialog({
  open,
  onClose,
  title,
  message,
  onConfirm,
}) {
  return (
    <Dialog aria-label="Confirmation Dialog" open={open} onClose={onClose}>
      <DialogTitle aria-label="Dialog Title" id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText aria-label="Dialog Message" id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button aria-label="Close" onClick={onClose} autoFocus>
          No
        </Button>
        <Button aria-label="Confirm" onClick={onConfirm} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
