import React from 'react';

import addProjectFormModel from "../AddNewProject/FormModel/AddProjectFormModel";

import { getFormattedDate } from "../../../utils/getFormattedDateTime";

import { StyledGridContainer, StyledPaper } from "../styles"
import { Typography, Grid, ListItemText, ListItem, List } from '@mui/material';


const { formField } = addProjectFormModel;

function ProjectPhaseDetails(props) {
  const {
    formValues }
    = props;

  return (
    <Grid item xs={12} md={12}>
      <StyledPaper elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List >
              <ListItem>
                <ListItemText
                  aria-label="Description"
                  primary={<b>Description</b>}
                  secondary={formValues.description || "None"}
                />
              </ListItem>
            </List>
          </Grid>

          {formValues.projectDetails?.length > 0 && (
            <>
              <StyledGridContainer container spacing={2} sx={{ marginBottom: "25px", marginLeft: "16px" }}>
                <Grid item xs={1} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                  Phase #
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                  CSJ/Plan ID #
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                  Let Date
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                  Completion Date
                </Grid>
                <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                  Cost Estimate
                </Grid>
                {formValues.isProjectStudy=="Yes" && (
                  <Grid item xs={3} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                    Study Cost
                  </Grid>
                )}
              </StyledGridContainer>

              {formValues.projectDetails.map((row, index) =>
                <StyledGridContainer container spacing={2} sx={{ paddingBottom: "25px", marginLeft: "16px" }} key={index}>
                  <Grid item xs={1}>
                    <Typography variant="body2">
                      {row.phase}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {row.csjNumber || "None"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {row.letDate ? getFormattedDate(new Date(row.letDate)) : "None"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {row.completionDate ? getFormattedDate(new Date(row.completionDate)) : "None"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2">
                      {row.costEstimate || "None"}
                    </Typography>
                  </Grid>
                  {formValues.isProjectStudy=="Yes" && (
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {row.studyCost || "None"}
                      </Typography>
                    </Grid>
                  )}
                </StyledGridContainer>

              )}
            </>
          )}
        </Grid>
      </StyledPaper>
    </Grid>

  );
}

export default ProjectPhaseDetails;
