import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";

//context
import { useAuth } from "../../context/AuthContext";

export default function InputDialog({
  open,
  onClose,
  title,
  type,
  confirmationBtnName,
  onConfirm,
  inputField1: initialInputField1,
  inputField2: initialInputField2,
  inputField3: initialInputField3,
  regionsDrpDwnVal = null,
  strategyTypesDrpDwnVal = null
}) {
  const { auth } = useAuth();
  const [inputField1, setInputField1] = useState(initialInputField1 || "");
  const [inputField2, setInputField2] = useState(initialInputField2 || "");
  const [inputField3, setInputField3] = useState(initialInputField3 || "");

  useEffect(() => {
    setInputField1(initialInputField1);
    setInputField2(initialInputField2);
    setInputField3(initialInputField3);
  }, [initialInputField1, initialInputField2, initialInputField3]);

  //Invoked when value in input field 1 changes.
  const handleInputField1Change = (event) => {
    setInputField1(event.target.value);
  };

  //Invoked when value in input field 2 changes.
  const handleInputField2Change = (event) => {
    setInputField2(event.target.value);
  };

  //Invoked when value in input field 3 changes.
  const handleInputField3Change = (event) => {
    setInputField3(event.target.value);
  };

  const handleCancel = (event) => {
    setInputField1("");
    setInputField2("");
    setInputField3("");
    onClose();
  };

  const handleConfirm = (event) => {
    onConfirm(inputField1, inputField2, inputField3, () => {
      setInputField1("");
      setInputField2("");
      setInputField3("");
    });
  };

  const label1 = (() => {
    if (type && type === "Strategy Types") {
      return "Strategy Name";
    }
    else if (type && type === "Region") {
      return "Region Name";
    }
    else {
      return "";
    }
  })();

  const label3 = (() => {
    if (type && type === "Strategy Types") {
      if (strategyTypesDrpDwnVal) {
        const strategyTypesNone = ["None"]
        const strategyTypesDropDwnOptions = strategyTypesDrpDwnVal.map(type => type.name);
        return ["Parent Category", [...strategyTypesNone, ...strategyTypesDropDwnOptions]]
      }
      else
        return null;
    }
    else if (type && type === "Region") {
      if (regionsDrpDwnVal) {
        const sisterRegionNone = ["None"]
        const sisterRegionOptions = regionsDrpDwnVal.map(reg => reg.name);
        return ["Sister Region", [...sisterRegionNone, ...sisterRegionOptions]];
      }
      else
        return null;
    }
    else
      return null;
  })();

  return (
    <Dialog aria-label="Confirmation Dialog" open={open} onClose={onClose}>
      <DialogTitle aria-label="Dialog Title" id="alert-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>

          {/* Label1 and Textfield */}
          <Grid item xs={6}>
            <InputLabel aria-label={label1}>{label1}  <em style={{ color: 'red' }}>*</em></InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="name"
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              value={inputField1}
              onChange={handleInputField1Change}
            />
          </Grid>

          {/* Description Label and Textfield */}
          <Grid item xs={6}>
            <InputLabel aria-label="description">Description: </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="description-textBox"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={inputField2}
              onChange={handleInputField2Change}
            />
          </Grid>

          {/* Label 3 and Drop Down */}
          {label3 && (
            <>
              <Grid item xs={6}>
                <InputLabel aria-label={label3[0]}>
                  {label3[0]}
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  id="dropDown"
                  label={label3[0]}
                  fullWidth
                  variant="outlined"
                  value={inputField3}
                  onChange={handleInputField3Change}
                >
                  {label3[1].length > 0 && label3[1].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button aria-label="Close" onClick={handleCancel} autoFocus>
          Cancel
        </Button>
        <Button aria-label="Confirm" onClick={handleConfirm} color="primary">
          {confirmationBtnName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
