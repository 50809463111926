import React from 'react';
import { Grid, ListItemText, ListItem, List } from '@mui/material';
import { StyledPaper } from "../styles"

import addProjectFormModel from "../AddNewProject/FormModel/AddProjectFormModel"

//utils
import { getCombRegImpactedRegs } from '../../../utils/getCombRegImpactedRegs';

const { formField } = addProjectFormModel;

function ProjectInfoDetails(props) {

  const {
    formValues, type }
    = props;

  //Set region value as per component
  if (type === "edit") {
    formValues.isProjectStudy = typeof (formValues.isProjectStudy) === "boolean"
      ? formValues.isProjectStudy
        ? "Yes" : "No"
      : formValues.isProjectStudy;
  }
  const formStrategyTypes = Array.isArray(formValues?.strategyTypes)
    ? formValues.strategyTypes.map(type => type.name).join(",")
    : formValues.strategyTypes;

  //For display, combine regions and impacted regions
  const formImpactedRegions = getCombRegImpactedRegs(formValues);

  return (
    <Grid item xs={12} md={12}>
      <StyledPaper elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}>
            <List >
              <ListItem>
                <ListItemText
                  aria-label="Project title"
                  primary={<b>Project Title</b>}
                  secondary={formValues.projectTitle || "None"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  aria-label="Project From"
                  primary={<b>Project From</b>}
                  secondary={formValues.projectFrom || "None"}
                />
              </ListItem>


              <ListItem>
                <ListItemText
                  aria-label="Strategy Type(s)"
                  primary={<b>Strategy Type(s)</b>}
                  secondary={formStrategyTypes || "None"}
                />
              </ListItem>


            </List>
          </Grid>
          <Grid item xs={4} md={4}>
            <List >
              <ListItem>
                <ListItemText
                  aria-label="Project Type"
                  primary={<b>Project Type</b>}
                  secondary={formValues.projectType || "None"}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  aria-label="Is this project a study?"
                  primary={<b>Is this project a study?</b>}
                  secondary={formValues.isProjectStudy}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  aria-label="Planning Document"
                  primary={<b>Planning Document</b>}
                  secondary={formValues.planningDocument || "None"}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={4} md={4}>
            <List >
              <ListItem>
                <ListItemText
                  aria-label="Project To"
                  primary={<b>Project To</b>}
                  secondary={formValues.projectTo || "None"}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  aria-label="Website"
                  primary={<b>Project Website</b>}
                  secondary={formValues.website || "None"}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  aria-label="Impacted Region(s)"
                  primary={<b>Impacted Region(s)</b>}
                  secondary={formImpactedRegions}
                />
              </ListItem>

            </List>
          </Grid>
        </Grid>
      </StyledPaper>
    </Grid>
  );
}

export default ProjectInfoDetails;
