import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { InputField, SelectField, RadioGroup } from '../../FormFields';
import MultiSelectField from '../../FormFields/MultiSelectField';
import MultiCheckboxField from '../../FormFields/MultiCheckboxField';

//context
import { useFormikContext } from 'formik';

//custom hook
import { useFetch } from '../../../hooks/useFetch';

//constants
import { API_URLS } from '../../../Constants';

const planningDocumentData = [
  { value: "UTP/STIP", label: "UTP/STIP" },
  { value: "MTP", label: "MTP" },
  { value: "Both", label: "Both" }
]

const projectTypesData = [
  { value: "Road", label: "Road" },
  { value: "Regional", label: "Regional" },
  { value: "Transit", label: "Transit" },
  { value: "Regional Transit", label: "Regional Transit" }
]

export default function ProjectInfo(props) {
  const { setFieldValue, values } = useFormikContext();
  const fetcher = useFetch("get", API_URLS["API_GET_ALL_REGIONS"]);
  const fetcherStrategyTypes = useFetch("get", API_URLS["API_GET_ALL_STRATEGY_TYPES"]);
  const [regionsDetails, setRegionsDetails] = useState([]);
  const [regionsData, setRegionsData] = useState([]);
  const [impactedRegionsData, setImpactedRegionsData] = useState([]);
  const [strategyTypesData, setStrategyTypesData] = useState([]);
  const {
    formField: {
      projectTitle,
      planningDocument,
      isProjectStudy,
      regions,
      impactedRegions,
      strategyTypes,
      projectType,
      projectTo,
      projectFrom,
      website } } = props;

  const getImpactedRegions = (selectedRegions) => {
    const allSisterRegions = regionsDetails
      .filter(region => selectedRegions.includes(region.id))
      .map(region => ({ sisterRegions: region.sisterRegion.filter(reg => reg.id !== region.id) }));
    const res = allSisterRegions.filter(reg => reg.sisterRegions.length > 0).flatMap(reg => reg.sisterRegions);
    return res;
  }

  //Fetch data from API when the component renders.
  useEffect(() => {

    //Invoke getAllRegions API
    fetcher.setRequest({});

    //Invoke getAllStrategytypes API
    fetcherStrategyTypes.setRequest({});
  }, []);

  //Once response is received, set appropriate state variables.
  useEffect(() => {
    const response = fetcher.serverResponse;
    if (response) {
      setRegionsDetails(response);
    }
  }, [fetcher.serverResponse]);

  //Once response is received, set appropriate state variables.
  useEffect(() => {
    const response = fetcherStrategyTypes.serverResponse;
    if (response?.success && response.data) {
      const sortedStrategyTypes = response.data.toSorted((a, b) => a.name.localeCompare(b.name));
      setStrategyTypesData(sortedStrategyTypes);
    }
  }, [fetcherStrategyTypes.serverResponse]);

  useEffect(() => {
    if (regionsDetails?.length > 0) {
      const regions = regionsDetails
        .filter(region => region.id !== "0")  //filter out all from values
        .map(region => {
          return {
            id: region.id,
            name: region.name
          }
        });
      const sortedRegions = regions.toSorted((a, b) => a.name.localeCompare(b.name));
      setRegionsData(sortedRegions);

      //If impactedRegions are already in form values
      if (values?.regions?.length > 0) {
        const selectedRegions = values.regions.map(region => region.id);
        const allImpactedRegions = getImpactedRegions(selectedRegions);
        if (values?.impactedRegions?.length > 0 && allImpactedRegions.length > 0) {
          const formImpactedRegions = values.impactedRegions.filter(reg => reg.id !== null);
          const combinedArray = [...allImpactedRegions, ...formImpactedRegions];
          const noDuplicatesArray = Array.from(new Map(combinedArray.map(item => [item.id, item])).values());
          setImpactedRegionsData(noDuplicatesArray);
        }
        else
          setImpactedRegionsData(allImpactedRegions);
      }
    }
  }, [regionsDetails]);

  //Set selected region whenever value in region drop down changes
  const handleRegionChange = (selectedRegions) => {
    const impactedRegions = getImpactedRegions(selectedRegions);

    //Update form values with only present items in form
    const presentItems = values.impactedRegions.filter(item => impactedRegions.includes(item));
    setFieldValue("impactedRegions", presentItems);
    setImpactedRegionsData(impactedRegions);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <InputField name={projectTitle.name} tooltip={projectTitle.label} label={projectTitle.label} fullWidth />
      </Grid>

      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <SelectField
          tooltip={projectType.label}
          name={projectType.name}
          label={projectType.label}
          data={projectTypesData}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <InputField name={projectTo.name} tooltip={projectTo.label} label={projectTo.label} fullWidth />
      </Grid>

      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <InputField name={projectFrom.name} tooltip={projectFrom.label} label={projectFrom.label} fullWidth />
      </Grid>

      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <RadioGroup
          tooltip={isProjectStudy.label}
          name={isProjectStudy.name}
          label={isProjectStudy.label}
          data={[{ label: "No", value: 'No' }, { label: "Yes", value: 'Yes' }]}
          fullWidth
        >
        </RadioGroup>
      </Grid>

      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <MultiSelectField
          tooltip={strategyTypes.label}
          name={strategyTypes.name}
          label={strategyTypes.label}
          data={strategyTypesData}
          helperText= "Select all that apply"
          type="strategyTypes"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <MultiSelectField
          tooltip={regions.label}
          name={regions.name}
          label={regions.label}
          data={regionsData}
          helperText= "Select all that apply"
          type="regions"
          onChange={(selected) => handleRegionChange(selected)}
          fullWidth
        />
      </Grid>

      {impactedRegionsData?.length > 0 && (
        <Grid item xs={12} sx={{ marginRight: "10px" }}>
          <MultiCheckboxField
            tooltip="Does this impact any other region(s)?"
            name={impactedRegions.name}
            label="Does this impact any other region(s)?"
            data={impactedRegionsData}
            fullWidth
          />
        </Grid>
      )}

      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <SelectField
          name={planningDocument.name}
          label={planningDocument.label}
          tooltip={planningDocument.label}
          data={planningDocumentData}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <InputField name={website.name} tooltip={website.label} label={website.label} fullWidth />
      </Grid>

    </Grid>
  );
}

