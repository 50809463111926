const userAgencyOptions = [
    { value: "TxDOT", label: "TxDOT" },
    { value: "MPO or COG", label: "MPO or COG" },
    { value: "TTI", label: "TTI" },
    { value: "Other", label: "Other" }
];

const USER_ACCESS_LEVEL = {
    "superAdminAccess": [
        ["All", "All", "All", "All"], //Projects
        ["All", "All", "All", "All"], //Strategy Types
        ["All", "All", "All", "All"], //Roadways
        ["All", "All", "All", "All"], //Regions
        ["All", "All", "All", "All"] //Users
    ],
    "regionalAdminAccess": [
        ["Regional", "Regional", "Regional", "Regional"], //Projects
        ["NA", "NA", "NA", "NA"], //Strategy Types
        ["Regional", "NA", "NA", "NA"], //Roadways
        ["NA", "NA", "NA", "NA"], //Regions
        ["Regional", "Regional", "Regional", "Regional"] //Users
    ],
    "ttiUserAccess": [
        ["All", "All", "All", "All"], //Projects
        ["NA", "NA", "NA", "NA"], //Strategy Types
        ["All", "NA", "NA", "NA"], //Roadways
        ["NA", "NA", "NA", "NA"], //Regions
        ["All", "NA", "NA", "NA"] //Users
    ],
    "regionalUserAccess": [
        ["Regional", "Regional", "Regional", "Regional"], //Projects
        ["NA", "NA", "NA", "NA"], //Strategy Types
        ["Regional", "NA", "NA", "NA"], //Roadways
        ["NA", "NA", "NA", "NA"], //Regions
        ["NA", "NA", "NA", "NA"] //Users
    ],
}

const TOKEN_EXPIRED = "Token Expired"

const API_URLS = {

    //dashboard
    "API_GET_DASHBOARD_STATS": "/api/dashboard/getAllStats",

    //projects
    "API_GET_PROJECTS": "/api/projects/getAllProjects",
    "API_GET_PROJECT_BY_ID": "/api/projects/getProjectById",
    "API_ADD_UPDATE_PROJECT": "/api/projects/addOrUpdateProject",
    "API_DELETE_PROJECTS": "/api/projects/deleteProjects",

    //strategy types
    "API_GET_ALL_STRATEGY_TYPES": "/api/strategyTypes/getAllStrategyTypes",
    "API_GET_STRATEGY_TYPE_BY_ID": "/api/strategyTypes/getStrategyTypesById",
    "API_ADD_UPDATE_STRATEGY_TYPE": "/api/strategyTypes/addOrUpdateStrategyType",
    "API_DELETE_STRATEGY_TYPES": "/api/strategyTypes/",

    //regions
    "API_GET_ALL_REGIONS": "/api/regions/getAllRegions",
    "API_GET_REGION_BY_ID": "/api/regions/getRegionById",
    "API_ADD_UPDATE_REGION": "/api/regions/addOrUpdateRegion",
    "API_DELETE_REGIONS": "/api/regions/",

    //roadways   
    "API_GET_ALL_ROADWAYS": "/api/roadways/getAllRoadways",
    "API_GET_ROADWAY_BY_ID": "/api/roadways/getRoadById",
    "API_UPDATE_ROADWAY": "/api/roadways/updateRoadway",
    "API_DOWNLOAD_PDF": "/pdf/roadways",

    //Texas100 monitored roadways
    "GET_TEXAS100_MONITORED_ROADWAYS": "/api/roads",
    "GET_TOP_105_ROADWAYS": "/api/roads/top105Roads",
    "GET_TOP_100_ROADWAYS_DETAILS": "/api/roads/top100Roads",

    //users
    "API_USERS": "/api/users",
    "API_RESET_PWD": "/api/password/reset",
    "API_CHANGE_PWD": "/api/password/change"
}

const CONTACT_US_MAIL_TO = "mailto:mitigation-plan-admin@tti.tamu.edu";

const NO_SIDEBAR_LOGOUT_PAGES = ["/", "/top100Roadways", "/login", "/reset-password"];
const HEADER_CONSTANTS = {
    MITIGATION_PLAN_SYSTEM: "TxDOT Mitigation Plan System 2.0",
    MITIGATION_PLANS: "The Top 100 Congested Road Segments Mitigation Plans",
    WEBSITE_HEADER: ["/", "/top100Roadways"]
};
const EXTERNAL_LINKS = {
    "tx100OfficialSite": "https://mobility.tamu.edu/texas-most-congested-roadways/"
}

const NAV_BUTTON_GROUP = [
    { "mitigationPlans": "Mitigation Plans for the Top 100" },
    { "tx100OfficialSite": "Texas’ Most Congested Roadways Official Site" },
    { "login": "TxDOT Mitigation Plan System Login" },
    { "home": "Home" }
]

const CONTACT_CARDS = [
    {
        "name": "Ibrahima Tembely",
        "agency": "Texas Department of Transportation",
        "email": "ibrahima.tembely@txdot.gov",
        "phoneNumber": "(512) 416-4627"
    },
    {
        "name": "Luke Albert",
        "agency": "Texas A&M Transportation Institute",
        "email": "l-albert@tti.tamu.edu",
        "phoneNumber": "(979) 317-2480"
    },
    {
        "name": "Phil Lasley",
        "agency": "Texas A&M Transportation Institute",
        "email": "p-lasley@tti.tamu.edu",
        "phoneNumber": "(512) 407-1113"
    }
]

const BYPASS_AUTHORIZATION_LIST = [API_URLS["GET_TEXAS100_MONITORED_ROADWAYS"], API_URLS["API_RESET_PWD"], API_URLS["API_CHANGE_PWD"], API_URLS["GET_TOP_100_ROADWAYS_DETAILS"]];

export { userAgencyOptions, USER_ACCESS_LEVEL, TOKEN_EXPIRED, API_URLS, CONTACT_US_MAIL_TO, NO_SIDEBAR_LOGOUT_PAGES, HEADER_CONSTANTS, EXTERNAL_LINKS, BYPASS_AUTHORIZATION_LIST, NAV_BUTTON_GROUP, CONTACT_CARDS };