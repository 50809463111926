import * as React from 'react';

import { Paper, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import { RadioGroup } from '../../FormFields';

export default function ProjectStatus(props) {
    const {
        formField: {
            feasibilityStudy,
            preliminaryDesign,
            nepaReview,
            finalDesign,
            funding,
            rowAcquisition,
            construction,
            scopeIdentified,
            fundingAcquired,
            researcherIdentified,
            studyInProgress,
            studyComplete,
        }, values } = props;
    return (
        <Grid item xs={12} sx={{ marginRight: "10px" }}>
         
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ minWidth: 170 }} >  </TableCell>
                                <TableCell style={{ minWidth: 170 }} > Not Begun </TableCell>
                                <TableCell style={{ minWidth: 170 }} > Stalled </TableCell>
                                <TableCell style={{ minWidth: 170 }} > In Progress </TableCell>
                                <TableCell style={{ minWidth: 170 }} > Complete </TableCell>
                            </TableRow>
                        </TableHead>

                    {values.isProjectStudy === 'Yes' ?
                        (<TableBody>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {scopeIdentified.label} </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={scopeIdentified.name}

                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={scopeIdentified.name}

                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={scopeIdentified.name}

                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={scopeIdentified.name}

                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {fundingAcquired.label} </TableCell>

                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={fundingAcquired.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={fundingAcquired.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={fundingAcquired.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={fundingAcquired.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {researcherIdentified.label} </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={researcherIdentified.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={researcherIdentified.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={researcherIdentified.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={researcherIdentified.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >{studyInProgress.label} </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={studyInProgress.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={studyInProgress.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={studyInProgress.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={studyInProgress.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {studyComplete.label}</TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={studyComplete.name}

                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={studyComplete.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={studyComplete.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={studyComplete.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                        </TableBody>)

                        :

                        (<TableBody>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} id="feasibilityStudy-radio-buttons"> {feasibilityStudy.label} </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={feasibilityStudy.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={feasibilityStudy.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={feasibilityStudy.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={feasibilityStudy.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {preliminaryDesign.label} </TableCell>

                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={preliminaryDesign.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={preliminaryDesign.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={preliminaryDesign.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={preliminaryDesign.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {nepaReview.label} </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={nepaReview.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={nepaReview.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={nepaReview.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={nepaReview.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {finalDesign.label} </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={finalDesign.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={finalDesign.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={finalDesign.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={finalDesign.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {funding.label}</TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={funding.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={funding.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={funding.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={funding.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {rowAcquisition.label} </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={rowAcquisition.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={rowAcquisition.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={rowAcquisition.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={rowAcquisition.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align={'center'} style={{ minWidth: 170 }} > {construction.label} </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={construction.name}
                                        data={[{ label: '', value: 'Not Begun' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={construction.name}
                                        data={[{ label: '', value: 'Stalled' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={construction.name}
                                        data={[{ label: '', value: 'In Progress' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                                <TableCell align={'center'} style={{ minWidth: 170 }} >
                                    <RadioGroup
                                        name={construction.name}
                                        data={[{ label: '', value: 'Complete' }]}
                                        fullWidth >
                                    </RadioGroup>
                                </TableCell>
                            </TableRow>

                        </TableBody>)}

                    </Table>
                </TableContainer>
         
        </Grid>
    );
}