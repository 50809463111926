import React from 'react';
import { Paper } from '@mui/material';
import { StyledTypography, StyledOuterDiv, StyledInnerDiv } from './styles';

const Legend = () => {
    return (
        <Paper style={{ padding: '10px', display: 'flex', marginRight: "10px" }}>
            <div aria-label="legend" style={{ display: 'flex' }}>

                {/* Texas 100 Monitored Roads Legend */}
                <StyledOuterDiv>
                    <StyledInnerDiv style={{ backgroundColor: '#0C7BDC' }}></StyledInnerDiv>
                    <StyledTypography variant="body2">Texas 100 Monitored Roads</StyledTypography>
                </StyledOuterDiv>

                {/* Top 100 Segments Legend */}
                <StyledOuterDiv>
                    <StyledInnerDiv style={{ backgroundColor: '#FFC20A' }}></StyledInnerDiv>
                    <StyledTypography variant="body2">Top 100 Segments</StyledTypography>
                </StyledOuterDiv>

                {/* Added Roadway Segments Legend */}
                <StyledOuterDiv>
                    <StyledInnerDiv style={{ backgroundColor: '#029356' }}></StyledInnerDiv>
                    <StyledTypography variant="body2">Added Roadway Segments</StyledTypography>
                </StyledOuterDiv>
            </div>
        </Paper>
    );
};

export default Legend;