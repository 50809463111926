import React from 'react';
import { useField } from 'formik';
import Tooltip from '@mui/material/Tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
 import { TextField } from '@mui/material';

export default function DatePickerField(props) {
  const { label, name, errorText, tooltip, ...rest } = props;
  const [field, meta, helpers] = useField(name); // Using the `name` prop
  const { setValue } = helpers;

  // Ensure the value is converted to a Day.js object or null if invalid
  const fieldValue = field.value ? dayjs(field.value) : null;

  // Helper function to display validation errors
  function renderHelperText() {
    if (meta.touched && meta.error) {
      return meta.error;
    }
    return '';
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Tooltip title={tooltip || ''}>
        <DatePicker
          {...field} // Pass Formik's field props
          {...rest}  // Pass any additional props
          value={fieldValue} // Controlled value
          onChange={(value) => {
            if (value) {
              setValue(value.format('YYYY-MM-DD'), true); // Update Formik value
            } else {
              setValue(null, true); // Handle clearing the field
            }
          }}
          inputFormat="MM/DD/YYYY"
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={meta.touched && Boolean(meta.error)}
              helperText={renderHelperText()}
              fullWidth
            />
          )}
        />
      </Tooltip>
    </LocalizationProvider>
  );
}
