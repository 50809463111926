import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { StyledDataGrid } from "./styles";
import {
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
    gridStringOrNumberComparator
} from "@mui/x-data-grid";
import {
    //Delete as DeleteIcon,
    Edit as EditIcon,
    Visibility as ViewIcon,
    PictureAsPdf as DownloadPDF
} from "@mui/icons-material";

//Components
import RouterBreadcrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";
import DownloadProgressDialog from "../../components/Dialog/DownloadProgressDialog";
import InformationDialog from "../../components/Dialog/InformationDialog";

//utils
import { getFormattedDate } from '../../utils/getFormattedDateTime';
import { getLatestYearRank } from "../../utils/getLatestYearRank";

//custom hook
import { useFetch } from "../../hooks/useFetch";

//constants
import { API_URLS } from "../../Constants";

//context
import { useAuth } from "../../context/AuthContext";
import { useUpdate } from "../../hooks/useUpdate";

const pathItems = [
    "Dashboard",
    "View Roadways"
]

export default function ViewRoadways() {
    const [roadwaysData, setRoadwaysData] = useState([]);
    const fetcher = useFetch("get", API_URLS["API_GET_ALL_ROADWAYS"]);
    const downloadFetcher = useUpdate("post", API_URLS["API_DOWNLOAD_PDF"]);
    const [downloadProgressDialog, setDownloadProgressDialog] = useState({ open: false });
    const [informationDialog, setInformationDialog] = useState({
        open: false,
        title: "",
        message: "",
        onConfirm: () => { },
    });
    const navigate = useNavigate();

    //Fetch data from API when the component renders.
    useEffect(() => {
        fetcher.setRequest({});
    }, []);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = fetcher.serverResponse;
        if (response?.success && response?.data?.length > 0) {
            const roadwaysData = response.data;

            //Sort alphabetically by roadway title
            const sortedRoadways = roadwaysData.toSorted((a, b) => a.roadwayTitle.localeCompare(b.roadwayTitle));
            setRoadwaysData(sortedRoadways || []);
        }
    }, [fetcher.serverResponse]);

    useEffect(() => {
        const response = downloadFetcher.serverResponse;
        if (response?.success && response.data) {
            downloadPDF(response);
        }
    }, [downloadFetcher.serverResponse]);

    useEffect(() => {
        if (downloadFetcher?.serverError?.message) {

            //close download progress dialog
            setDownloadProgressDialog((prevState) => ({ ...prevState, open: false }))

            setInformationDialog({
                open: true,
                title: "Error",
                message: 'Some error occured. Please try again.',
                onClose: () => {
                    setInformationDialog((prevState) => ({ ...prevState, open: false }));
                }
            });
        }

    }, [downloadFetcher.serverError])

    const downloadPDF = async (response) => {
        try {

            // Decode Base64 data
            const binaryString = atob(response.data);
            const binaryData = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                binaryData[i] = binaryString.charCodeAt(i);
            }
            // Use the filename from the response
            const filename = response.filename;
            const fileType = filename.endsWith('.zip') ? 'application/zip' : 'application/pdf';

            // Create a Blob from the binary data
            const blob = new Blob([binaryData], { type: fileType });
            const url = window.URL.createObjectURL(blob);

            // Create a download link
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);

            //close confirmation dialog
            setDownloadProgressDialog((prevState) => ({ ...prevState, open: false }))
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    //React Hooks
    const [selectedRows, setSelectedRows] = useState([]);
    const [confirmationDialog, setConfirmationDialog] = useState({ open: false });

    //Get loggedIn user details
    const { auth } = useAuth();
    const loggedInUser = auth?.user?.privileges || null;
    if (!loggedInUser) return;
    const showEditAction = loggedInUser?.roadways.includes("update");
    //const showDelAction = loggedInUser?.roadways.includes("delete");
    const showViewAction = loggedInUser?.roadways.includes("view");

    //when download pdf button is clicked
    const onClickDownloadPDF = async (segmentIds) => {
        if (segmentIds?.length > 100) {
            setInformationDialog({
                open: true,
                title: "Error",
                message: 'Please select upto 100 segments at a time.',
                onClose: () => {
                    setInformationDialog((prevState) => ({ ...prevState, open: false }));
                }
            });
        }
        else {
            setDownloadProgressDialog({
                open: true,
                message: 'Downloading file(s)'
            });

            //call API to download pdf
            await downloadFetcher.executeUpdate({
                "segmentIds": segmentIds
            });
        }
    }

    //Data Grid columns
    const columns = [
        {
            field: 'segmentId',
            headerName: 'Segment ID',
            width: 150,
            filterable: true,
            valueGetter: (params) => parseInt(params.row.segmentId) || 0
        },
        {
            field: "roadwayTitle",
            headerName: "Roadway Title",
            width: 450,
            filterable: true,
            renderCell: (params) => {

                const handleTitleClick = (segmentId) => {
                    const backendPageUrl = `${window.location.origin}/pages/roadway/${segmentId}`;

                    // Open the backend URL in a new tab
                    window.open(backendPageUrl, "_blank");
                };

                return (
                    <Button
                        onClick={() => handleTitleClick(params.row.segmentId)}
                        variant="text"
                        style={{
                            fontSize: "16px",
                            textTransform: "none"
                        }}
                    >
                        <Typography aria-label="Roadway Title" variant="body2" sx={{ textAlign: "left", width: "100%" }}>
                            {params.row.roadwayTitle}
                        </Typography>
                    </Button>
                );
            },
        },
        {
            field: 'region',
            headerName: 'Region(s)',
            width: 130,
            filterable: true,
            valueGetter: (params) => params.row.region?.name || "None",
            renderCell: (params) => {
                const regionName = params.row.region && ('name' in params.row.region) ? params.row.region['name'] : "None"
                return (
                    <Typography aria-label="Region name" variant="body2" key={params.id}>
                        {regionName}
                    </Typography>
                );
            }
        },
        {
            field: 'rank',
            headerName: 'Rank',
            width: 100,
            filterable: true,
            valueGetter: (params) => parseInt(params.row.rank) || null
        },
        {
            field: 'previousRanks',
            headerName: 'Previous Rank',
            width: 150,
            filterable: false,
            renderCell: (params) => {
                const previousYearRank = getLatestYearRank(params.row.previousRanks);
                return (
                    <Typography aria-label="Previous Rank" variant="body2">
                        {previousYearRank}
                    </Typography>
                );
            },
            valueGetter: (params) => {
                return getLatestYearRank(params.row.previousRanks);
            }
        },
        {
            field: "lastModified",
            headerName: "Last Modified",
            width: 150,
            filterable: false,
            renderCell: (params) => {
                const lastModifiedDate = getFormattedDate(new Date(params.row.lastModifiedDate));
                const lastModifiedBy = params.row.lastModifiedBy ? params.row.lastModifiedBy.split('@')[0] || params.row.lastModifiedBy : "";
                return (
                    <div style={{ whiteSpace: 'normal' }}>
                        <Typography aria-label="Last Modified Date" variant="body2">
                            {lastModifiedDate}
                        </Typography>
                        <Typography aria-label="Last Modified By" variant="body2">
                            {lastModifiedBy}
                        </Typography>
                    </div>
                );
            },
            valueGetter: (params) => {

                // Return a combined value that contains both date and last modified by
                const lastModifiedDate = new Date(params.row.lastModifiedDate);
                const lastModifiedBy = params.row.lastModifiedBy ? params.row.lastModifiedBy.split('@')[0] || params.row.lastModifiedBy : "";
                const formattedDate = getFormattedDate(lastModifiedDate);
                return `${formattedDate} ${lastModifiedBy}`;
            },
            sortComparator: (v1, v2) => {
                const [date1, user1] = v1.split(" "); // Split into date and user
                const [date2, user2] = v2.split(" "); // Split into date and user

                const parsedDate1 = new Date(date1); // Parse the date part for sorting
                const parsedDate2 = new Date(date2); // Parse the date part for sorting

                // First compare by date
                if (!isNaN(parsedDate1) && !isNaN(parsedDate2)) {
                    if (parsedDate1.getTime() !== parsedDate2.getTime()) {
                        return parsedDate1 - parsedDate2; // Sort by date
                    }
                }

                // If dates are the same, compare by lastModifiedBy (username)
                if (user1 < user2) {
                    return -1; // user1 comes before user2
                }
                if (user1 > user2) {
                    return 1; // user1 comes after user2
                }

                return 0; // If both date and user are the same
            }
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 150,
            getActions: (params) => {
                const actions = [];
                if (showViewAction) {
                    actions.push(
                        <GridActionsCellItem
                            aria-label="View Action"
                            icon={<IconsWithTooltip title="View Roadway" icon={<ViewIcon />} />}
                            label="View"
                            onClick={() => { navigate(`/view-selected-roadway`, { state: { segmentId: params.row.segmentId } }) }}
                        />
                    )
                }
                if (showEditAction) {
                    actions.push(
                        <GridActionsCellItem
                            aria-label="Edit Action"
                            icon={<IconsWithTooltip title="Update Roadway" icon={<EditIcon />} />}
                            label="Edit"
                            onClick={() => { navigate(`/edit-roadway`, { state: { segmentId: params.row.segmentId, navFromView: true } }) }}
                        />
                    )
                }
                actions.push(
                    <GridActionsCellItem
                        aria-label="Download Mitigation Plans PDF Action"
                        icon={<IconsWithTooltip title="Download Mitigation Plans PDF" icon={<DownloadPDF />} />}
                        label="Download Mitigation Plans PDF"
                        onClick={() => { onClickDownloadPDF([params.row.segmentId]) }}
                    />
                )
                /*if (showDelAction) {
                    actions.push(
                        <GridActionsCellItem
                            aria-label="Delete Action"
                            icon={<IconsWithTooltip title="Delete roadway" icon={<DeleteIcon />} />}
                            label="Delete"
                            onClick={() => handleDeleteSelected([params.row.segmentId])}
                            showInMenu
                        />
                    )
                }*/
                return actions;
            },
        },
    ];

    //Custom toolbar to return number of records alongwith filter and export options.
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div style={{ marginRight: "15px" }}>Total Records: {roadwaysData.length}</div>
                <GridToolbarFilterButton />
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
                {selectedRows?.length > 1 && (
                    <Button sx={{ fontSize: "13px" }} startIcon={<DownloadPDF />} onClick={() => { onClickDownloadPDF(selectedRows) }}>Download Selected PDF</Button>
                )}
            </GridToolbarContainer>
        );
    }

    /*Roadway Deletion: Set action on clicking "Yes" for selected roadway(s).
    const handleDeleteConfirm = () => {
        setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
    };

    //Roadway Deletion: Set ConfirmationDialog properties for selected roadway(s).
    const handleDeleteSelected = (selectedIds) => {
        if (selectedIds && selectedIds.length > 0) {
            setConfirmationDialog({
                open: true,
                title: "Delete Confirmation",
                message: `Are you sure you want to delete the selected roadway(s) ?`,
                onConfirm: () => {
                    const updatedRows = roadwaysData.filter(row => !selectedIds.includes(row.segmentId));

                    //Update data table.
                    setRoadwaysData(updatedRows);

                    //Update data in the global context.
                    updateData(updatedRows);
                    setSelectedRows([]);
                    handleDeleteConfirm();
                },
            });
        }
    };*/
    return (
        <>
            {/* Breadcrumbs for the page */}
            <Grid item xs={12} md={12}>
                <RouterBreadcrumbs pathItems={pathItems} />
            </Grid>

            {/* Page Title for the page */}
            <Grid item xs={12} md={12}>
                <Title title="View Roadways" />
            </Grid>

            {/* Render Delete All button 
            <Grid item xs={12}>
                {selectedRows.length > 1 && (
                    <StyledTopButton
                        aria-label="Delete All Button"
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteSelected(selectedRows)}
                    >
                        Delete All
                    </StyledTopButton>
                )}
            </Grid>*/}

            {/* Display Data Table */}
            <Grid item xs={12} sx={{ paddingRight: 3 }}>
                <StyledDataGrid
                    rows={roadwaysData}
                    columns={columns}
                    loading={fetcher.isLoading}
                    getRowId={(row) => row.segmentId}
                    autoHeight
                    initialState={{
                        columns: {
                            sorting: {
                                sortModel: [
                                    {
                                        field: 'lastModified',
                                    },
                                ],
                            },
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 15, 20, 25]}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    selectedRows={selectedRows}
                />
            </Grid>

            {/* ConfirmationDialog component */}
            <ConfirmationDialog
                open={confirmationDialog.open}
                onClose={() =>
                    setConfirmationDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={confirmationDialog.title}
                message={confirmationDialog.message}
                onConfirm={confirmationDialog.onConfirm}
            />

            {/* Download Progress Dialog */}
            <DownloadProgressDialog
                open={downloadProgressDialog.open}
                message={downloadProgressDialog.message}
            />

            {/* Information Dialog component */}
            <InformationDialog
                open={informationDialog.open}
                title={informationDialog.title}
                message={informationDialog.message}
                onClose={informationDialog.onClose}
            />
        </>
    );
}