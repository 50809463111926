import { Dialog, DialogContent, DialogTitle, LinearProgress, Box } from "@mui/material";

export default function DownloadProgressDialog({
    open,
    message
}) {
    return (
        <Dialog aria-label="Download Dialog" open={open} >
            <DialogTitle aria-label="Dialog Title" id="alert-dialog-title">{message}</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </DialogContent>
        </Dialog>
    )
}