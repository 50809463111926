import React from 'react';
import { StyledGridContainer, StyledPaper } from "../styles"
import {Typography, Grid, } from '@mui/material';

function ConnectedRoadwaysDetails(props) {

  const {
    formValues }
    = props;
  const roadways = formValues.connectedRoadways;
  return (
    <Grid item xs={12} md={12}>
      <StyledPaper elevation={3}>
        {roadways && roadways.length > 0 ? (
          <>
            <StyledGridContainer container spacing={2} sx={{ marginBottom: "25px" }}>
              <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                Roadway Name
              </Grid>
              <Grid item xs={3} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                From Road
              </Grid>
              <Grid item xs={3} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                To Road
              </Grid>
              <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                Impact Area
              </Grid>
              <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                Rank
              </Grid>
            </StyledGridContainer>
            {roadways.map((row, index) =>
              <StyledGridContainer container spacing={2} sx={{ paddingBottom: "25px" }} key={index}>
                <Grid item xs={2}>
                  <Typography variant="body2">
                    {row.roadName || "None"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {row.fromRoad || "None"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {row.toRoad || "None"}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2">
                    {row.impactArea || "None"}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2">
                    {row.rank || "None"}
                  </Typography>
                </Grid>
              </StyledGridContainer>
            )}
          </>
        ) : (
          <StyledGridContainer container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">No connected roadways available.</Typography>
            </Grid>
          </StyledGridContainer>
        )}
      </StyledPaper>
    </Grid>

  );
}



export default ConnectedRoadwaysDetails;
