import React, { createContext, useState, useContext } from 'react';
export const ConnectedProjectsDataContext = createContext();

export const ConnectedProjectsDataProvider = ({ children }) => {
    const [connectedProjectsData, setConnectedProjectsData] = useState([]);

    //Update data as per component.
    const updateConnectedProjectsData = (newData) => {
        setConnectedProjectsData(newData);
    };

    return (
        <ConnectedProjectsDataContext.Provider value={{ connectedProjectsData, updateConnectedProjectsData }}>
            {children}
        </ConnectedProjectsDataContext.Provider>
    );
};

export const useConnectedProjectsData = () => {
    return useContext(ConnectedProjectsDataContext);
};