import addProjectFormModel from './AddProjectFormModel';
const {
    formField: {
        projectTitle,
        planningDocument,
        isProjectStudy,
        regions,
        impactedRegions,
        strategyTypes,
        projectType,
        projectTo,
        projectFrom,
        website,
        description,
        projectDetails,
        feasibilityStudy,
        preliminaryDesign,
        nepaReview,
        finalDesign,
        funding,
        rowAcquisition,
        construction,
        scopeIdentified,
        fundingAcquired,
        researcherIdentified,
        studyInProgress,
        studyComplete,
        connectedRoadways,
        notes,
        status,
        reviewedDate,
        reviewedBy,
        createdDate,
        lastModifiedDate,
        lastModifiedBy
    }
} = addProjectFormModel;

export default {
    [projectTitle.name]: '',
    [planningDocument.name]: '',
    [isProjectStudy.name]: '',
    regions: [],
    impactedRegions: [],
    strategyTypes: [],
    [projectType.name]: '',
    [projectTo.name]: '',
    [projectFrom.name]: '',
    [website.name]: '',

    [description.name]: '',
    projectDetails: [{
        [projectDetails.phaseNumber.name]: 1,
        [projectDetails.csjNumber.name]: '',
        [projectDetails.letDate.name]: '',
        [projectDetails.completionDate.name]: '',
        [projectDetails.costEstimate.name]: '',
        [projectDetails.studyCost.name]: '',
    }],

    [feasibilityStudy.name]: '',
    [preliminaryDesign.name]: '',
    [nepaReview.name]: '',
    [finalDesign.name]: '',
    [funding.name]: '',
    [rowAcquisition.name]: '',
    [construction.name]: '',

    [scopeIdentified.name]: '',
    [fundingAcquired.name]: '',
    [researcherIdentified.name]: '',
    [studyInProgress.name]: '',
    [studyComplete.name]: '',

    connectedRoadways: [],
    [notes.name]: '',
    [status.name]: '',
    [reviewedDate.name]: '',
    [reviewedBy.name]: '',
    [createdDate.name]: '',
    [lastModifiedDate.name]: '',
    [lastModifiedBy.name]: '',
};
