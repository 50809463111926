import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box } from "@mui/material";

//Import Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

//Import Pages
import ViewProjects from "../../pages/Projects/ViewProjects";
import Dashboard from "../../pages/Dashboard/Dashboard";
import EditProject from "../../pages/Projects/EditProject";
import ReviewProject from "../../pages/Projects/ReviewProject";
import StrategyTypes from "../../pages/Projects/StrategyTypes";
import AddNewProject from "../../pages/Projects/AddNewProject/index";
import Regions from "../../pages/Regions/Regions";
import Users from "../../pages/Users/Users";
import ViewRoadways from "../../pages/Roadways/ViewRoadways";
import EditRoadway from "../../pages/Roadways/EditRoadway";
import ConnectedProjects from "../../pages/Roadways/ConnectedProjects";
import LoginUser from "../../pages/Login/LoginUser";
import ViewSelectedRoadway from "../../pages/Roadways/ViewSelectedRoadway";
import HomePage from "../../pages/Home/HomePage";
import Top100RoadwaysPage from "../../pages/Home/Top100RoadwaysPage";
import ResetPassword from "../../pages/Login/ResetPassword";

//Import context
import { useAuth } from "../../context/AuthContext";
import { ConnectedProjectsDataProvider } from "../../context/ConnectedProjectsDataContext";

//constants
import { NO_SIDEBAR_LOGOUT_PAGES, HEADER_CONSTANTS } from "../../Constants";

export default function BasicLayout() {
  const { auth } = useAuth();
  const currentUserPrivileges = auth?.user?.privileges
    ? auth?.user?.privileges
    : null;
  const userRole = auth?.user?.roleName.toLowerCase() || "";
  const location = useLocation();
  const isNoSidebarLogoutPages = NO_SIDEBAR_LOGOUT_PAGES.includes(
    location.pathname
  );
  const headerTitle = HEADER_CONSTANTS.WEBSITE_HEADER.includes(
    location.pathname
  )
    ? HEADER_CONSTANTS.MITIGATION_PLANS
    : HEADER_CONSTANTS.MITIGATION_PLAN_SYSTEM;

  // Set the document title based on the header title
  useEffect(() => {
    document.title = headerTitle;
  }, [headerTitle]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header showLogout={!isNoSidebarLogoutPages} title={headerTitle} />
      <Box sx={{ display: "flex", flex: 1 }}>
        {!isNoSidebarLogoutPages && <Sidebar />}
        <Box
          sx={{ paddingTop: location.pathname === "/login" || location.pathname === "/reset-password" ? 0 : 12, flex: 1 }}
        >
          {/* Add Routing to different components based on path. */}
          <Routes>
            {/* Website routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/top100Roadways" element={<Top100RoadwaysPage />} />
            <Route path='/reset-password' element={<ResetPassword />} />

            {/* Portal routes */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<LoginUser />} />
            <Route path="/view-projects" element={<ViewProjects />} />
            <Route path="/edit-project" element={<EditProject />} />
            <Route path="/review-project" element={<ReviewProject />} />
            <Route path="/add-new-project" element={<AddNewProject />} />
            <Route path="/view-roadways" element={<ViewRoadways />} />
            <Route
              path="/view-selected-roadway"
              element={<ViewSelectedRoadway />}
            />

            {/* For roadways */}
            {currentUserPrivileges?.roadways?.includes("update") && (
              <>
                <Route
                  path="/edit-roadway"
                  element={
                    <ConnectedProjectsDataProvider>
                      <EditRoadway />
                    </ConnectedProjectsDataProvider>
                  }
                />
                <Route
                  path="/connected-projects"
                  element={
                    <ConnectedProjectsDataProvider>
                      <ConnectedProjects />
                    </ConnectedProjectsDataProvider>
                  }
                />
              </>
            )}

            {/* For Strategy Types */}
            {userRole === "super admin" && (
              <Route
                path="/projects/strategy-types"
                element={<StrategyTypes />}
              />
            )}

            {/* For regions */}
            {userRole === "super admin" && (
              <Route path="/regions" element={<Regions />} />
            )}

            {/* For users */}
            {currentUserPrivileges?.users?.includes("view") && (
              <Route path="/users" element={<Users />} />
            )}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
