import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Avatar,
  Grid,
  Box,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  CircularProgress
} from "@mui/material";

//Import icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//Styles
import { StyledButton, StyledInputRequired } from "./styles";

//Context
import { useAuth } from "../../context/AuthContext";

//themes
import themes from "../../themes/defaultTheme";

export default function LoginUser() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [msg, setMsg] = useState({ message: null, severity: null });
  const [loading, setLoading] = useState(false);

  //Show Password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    //Validate user input data.
    const username = data.get("username");
    const password = data.get("password");
    if (username && password) {
      try {
        await login(username, password);
        setLoading(false);
        navigate("/dashboard");
      } catch (error) {
        if (error.response && error.response.status === 401)
          setMsg({ 'message': 'Incorrect username or password', 'severity': 'error' });
        else
          setMsg({ 'message': 'Some error occured. Please try again', 'severity': 'error' });
        setLoading(false);
      }
    }
    else {
      setLoading(false);
      setMsg({ 'message': 'Username or password is empty', 'severity': 'error' });
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(images/mitigation-plan.png)",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 25,
            mx: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
          </div>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              required
              InputProps={{
                autoComplete: "username",
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "current-password",
              }}
            />

            {/* Display message */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
              }}
            >
              {/* Error message */}
              <Box sx={{ flexGrow: 1 }}>
                {msg && Object.keys(msg)?.length > 0 && msg["message"] && (
                  <StyledInputRequired style={{ fontWeight: 'bold', color: msg["severity"] === "error" ? "red" : "green" }}>
                    {msg["message"]}
                  </StyledInputRequired>
                )}
              </Box>

              {/* Forgot Password link */}
              <Typography
                variant="body2"
                color="secondary"
                sx={{ cursor: "pointer" }}
                onClick={() => navigate('/reset-password', { state: { type: 'forgotPwd' } })}
              >
                Forgot Password
              </Typography>
            </Box>

            {loading ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 3,
                  marginLeft: 0
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, marginLeft: 0 }}
                >
                  Log In
                </StyledButton>
                <Grid
                  item
                  xs={12}
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <StyledButton
                    fullWidth
                    sx={{ color: themes.default.palette.primary.contrastText }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Go to Mitigation Plans
                  </StyledButton>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
