import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { StyledButton, StyledDataGrid } from "./styles";
import {
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  LockOpen as AccessIcon,
  Password as PasswordIcon,
} from "@mui/icons-material";

//Components
import RouterBreadrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";
import UserInputDialog from "../../components/Dialog/UserInputDialog";
import InformationDialog from "../../components/Dialog/InformationDialog";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import UserAccessDialog from "../../components/Dialog/UserAccessDialog";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";
import customPasswordGenerator from "../../utils/customPasswordGenerator";

//custom hook
import { useFetch } from "../../hooks/useFetch";
import { useUpdate } from "../../hooks/useUpdate";

//constants
import { API_URLS, USER_ACCESS_LEVEL } from "../../Constants";

//context
import { useAuth } from "../../context/AuthContext";

const pathItems = ["Dashboard", "Users"];

export default function Users() {
  const [usersData, setUsersData] = useState([]);
  const usersAPI = API_URLS["API_USERS"];
  const { auth } = useAuth();
  const [reloadFlag, setReloadFlag] = useState(false);
  const [newUsername, setNewUsername] = useState('');

  //custom hooks
  const fetcher = useFetch("get", usersAPI);
  const postFetcher = useUpdate("post", usersAPI);
  const putFetcher = useUpdate("put", usersAPI);
  const deleteFetcher = useUpdate("delete", usersAPI);
  const resetPwdFetcher = useUpdate("post", API_URLS["API_RESET_PWD"]);

  //Fetch data from API when the component renders.
  useEffect(() => {
    fetcher.setRequest({});
  }, [reloadFlag]);

  //Once response is received, set appropriate state variables.
  useEffect(() => {
    const response = fetcher.serverResponse;
    if (response?.success && response.success) {
      let allUsersData = response.data;
      if (
        auth?.user?.roleName.toLowerCase() === "regional admin" &&
        allUsersData?.length > 0
      ) {
        allUsersData = allUsersData.filter(
          (user) => user.role.toLowerCase() === "regional user"
        );
      }

      // Sort the users alphabetically by first name
      const sortedUsersData = Array.isArray(allUsersData)
        ? allUsersData.toSorted((a, b) =>
          a.firstName.localeCompare(b.firstName)
        )
        : null;
      setUsersData(sortedUsersData || []);
    }
  }, [fetcher.serverResponse]);

  //Once data from POST request is fetched, refetch the data
  useEffect(() => {
    if (postFetcher?.serverResponse?.success) {
      setReloadFlag((prev) => !prev);

      setInformationDialog({
        open: true,
        title: "Success",
        message: "User added successfully. A password reset link has been sent to user's registered email.",
      });

      //call API to send pwd reset link to user
      resetPwdFetcher.executeUpdate({
        "username": newUsername
      });
    }
    else if (postFetcher?.serverResponse?.error) {
      console.error(
        "Error in POST request: ",
        postFetcher.serverResponse.error
      );
      setInformationDialog({
        open: true,
        title: "Error",
        message: postFetcher.serverResponse.error,
      });
    }
  }, [postFetcher.serverResponse]);

  //Once data from PUT request is fetched, refetch the data
  useEffect(() => {
    if (
      putFetcher?.serverResponse?.success &&
      putFetcher.serverResponse.success
    ) {
      setReloadFlag((prev) => !prev);
    } else if (putFetcher?.serverResponse?.error)
      console.error("Error in PUT request: ", putFetcher.serverResponse.error);
  }, [putFetcher.serverResponse]);


  //Once error from POST request is received, show error message
  useEffect(() => {
    if (resetPwdFetcher?.serverError?.message) {
      setInformationDialog({
        open: true,
        title: "Error",
        message: resetPwdFetcher.serverError.message?.message || 'Some error occured. Please try again.',
      });
    }
  }, [resetPwdFetcher.serverError]);

  //React Hooks
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState({ open: false });
  const [editDialog, setEditDialog] = useState({ open: false });
  const [addDialog, setAddDialog] = useState({ open: false });
  const [informationDialog, setInformationDialog] = useState({ open: false });
  const [userAccessDialog, setUserAccessDialog] = useState({ open: false });

  //Get loggedIn user details
  const loggedInUser = auth?.user?.privileges || null;
  if (!loggedInUser) return;
  const showEditAction = loggedInUser?.users.includes("update");
  const showDelAction = loggedInUser?.users.includes("delete");
  const showPrivilegesAction = loggedInUser?.users.includes("view");
  const loggedInUsername = auth.user.username;

  //Data Grid columns
  const columns = [
    { field: "username", headerName: "Username", width: 250, filterable: true },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      valueGetter: (params) => {
        const { firstName, lastName } = params.row;
        return lastName ? `${firstName} ${lastName}` : firstName;
      },
      renderCell: (params) => (
        <Typography aria-label="Name" variant="body2" key={params.id}>
          {params.value}
        </Typography>
      )
    },
    { field: "email", headerName: "Email", width: 250, filterable: true },
    { field: "role", headerName: "Role", width: 190, filterable: true },
    { field: "region", headerName: "Region", width: 190, filterable: true },
    { field: "agency", headerName: "Agency", width: 120, filterable: true },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 200,
      getActions: (params) => {
        const actions = [];

        //Change password button
        actions.push(
          <GridActionsCellItem
            aria-label="Change Password Action"
            icon={
              <IconsWithTooltip
                title="Click to change password"
                icon={<PasswordIcon />}
              />
            }
            label="Change Password"
            onClick={() => handleResetPasswordClick(params.row.username)}
          />
        );
        if (showEditAction) {
          actions.push(
            <GridActionsCellItem
              aria-label="Edit Action"
              icon={
                <IconsWithTooltip title="Update user" icon={<EditIcon />} />
              }
              label="Edit"
              onClick={() => handleEditSelected(params.row.id)}
            />
          );
        }
        if (showDelAction) {
          actions.push(
            <GridActionsCellItem
              aria-label="Delete Action"
              icon={
                <IconsWithTooltip title="Delete user" icon={<DeleteIcon />} />
              }
              label="Delete"
              onClick={() => handleDeleteSelected([params.row.id])}
              showInMenu={true}
            />
          );
        }
        if (showPrivilegesAction) {
          actions.push(
            <GridActionsCellItem
              aria-label="View Access Action"
              icon={
                <IconsWithTooltip
                  title="View user privileges"
                  icon={<AccessIcon />}
                />
              }
              label="View Access"
              onClick={() => handleUserAccessDialog(params.row.id)}
              showInMenu={true}
            />
          );
        }
        return actions;
      },
    },
  ];

  //Custom toolbar to return number of records alongwith filter and export options.
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div style={{ marginRight: "15px" }}>
          Total Records: {usersData.length}
        </div>
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
        />
      </GridToolbarContainer>
    );
  }

  // Checks if email already exists or not. Returns true if it exists else returns false.
  const checkIfEmailExists = (email) => {
    //Check if email is empty or not.
    if (email) {
      const findRow =
        usersData != null && usersData.length > 0
          ? usersData.find(
            (row) => row.email.toLowerCase() === email.toLowerCase()
          )
          : null;
      if (findRow != null) return true;
    }
    return false;
  };

  //Checks if username already exists or not. Returns true if it exists else returns false.
  const checkIfUsernameExists = (username) => {
    //Check if email is empty or not.
    if (username) {
      const findRow =
        usersData != null && usersData.length > 0
          ? usersData.find(
            (row) => row.username.toLowerCase() === username.toLowerCase()
          )
          : null;
      if (findRow != null) return true;
    }
    return false;
  };

  //Perform validation and check for errors
  const validateUserInput = (firstName, role, region, email, originalEmail) => {
    let errorFields = {};
    if (firstName === "" || firstName == null) {
      //Check if first name is empty or not.
      errorFields["Empty"] = ["First Name"];
    }
    if (role === "" || role == null) {
      //Check if role is empty or not.
      if ("Empty" in errorFields) {
        let valuesArray = errorFields["Empty"];
        valuesArray.push("Role");
      } else {
        errorFields["Empty"] = ["Role"];
      }
    }
    if (region === "" || region == null) {
      //Check if region is empty or not.
      if ("Empty" in errorFields) {
        let valuesArray = errorFields["Empty"];
        valuesArray.push("Region");
      } else {
        errorFields["Empty"] = ["Region"];
      }
    }
    if (email === "" || email == null) {
      //Check if email is empty or not.
      if ("Empty" in errorFields) {
        let valuesArray = errorFields["Empty"];
        valuesArray.push("Email");
      } else {
        errorFields["Empty"] = ["Email"];
      }
    } else {
      //Check if email already exists or not in the table.
      const emailFound =
        originalEmail !== email ? checkIfEmailExists(email) : false;
      if (emailFound) {
        errorFields["Exists"] = ["Email"];
      } else {
        //Validate email address.
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const validEmail = pattern.test(email);
        if (validEmail === false) {
          errorFields["InvalidEmail"] = ["Email"];
        }
      }
    }
    return errorFields;
  };

  //Display error message
  const displayErrorMessage = (errorFields) => {
    let errorMsg = "";
    if ("Empty" in errorFields) {
      let errorValues =
        errorFields["Empty"].length === 1
          ? errorFields["Empty"]
          : errorFields["Empty"].join(", ");
      errorMsg = "Following field(s) should have a value: " + errorValues;
    }
    if ("Exists" in errorFields) {
      let errorValues =
        errorFields["Exists"].length === 1
          ? errorFields["Exists"]
          : errorFields["Exists"].join(", ");
      errorMsg =
        errorMsg === ""
          ? "Following field value(s) already exist: " + errorValues
          : errorMsg +
          "\nFollowing field value(s) already exist: " +
          errorValues;
    } else if ("InvalidEmail" in errorFields) {
      errorMsg =
        errorMsg === ""
          ? "Email invalid. Choose a valid email."
          : errorMsg + "\nEmail invalid. Choose a valid email.";
    }
    setInformationDialog({
      open: true,
      title: "Alert",
      message: errorMsg,
    });
  };

  //Invoked when user clicks "Add" button in Add Dialog.
  const handleAddConfirm = (
    firstName,
    lastName,
    role,
    region,
    agency,
    email,
    callback = () => { }
  ) => {
    //Validate user input.
    const errorFields = validateUserInput(firstName, role, region, email, "");

    //Check if any errors are there or not.
    if (Object.keys(errorFields).length === 0) {

      //Create a new row with the data.
      const newRow = {
        id: "",
        firstName: firstName,
        lastName: lastName,
        username: email.toLowerCase(),
        email: email.toLowerCase(),
        password: customPasswordGenerator(), //generate initial password
        role: role,
        region: region,
        agency: agency,
        lastModifiedBy: loggedInUsername,
        lastModifiedDate: new Date(),
      };

      //add username to hook to send reset password email.
      setNewUsername(newRow.username)

      // Update data in the database.
      postFetcher.executeUpdate(newRow);

      //Close AddDialog.
      setAddDialog((prevState) => ({ ...prevState, open: false }));

      callback();
    } else {
      displayErrorMessage(errorFields);
    }
  };

  //User Addition: Invoked to show Add dialog when user clicks "Add New User" button.
  const handleAdd = () => {
    setAddDialog({
      open: true,
      firstName: "",
      lastName: "",
      role: "",
      region: "",
      agency: "",
      email: "",
    });
  };

  //Invoked when user clicks "Edit" button in Edit Dialog.
  const handleEditConfirm = (
    editedFirstName,
    editedLastName,
    editedRole,
    editedRegion,
    editedAgency,
    editedEmail,
    callback = () => { }
  ) => {
    const originalRow = usersData.find((row) => row.id === selectedRows[0].id);
    const originalEmail = originalRow.email;

    //Validate user input.
    const errorFields = validateUserInput(
      editedFirstName,
      editedRole,
      editedRegion,
      editedEmail,
      originalEmail
    );

    //Check if username already exists or not in the table.
    const originalUsername = originalRow.username;
    const usernameFound =
      originalUsername !== editedEmail
        ? checkIfUsernameExists(editedEmail)
        : false;

    //If username exists then add it to error Fields array.
    if (usernameFound) {
      if ("Exists" in errorFields) {
        errorFields["Exists"].push("Username");
      } else {
        errorFields["Exists"] = ["Username"];
      }
    }

    //Check if any errors are there or not.
    if (Object.keys(errorFields).length === 0) {
      //Update row with new data.
      const updatedRow = {
        id: selectedRows[0].id,
        firstName: editedFirstName,
        lastName: editedLastName,
        username: editedEmail.toLowerCase(),
        email: editedEmail.toLowerCase(),
        role: editedRole,
        region: editedRegion,
        agency: editedAgency,
        lastModifiedBy: loggedInUsername,
        lastModifiedDate: new Date(),
      };

      // Update data in the database.
      putFetcher.executeUpdate(updatedRow);

      //Close EditDialog.
      setEditDialog((prevState) => ({ ...prevState, open: false }));

      callback();
    } else {
      displayErrorMessage(errorFields);
    }
  };

  //User Edit: Invoked to show Edit Dialog when user clicks edit icon for the selected row.
  const handleEditSelected = (selectedRowId) => {
    const selectedRow = usersData.find((row) => row.id === selectedRowId);

    //If a row is selected then open Edit Dialog.
    if (selectedRow) {
      setSelectedRows([selectedRow]);
      setEditDialog({
        open: true,
        firstName: selectedRow.firstName,
        lastName: selectedRow.lastName,
        role: selectedRow.role,
        region: selectedRow.region,
        agency: selectedRow.agency,
        email: selectedRow.email,
      });
    }
  };

  //User Deletion: Set action on clicking "Yes" for selected user(s).
  const handleDeleteConfirm = () => {
    setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
  };

  //User Deletion: Set ConfirmationDialog properties for selected user(s).
  const handleDeleteSelected = (selectedIds) => {
    if (selectedIds && selectedIds.length > 0) {
      setConfirmationDialog({
        open: true,
        title: "Delete Confirmation",
        message: `Are you sure you want to delete the selected user(s) ?`,
        onConfirm: async () => {
          // Update data in the database.
          for (const id of selectedIds) {
            await deleteFetcher.executeUpdate({ id: id });
            if (deleteFetcher?.serverResponse?.error)
              console.error(
                "Error in handleDeleteSelected for id " +
                id +
                " : " +
                deleteFetcher.serverResponse.error
              );
          }
          setReloadFlag((prev) => !prev);
          setSelectedRows([]);
          handleDeleteConfirm();
        },
      });
    }
  };

  //Displays selected user access level.
  const handleUserAccessDialog = (selectedRowId) => {
    const selectedRow = usersData.find((row) => row.id === selectedRowId);
    if (selectedRow) {
      const selectedUserRole = selectedRow.role;
      let selectedUserAccessLevel = null;
      if (selectedUserRole?.toLowerCase() === "super admin") {
        selectedUserAccessLevel = USER_ACCESS_LEVEL.superAdminAccess;
      } else if (selectedUserRole?.toLowerCase() === "regional admin") {
        selectedUserAccessLevel = USER_ACCESS_LEVEL.regionalAdminAccess;
      } else if (selectedUserRole?.toLowerCase() === "tti user") {
        selectedUserAccessLevel = USER_ACCESS_LEVEL.ttiUserAccess;
      } else if (selectedUserRole?.toLowerCase() === "regional user") {
        selectedUserAccessLevel = USER_ACCESS_LEVEL.regionalUserAccess;
      }
      setUserAccessDialog({
        open: true,
        title: "View User Privileges",
        data: selectedUserAccessLevel,
      });
    }
  };

  //Call reset password API
  const handleResetPasswordClick = (selectedUsername) => {

    setInformationDialog({
      open: true,
      title: "Success",
      message: "A password reset link has been sent to user's registered email.",
    });

    //call API to send pwd reset link to user
    resetPwdFetcher.executeUpdate({
      "username": selectedUsername || ""
    });
  };

  return (
    <>
      {/* Breadcrumbs */}
      <Grid item xs={12}>
        <RouterBreadrumbs pathItems={pathItems} />
      </Grid>

      {/* Page Title */}
      <Grid item xs={12}>
        <Title title={"Users"} />
      </Grid>

      {/* Add New User Button */}
      {loggedInUser?.users.includes("add") && (
        <Grid item xs={12}>
          <StyledButton
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={handleAdd}
          >
            Add New User
          </StyledButton>

          {/* Render Delete All button */}
          {selectedRows.length > 1 && (
            <StyledButton
              aria-label="Delete All Button"
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => handleDeleteSelected(selectedRows)}
            >
              Delete All
            </StyledButton>
          )}
        </Grid>
      )}

      {/* Display Data Table */}
      <Grid container sx={{ paddingRight: 3 }}>
        <StyledDataGrid
          rows={usersData}
          columns={columns}
          loading={fetcher.isLoading}
          autoHeight
          initialState={{
            columns: {
              // Hide column actions from data grid view.
              columnVisibilityModel: {
                actions:
                  showEditAction || showDelAction || showPrivilegesAction,
              },
            },
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 15, 20, 25]}
          slots={{
            toolbar: CustomToolbar,
          }}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          selectedRows={selectedRows}
        />
      </Grid>

      {/* ConfirmationDialog component */}
      <ConfirmationDialog
        open={confirmationDialog.open}
        onClose={() =>
          setConfirmationDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={confirmationDialog.title}
        message={confirmationDialog.message}
        onConfirm={confirmationDialog.onConfirm}
      />

      {/* Add New User Dialog */}
      <UserInputDialog
        open={addDialog.open}
        onClose={() =>
          setAddDialog((prevState) => ({ ...prevState, open: false }))
        }
        onConfirm={handleAddConfirm}
        firstName={addDialog.firstName || ""}
        lastName={addDialog.lastName || ""}
        role={addDialog.role || ""}
        region={addDialog.region || ""}
        agency={addDialog.agency || ""}
        email={addDialog.email || ""}
        loggedInUser={auth?.user || null}
        dialogType="add"
      />

      {/* Edit Dialog component */}
      <UserInputDialog
        open={editDialog.open}
        onClose={() =>
          setEditDialog((prevState) => ({ ...prevState, open: false }))
        }
        onConfirm={handleEditConfirm}
        firstName={editDialog.firstName || ""}
        lastName={editDialog.lastName || ""}
        role={editDialog.role || ""}
        region={editDialog.region || ""}
        agency={editDialog.agency || ""}
        email={editDialog.email || ""}
        loggedInUser={auth?.user || null}
        dialogType="edit"
      />

      {/* Information Dialog component */}
      <InformationDialog
        open={informationDialog.open}
        onClose={() =>
          setInformationDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={informationDialog.title}
        message={informationDialog.message}
        onConfirm={informationDialog.onConfirm}
      />

      {/* User Access Dialog Component */}
      <UserAccessDialog
        open={userAccessDialog.open}
        onClose={() =>
          setUserAccessDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={userAccessDialog.title}
        userAccessData={userAccessDialog.data}
      />
    </>
  );
}
