import { Typography, Grid, Box, LinearProgress, Alert, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledBox, StyledTypography, StyledGrid, StyledPaper } from "./styles";

//leaflet
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON, TileLayer, Popup } from "react-leaflet";

//context
import { useAuth } from "../../context/AuthContext";

//components
import Title from "../../components/Typography/Title";
import TileHeading from "../../components/Typography/TileHeading";
import { FitBounds } from "../../components/Map/FitBounds";

//custom hooks
import { useFetch } from "../../hooks/useFetch";

//constants
import { API_URLS } from "../../Constants";

export default function Dashboard() {
    const { auth } = useAuth();
    const firstName = auth?.user?.firstName;
    const loggedInUserRole = auth?.user?.roleName || null;
    const roadwaysFetcher = useFetch("get", API_URLS["GET_TOP_105_ROADWAYS"]);
    const statsFetcher = useFetch("get", API_URLS["API_GET_DASHBOARD_STATS"]);
    const [top105RoadwaysData, setTop105RoadwaysData] = useState([]);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [regionsDropDown, setRegionsDropDown] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [allStats, setAllStats] = useState([]);
    const [selectedStat, setSelectedStat] = useState({});
    const [pageTitle, setPageTitle] = useState('');

    //Fetch data from API when the component renders.
    useEffect(() => {
        statsFetcher.setRequest({});

        //If loggedin user is super admin or TTI user, fetch all top 105 roads
        if (loggedInUserRole?.toLowerCase() === "super admin" || loggedInUserRole?.toLowerCase() === "tti user") {
            roadwaysFetcher.setRequest({});
            setPageTitle('Top 105 Congested Road Segments')
        }
        else if (auth?.user?.regionAccessibleSet) {

            //set page title
            setPageTitle(`${auth.user.regionAccessibleSet[0]?.accessibles || ''} Top 105 Congested Road Segments`);

            const userRegionsIds = auth.user.regionAccessibleSet.map(region => region.id);
            if (userRegionsIds?.length === 1) {
                const urlWithRegionIds = `${API_URLS["GET_TOP_105_ROADWAYS"]}?district=${userRegionsIds[0]}`;
                roadwaysFetcher.setRequest({ passedUrl: urlWithRegionIds });
            }
        }
    }, [loggedInUserRole]);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = roadwaysFetcher.serverResponse;
        if (response?.length > 0) {
            setTop105RoadwaysData(response || []);
        }
        else if (response?.error) {
            console.error("Error in GET roadways request", response.error);
        }
    }, [roadwaysFetcher.serverResponse]);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = statsFetcher.serverResponse;
        if (response?.success && response.data) {
            const regionNames = response.data.map(stat => stat.regionName).filter(name => name !== null);

            //sort regions alphabetically
            const sortedRegions = regionNames.toSorted((a, b) => a.localeCompare(b));
            setRegionsDropDown(sortedRegions);
            setAllStats(response.data);
        }
        else if (response?.message) {
            console.error("Error in GET roadways request", response.message);
        }
    }, [statsFetcher.serverResponse]);

    //Renders when either regionsDropDown or allStats changes.
    useEffect(() => {
        if (regionsDropDown?.length > 0)
            setSelectedRegion(regionsDropDown[0]);
        if (allStats?.length > 0 && regionsDropDown?.length > 0)
            setSelectedStat(allStats.filter(stat => stat.regionName === regionsDropDown[0])[0]);
    }, [regionsDropDown, allStats]);

    //When region drop down is clicked.
    const handleRegionChange = (event) => {

        //set selected region
        setSelectedRegion(event.target.value);

        //change values as per selected region
        setSelectedStat(allStats.filter(stat => stat.regionName === event.target.value)[0]);
    };

    //Set geoJSon style
    const geoJSONStyle = () => {
        return { color: '#0C7BDC' };
    }

    //Invoked when a road segment is selected.
    const handleRoadClick = (feature, layer) => {
        const roadName = feature.properties.road;
        const fromRoad = feature.properties.from_road;
        const toRoad = feature.properties.to_road;
        const rank = feature.properties.arank;
        const segmentId = feature.id;

        //Store the selected segment in selectedSegment state.
        setSelectedSegment({
            segmentId,
            roadName,
            fromRoad,
            toRoad,
            rank,
        });
    };

    //Highlights road segemnt on mouseOver.
    const handleMouseOver = (layer) => {
        layer.setStyle({
            weight: 3,
            color: 'red',
            fillOpacity: 0.7,
        });
    };

    //Highlights road segemnt on mouseOut.
    const handleMouseOut = (layer) => {

        layer.setStyle({
            weight: 3,
            color: '#0C7BDC',
            dashArray: "",
            fillOpacity: 0.7,
        });
    };

    //Renders different events for each road segment.
    function onEachRoad(feature, layer) {
        layer.on({
            mouseover: handleMouseOver.bind(null, layer),
            mouseout: handleMouseOut.bind(null, layer),
            click: handleRoadClick.bind(null, feature)
        });
    }

    return (
        <>
            {/* Welcome user */}
            <StyledGrid item xs={12}>
                <Typography color="inherit" variant="h8">Hello {firstName}!</Typography>
            </StyledGrid>

            {/* Top 105 roads heading */}
            <StyledGrid item xs={12}>
                <Title title={pageTitle} />
            </StyledGrid>

            {/* Map */}
            <StyledGrid item xs={12}>

                {/* Map Alert for loading. */}
                {roadwaysFetcher.isLoading && (
                    <div aria-label="Loading Map">
                        <Alert aria-label="Loading map with road segments. Thank you for your patience." style={{ marginRight: "20px" }} severity="info">Loading map with road segments. Thank you for your patience.</Alert>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress style={{ marginRight: "20px" }} />
                            <MapContainer style={{ height: "500px", marginRight: "20px" }} center={[31.9686, -99.9018]} minZoom={6} zoom={6}>
                                <TileLayer
                                    attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                />
                            </MapContainer>
                        </Box>
                    </div>
                )}

                {/* Display map */}
                {!roadwaysFetcher.isLoading && top105RoadwaysData && (
                    <div aria-label="Map" style={{ marginBottom: '10px' }}>

                        <MapContainer style={{ height: "500px", marginRight: "20px" }} minZoom={6}>

                            <TileLayer
                                attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            />
                            <GeoJSON
                                key={JSON.stringify({ top105RoadwaysData })}
                                data={top105RoadwaysData}
                                onEachFeature={onEachRoad}
                                style={geoJSONStyle}
                            >
                                {selectedSegment && (
                                    <Popup>
                                        <div>
                                            <p style={{ textAlign: "center" }}>
                                                <strong>Road Segment Details</strong>
                                            </p>

                                            <p>
                                                <strong>Roadname : </strong>
                                                {selectedSegment.roadName}
                                                <br />
                                                <strong>From Road : </strong>
                                                {selectedSegment.fromRoad}
                                                <br />
                                                <strong>To Road : </strong>
                                                {selectedSegment.toRoad}
                                                <br />
                                                <strong>Rank : </strong>
                                                {selectedSegment.rank}
                                            </p>
                                        </div>
                                    </Popup>
                                )
                                }
                            </GeoJSON>
                            <FitBounds data={top105RoadwaysData} />
                        </MapContainer>
                    </div>
                )}
            </StyledGrid>

            {/* Statistics Heading */}
            <StyledGrid item xs={12}>
                <Title title="Statistics" />
            </StyledGrid>

            {statsFetcher.isLoading && (
                <Alert aria-label="Loading statistics. Thank you for your patience."
                    severity="info">Loading statistics. Thank you for your patience.</Alert>
            )}

            {!statsFetcher.isLoading && (
                <>
                    {/* Statistics */}
                    <StyledGrid container spacing={2} sx={{ paddingRight: "20px" }}>

                        {/* Region names drop down */}
                        <Grid item xs={2}>
                            <StyledPaper elevation={3}>
                                <StyledBox>
                                    <TileHeading title="Select Region" />
                                    <Select
                                        id="regionDropDown"
                                        variant="outlined"
                                        value={selectedRegion}
                                        sx={{
                                            width: '70%',
                                            height: '30px'
                                        }}
                                        onChange={handleRegionChange}
                                    >
                                        {regionsDropDown?.length > 0 && regionsDropDown.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </StyledBox>
                            </StyledPaper>
                        </Grid>

                        {/* Total number of projects */}
                        <Grid item xs={2}>
                            <StyledPaper elevation={3}>
                                <StyledBox>
                                    <TileHeading title="Total Projects" />
                                    <StyledTypography>{selectedStat.totalProjects}</StyledTypography>
                                </StyledBox>
                            </StyledPaper>
                        </Grid>

                        {/* Draft projects */}
                        <Grid item xs={2}>
                            <StyledPaper elevation={3}>
                                <StyledBox>
                                    <TileHeading title="Draft" />
                                    <StyledTypography>{selectedStat.draftProjects}</StyledTypography>
                                </StyledBox>
                            </StyledPaper>
                        </Grid>

                        {/* Active projects */}
                        <Grid item xs={2}>
                            <StyledPaper elevation={3}>
                                <StyledBox>
                                    <TileHeading title="Active" />
                                    <StyledTypography>{selectedStat.activeProjects}</StyledTypography>
                                </StyledBox>
                            </StyledPaper>
                        </Grid>

                        {/* Inactive projects */}
                        <Grid item xs={2}>
                            <StyledPaper elevation={3}>
                                <StyledBox>
                                    <TileHeading title="Inactive" />
                                    <StyledTypography>{selectedStat.inactiveProjects}</StyledTypography>
                                </StyledBox>
                            </StyledPaper>
                        </Grid>

                        {/* Reviewed projects */}
                        <Grid item xs={2}>
                            <StyledPaper elevation={3}>
                                <StyledBox>
                                    <TileHeading title="Reviewed" />
                                    <StyledTypography>{selectedStat.reviewedProjects}</StyledTypography>
                                </StyledBox>
                            </StyledPaper>
                        </Grid>
                    </StyledGrid>
                </>
            )}
        </>
    );
}