export const getCombRegImpactedRegs = (project) => {
    let regions = "";
    if (project && Object.keys(project).length > 0) {
        regions = project.regions?.length > 0 ? project.regions.filter(reg => reg.id !== null).map(reg => reg.name).join(', ') : "";

        //if impacted regions are present then concatenate then with regions
        const impactedRegions = project.impactedRegions?.length > 0 ? project.impactedRegions.filter(reg => reg.id !== null) : [];
        if (impactedRegions.length > 0) {
            const impactedRegionNames = impactedRegions.map(reg => reg.name).join(', ');
            regions = regions ? `${regions}, ${impactedRegionNames}` : impactedRegionNames;
        }
    }
    return regions;
};