import { lighten, darken, createTheme } from "@mui/material";

const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#0C7BDC";

const lightenRate = 0.075;
const darkenRate = 0.15;

const defaultTheme = {
    palette: {
        primary: {
            light: "#ECEDED",
            main: "#14375A",
            dark: "#14375A",
            contrastText: "#CC7B29",
        },
        secondary: {
            light: "#ECEDED",
            main: "#CC7B29",
            dark: "#CC7B29",
            contrastText: "#14375A",
        },
        warning: {
            main: warning,
            light: lighten(warning, lightenRate),
            dark: darken(warning, darkenRate),
        },
        success: {
            main: success,
            light: lighten(success, lightenRate),
            dark: darken(success, darkenRate),
        },
        info: {
            main: info,
            light: lighten(info, lightenRate),
            dark: darken(info, darkenRate),
        },
        text: {
            primary: "#14375A",
            secondary: "#14375A",
            hint: "#14375A",
        },
        background: {
            default: "#F6F7FF",
            light: "#F3F5FF",
        },
    },
    customShadows: {
        widget:
            "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        widgetDark:
            "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
        widgetWide:
            "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    },
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: "#4A4A4A1A",
            },
        },
        MuiMenu: {
            paper: {
                boxShadow:
                    "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
            },
        },
        MuiSelect: {
            icon: {
                color: "#B9B9B9",
            },
        },
        MuiListItem: {
            root: {
                "&$selected": {
                    backgroundColor: "#F3F5FF !important",
                    "&:focus": {
                        backgroundColor: "#F3F5FF",
                    },
                },
            },
            button: {
                "&:hover, &:focus": {
                    backgroundColor: "#F3F5FF",
                },
            },
        },
        MuiTouchRipple: {
            child: {
                backgroundColor: "white",
            },
        },
        MuiTableRow: {
            root: {
                height: 56,
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: "1px solid rgba(224, 224, 224, .5)",
                paddingLeft: 24
            },
            head: {
                fontSize: "0.95rem",
            },
            body: {
                fontSize: "0.95rem",
            },
        },
        PrivateSwitchBase: {
            root: {
                marginLeft: 10
            }
        }
    },
};

const overrides = {
    typography: {
        fontFamily: "Verdana, Arial, sans-serif",
        body1: {
            color: defaultTheme.palette.primary.main
        },
        h1: {
            fontSize: "3rem",
            color: defaultTheme.palette.primary.main
        },
        h2: {
            fontSize: "2rem",
            color: defaultTheme.palette.primary.main
        },
        h3: {
            fontSize: "1.64rem",
            color: defaultTheme.palette.primary.main
        },
        h4: {
            fontSize: "1.5rem",
            color: defaultTheme.palette.primary.main
        },
        h6: {
            fontSize: "1rem",
            color: defaultTheme.palette.primary.main
        },
        h9: {
            fontSize: "0.88rem",
            color: defaultTheme.palette.primary.main
        }
    },
};

const themes = {
    default: createTheme({ ...defaultTheme, ...overrides }),
};

export default themes;