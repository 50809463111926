import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { LayoutProvider } from "./context/LayoutContext";
import { ThemeProvider } from "@mui/system";
import themes from "./themes/defaultTheme";

//Using Browser Router to setup Routing for the application.
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <LayoutProvider>
      <ThemeProvider theme={themes.default}>
        <App />
      </ThemeProvider>
    </LayoutProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
