import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Grid, Button, Typography } from "@mui/material";
import { useAuth } from "../../context/AuthContext";

//Renders the header component.
export default function Header({ showLogout, title }) {
  //Local constants
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  return (
    <AppBar position="fixed" sx={{ zIndex: 1200 }}>
      <Toolbar disableGutters={true}>
        <Grid container alignItems="center">

          {/* TxDOT logo */}
          <Grid item xs={3} align="left" sx={{ display: 'flex', paddingLeft: 3 }}>
            <img alt="TxDOT Logo" src="/images/TxDOTLogo.png" style={{ width: "auto", height: '55px', marginBottom: 6 }} />
            <div style={{
              height: '50px',
              width: '1px',
              backgroundColor: 'white',
              marginLeft: 20,
              marginRight: 15,
              marginTop: 8,
              marginBottom: 2
            }}></div>
            <img alt="TTI Logo" src="/images/TTILogoWhite.png" style={{ width: "auto", height: '30px', marginTop: 25 }} />
          </Grid>

          {/* Title bar */}
          <Grid item xs={7} align="center">
            <Typography
              sx={{ color: 'secondary.light', textTransform: "none", fontSize: "22px", fontWeight: 'bold', lineHeight: 0 }}
            >
              {title}
            </Typography>
          </Grid>

          {/* Add logout button */}
          {showLogout && (
            <Grid item xs={2} align="right" sx={{ paddingRight: 2 }}>
              <Button
                onClick={handleLogout}
                sx={{ color: 'secondary.light', textTransform: "none" }}
              >
                Logout
              </Button>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar >
  );
}