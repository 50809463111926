import { useFetch } from './useFetch';

export const useUpdate = (method, endpoint) => {
    const { setRequest, serverResponse, serverError } = useFetch(method, endpoint);

    const executeUpdate = async (data, url) => {
        try {

            await setRequest({ body: JSON.stringify(data), passedUrl: url });

        } catch (error) {
            console.error(`Error in ${method} request to ${endpoint}:`, error);
            return null;
        }
    }

    return { executeUpdate, serverResponse, serverError };
};