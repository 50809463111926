
import * as React from 'react';

import { useEffect, useRef } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';

import {
  Delete as DeleteIcon,
  InfoOutlined as InfoIcon,
  AddCircle as AddCircleIcon
} from "@mui/icons-material";

import { FieldArray } from 'formik';

import { InputField, DatePickerField } from '../../FormFields';
import Grid from '@mui/material/Grid';



export default function ProjectPhase(props) {

  const {
    formField: {
      description,
      projectDetails,
    }, values, push } = props;

  function addcolumnhead() {
    return (<>
      {/* code to return study cost field */}

    </>)
  }

  useEffect(() => {
    addcolumnhead();
  }, [values.isProjectStudy]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ marginRight: "10px" }}>
        <InputField tooltip={description.label}  name={description.name} label={description.label} fullWidth />
      </Grid>
      <Grid item xs={12} sx={{ marginRight: "10px" }}>

        <FieldArray name="projectDetails">
          {({ remove, push }) => (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer >

                <Table stickyHeader aria-label="sticky table">
                  <TableHead>

                    <TableRow>

                      <TableCell align={'center'} style={{ minWidth: 80 }} > Actions </TableCell>
                      <TableCell align={'center'}> {projectDetails.phaseNumber.label} </TableCell>
                      <TableCell align={'center'} style={{ minWidth: 170 }} > {projectDetails.csjNumber.label}
                        <Tooltip title="Add one or more CSJ/Plan ID numbers per phase" style={{ display: 'flex' }}>
                          <InfoIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                        </Tooltip>
                      </TableCell>
                      <Tooltip title={projectDetails.letDate.label}>
                        <TableCell align={'center'} style={{ minWidth: 170 }} > {projectDetails.letDate.label} </TableCell>
                      </Tooltip>
                      <Tooltip title={projectDetails.completionDate.label}>
                        <TableCell align={'center'} style={{ minWidth: 170 }} > {projectDetails.completionDate.label} </TableCell>
                      </Tooltip>
                      <Tooltip title={projectDetails.costEstimate.label}>
                        <TableCell align={'center'} style={{ minWidth: 170 }} > {projectDetails.costEstimate.label} </TableCell>
                      </Tooltip>
                      {values.isProjectStudy === 'Yes' &&
                        <Tooltip title={projectDetails.studyCost.label}>
                          <TableCell align={'center'} style={{ minWidth: 170 }} > {projectDetails.studyCost.label} </TableCell>
                        </Tooltip>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      values.projectDetails.length > 0 &&
                      values.projectDetails.map((project, index) =>
                      (
                        <TableRow key={index}>

                          <TableCell align={'center'} style={{ minWidth: 80 }} >
                            <Tooltip title="Click to add a new phase" placement='left'>
                              <IconButton type="button" aria-label="add" onClick={() => push({ phase: values.projectDetails.length + 1, csjNumber: '', letDate: '', completionDate: '', costEstimate: '' })}> <AddCircleIcon />
                              </IconButton>
                            </Tooltip>
                            {/* Disable the delete action if the project phase details are less than 1 */}
                            {values.projectDetails.length > 1 && 
                            <Tooltip title="Click to delete this row/phase" placement='left'>
                              <IconButton type="button" aria-label="delete" onClick={() => remove(index)}> <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            }
                          </TableCell>

                          <TableCell align={'center'} style={{ minWidth: 50 }} >
                            <Typography aria-label="Phase number">{index + 1}</Typography>
                          </TableCell>
                          <TableCell align={'center'} style={{ minWidth: 170 }} >
                            <InputField tooltip={projectDetails.csjNumber.label} key={`gridVal${index}csjNumber`} name={`projectDetails.${index}.csjNumber`} label={projectDetails.csjNumber.label} fullWidth />
                          </TableCell>
                          <TableCell align={'center'} style={{ minWidth: 170 }} >
                            <DatePickerField tooltip={projectDetails.letDate.label} key={`gridVal${index}letDate`} name={`projectDetails.${index}.letDate`} label={projectDetails.letDate.label} fullWidth />
                          </TableCell>

                          <TableCell align={'center'} style={{ minWidth: 170 }} >
                            <DatePickerField tooltip={projectDetails.completionDate.label} key={`gridVal${index}completionDate`} name={`projectDetails.${index}.completionDate`} label={projectDetails.completionDate.label} fullWidth />
                          </TableCell>
                          <TableCell align={'center'} style={{ minWidth: 170 }} >
                            <InputField tooltip={projectDetails.costEstimate.label} key={`gridVal${index}costEstimate`} name={`projectDetails.${index}.costEstimate`} label={projectDetails.costEstimate.label} fullWidth />

                          </TableCell>
                          {values.isProjectStudy === 'Yes' &&
                            <TableCell align={'center'} style={{ minWidth: 170 }} >
                              <InputField tooltip={projectDetails.studyCost.label} key={`gridVal${index}studyCost`} name={`projectDetails.${index}.studyCost`} label={projectDetails.studyCost.label} fullWidth />
                            </TableCell>
                          }



                        </TableRow>

                      ))}

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </FieldArray>
      </Grid>
    </Grid >
  );
}