//context
import { AuthProvider } from "./context/AuthContext";

//components
import BasicLayout from "./components/Layout/BasicLayout";

export default function App() {
  return (
    <AuthProvider>
      <BasicLayout />
    </AuthProvider>
  );
}