import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Typography, Grid, Alert, CircularProgress } from '@mui/material';

import RouterBreadcrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs"
import ProjectInfoDetails from './ReviewProject/ProjectInfoDetails';
import ProjectPhaseDetails from './ReviewProject/ProjectPhaseDetails';
import ProjectStatusDetails from './ReviewProject/ProjectStatusDetails';
import ConnectedRoadwaysDetails from './ReviewProject/ConnectedRoadwaysDetails';
import addProjectFormModel from "../Projects/AddNewProject/FormModel/AddProjectFormModel";
import validationSchema from "../Projects/AddNewProject/FormModel/validationSchema";

import InputField from '../FormFields/InputField';

//componets
import EditProjectDialog from '../../components/Dialog/EditProjectDialog';
import Title from '../../components/Typography/Title';
import mapProjectsData from '../../utils/mapProjectsData';

//styles
import { StyledButton, StyledHeadingGrid } from "./styles"

//cutom hooks
import { useFetch } from '../../hooks/useFetch';
import { useUpdate } from '../../hooks/useUpdate';

//constants
import { API_URLS } from '../../Constants';
import { getQtrAndFY } from '../../utils/dateUtils';

//context
import { useAuth } from '../../context/AuthContext';

const { formId, formField } = addProjectFormModel;

export default function EditProject() {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = location.state;
  const fetcher = useFetch("get", API_URLS["API_GET_PROJECT_BY_ID"]);
  const postFetcher = useUpdate("post", API_URLS["API_ADD_UPDATE_PROJECT"]);

  //Local Constants
  const [project, setProject] = useState([]);

  const pathItems = [
    'Dashboard',
    'Projects',
    'Edit Project'
  ];

  const [steps1, setSteps1] = useState(['Project Information', 'Project Details/Phase', 'Project Status', 'Connected Roadways', 'Submit Project']);

  const [currentValidationSchema, setSchema] = React.useState(validationSchema[0]);

  const [open, setOpen] = React.useState({
    open: false,
    activeStep: "",
    title: "Edit",
  });
  const [alert, setAlert] = React.useState({
    open: false,
    message: ""
  });

  const [errors, setErrors] = useState([]);

  async function _handleSubmit(values, actionName) {

    if (actionName !== "Cancel") {
      const { fiscalYear, quarter } = getQtrAndFY();
      project[0].notes = values.notes;

      //Set options
      const options = {
        projectData: project[0],
        projectStatus: project[0].status,
        passedCreatedDate: project[0].createdDate,
        passedYrFirstEntered: project[0].yearFirstEntered,
        passedYrPlanActive: project[0].yearPlanActive,
        passedQtrPlanActive: project[0].quarterPlanActive,
        passedReviewedDate: actionName === "Publish" ? new Date() : project[0].reviewedDate,
        passedReviewedBy: actionName === "Publish" ? auth?.user?.username || "" : project[0].reviewedBy
      }
      const mappedProjectData = mapProjectsData(options);

      //For draft
      if (actionName === "Save As Draft") {
        await postFetcher.executeUpdate(mappedProjectData);
        if (postFetcher?.serverResponse?.error) {
          console.error('Error in POST request: ', postFetcher.serverResponse.error);
          navigate('/view-projects')
        }
      }
      else if (actionName === "Publish" || actionName === "Save") {
        const connectedRoadways = mappedProjectData.connectedRoadways;
        if (mappedProjectData.projectType !== "Regional" && mappedProjectData.projectType !== "Regional Transit" && connectedRoadways.length == 0) {
          setAlert({ open: true, message: "Please select the connected roadways to proceed" });
        }
        else if (mappedProjectData.projectType === "Regional" || mappedProjectData.projectType === "Regional Transit") {
          mappedProjectData.connectedRoadways = [];
          mappedProjectData.status = "active";
          mappedProjectData.yearFirstEntered = fiscalYear;
          mappedProjectData.yearPlanActive = fiscalYear;
          mappedProjectData.quarterPlanActive = quarter
          await postFetcher.executeUpdate(mappedProjectData);
          if (postFetcher?.serverResponse?.error)
            console.error('Error in POST request: ', postFetcher.serverResponse.error);
          //navigate('/view-projects');
        }
        else {

          //for draft project, update year first entered
          if (actionName === "Publish") {
            mappedProjectData.yearFirstEntered = fiscalYear;
          }

          let updatedStatus = "inactive"
          connectedRoadways.some((roadway) => {
            if (roadway.rank <= 105) {
              updatedStatus = "active";
              return true;
            }
            return false;
          })

          //Set year plan active and quarter plan active when project moves from inactive to active or draft to active
          if (mappedProjectData.status !== updatedStatus && updatedStatus === "active") {
            mappedProjectData.yearPlanActive = fiscalYear;
            mappedProjectData.quarterPlanActive = quarter
          }

          //update status
          mappedProjectData.status = updatedStatus;
          await postFetcher.executeUpdate(mappedProjectData);
          if (postFetcher?.serverResponse?.error)
            console.error('Error in POST request: ', postFetcher.serverResponse.error);
          //navigate('/view-projects')
        }
      }
    }
    else {
      navigate('/view-projects')
    }
  }

  //Fetch data from API when the component renders.
  useEffect(() => {
    const url = `${API_URLS["API_GET_PROJECT_BY_ID"]}?id=${projectId}`;
    fetcher.setRequest({ passedUrl: url });
  }, []);

  //Once response is received, set appropriate state variables.
  useEffect(() => {
    const response = fetcher.serverResponse;
    if (response) {
      setProject(response);
    }
    else if (fetcher?.serverError) {
      navigate('/view-projects');

    }
  }, [fetcher.serverResponse]);




  const onClose = (updatedData) => {
    setOpen((prev) => ({ ...prev, open: false }));
    setProject(prev => {
      if (prev.length > 0) {
        const finalData = {
          ...updatedData,
          status: prev[0].status,
          createdDate: prev[0].createdDate,
          yearFirstEntered: prev[0].yearFirstEntered,
          yearPlanActive: prev[0].yearPlanActive,
          quarterPlanActive: prev[0].quarterPlanActive,
          reviewedDate: prev[0].reviewedDate,
          reviewedBy: prev[0].reviewedBy
        }
        return [finalData];
      }
      return [updatedData]
    });
  }

  //Navigate to view projects is response received successfully
  useEffect(() => {
    if (postFetcher?.serverResponse)
      navigate('/view-projects');
    else if (postFetcher?.serverResponse?.error)
      console.error('Error in POST request: ', postFetcher.serverResponse.error);
  }, [postFetcher.serverResponse]);


  useEffect(() => {

    if (postFetcher?.serverError && postFetcher?.serverError?.message?.validationErrors) {
      const errorSet = transformStrings(postFetcher.serverError.message.validationErrors);

      setErrors(errorSet);
    }


  }, [postFetcher.serverError]);

  function transformStrings(inputArray) {
    const transformedArray = inputArray.map(inputString => {
        if (inputString.field.includes("projectDetails")) {
            inputString.field = inputString.field.replace("projectDetails", "projectPhase");
        }
        const parts = inputString.field.split('/').map(part => part.replace(/\d+/, '-'));

        // Join with spaces before each camel case letter and capitalize the first letter
        return parts
            .map(part =>
                part.replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space before camel case letters
                    .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize each word
            )
            .join(' ');
    });

    // Ensure unique values in the final output array
    return Array.from(new Set(transformedArray));
}



  return (
    <Grid container spacing={2}>

      {/* Breadcrumbs for the page */}
      <Grid aria-label='Breadcrumbs' item xs={12} md={12}>
        <RouterBreadcrumbs pathItems={pathItems} />
      </Grid>

      <Grid item xs={12}>
        <Title title="Edit Project" />
      </Grid>


      {fetcher.isLoading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
          <CircularProgress />
        </Grid>
      ) : project.length > 0 && (
        <Formik
          initialValues={project[0]}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, values, actions }) => {
            return (
              <Form id={formId}>
                <Grid container>
                  <EditProjectDialog
                    title={open.title}
                    open={open.open}
                    setOpen={setOpen}
                    activeStep={open.activeStep}
                    handleClose={onClose}
                    render={() => void 0}
                    values={values}
                    steps={steps1}
                    setstep={setSteps1}
                    edit={"Edit Existing Project"}
                  />

                  {/* Project Information*/}
                  <StyledHeadingGrid aria-label='Project Information Heading' item xs={12} sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Project Information
                    </Typography>
                    <IconButton aria-label="edit"
                      onClick={() => {
                        setOpen((prev) => ({ ...prev, open: true, activeStep: 0, title: "Edit Project Information" }))
                        setSchema(validationSchema[0])
                      }}
                      sx={{ paddingBottom: 2, paddingLeft: 2 }}>
                      <EditIcon />
                    </IconButton>
                  </StyledHeadingGrid>

                  <Grid item xs={12} sx={{ paddingLeft: 2 }}>
                    <ProjectInfoDetails formValues={project[0]} formfield={formField} type="edit" />
                  </Grid>

                  {/* Project Phase */}
                  <StyledHeadingGrid aria-label='Project Phase Heading' item xs={12} sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2, paddingTop: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Project Phase
                    </Typography>
                    <IconButton aria-label="edit"
                      onClick={() => {
                        setOpen((prev) => ({ ...prev, open: true, activeStep: 1, title: "Edit Project Phase" }))
                        setSchema(validationSchema[1])
                      }} sx={{ paddingBottom: 2, paddingLeft: 2 }}>
                      <EditIcon />
                    </IconButton>
                  </StyledHeadingGrid>
                  <Grid item xs={12} sx={{ paddingLeft: 2 }}>
                    <ProjectPhaseDetails formValues={project[0]} formfield={formField} />
                  </Grid>

                  {/* Project Status */}
                  <StyledHeadingGrid aria-label='Project Status Heading' item xs={12} sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2, paddingTop: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Project Status
                    </Typography>
                    <IconButton aria-label="edit" onClick={() => {
                      setOpen((prev) => ({ ...prev, open: true, activeStep: 2, title: "Edit Project Status" }))
                      setSchema(validationSchema[2])
                    }} sx={{ paddingBottom: 2, paddingLeft: 2 }}>
                      <EditIcon />
                    </IconButton>
                  </StyledHeadingGrid>
                  <Grid item xs={12} sx={{ paddingLeft: 2 }}>
                    <ProjectStatusDetails formValues={project} formfield={formField} type="edit" />
                  </Grid>

                  {/* Connected Roadways */}
                  {project[0].projectType !== 'Regional' && project[0].projectType !== 'Regional Transit' &&
                    <>
                      <StyledHeadingGrid aria-label='Connected Roadways Heading' item xs={12} sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2, paddingTop: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          Connected Roadways
                        </Typography>
                        <IconButton aria-label="edit" onClick={() => {
                          setOpen((prev) => ({ ...prev, open: true, activeStep: 3, title: "Edit Connected Roadways" }))
                          setSchema(validationSchema[3])
                        }} sx={{ paddingBottom: 2, paddingLeft: 2 }}>
                          <EditIcon />
                        </IconButton>
                      </StyledHeadingGrid>
                      <Grid item xs={12} sx={{ paddingLeft: 2 }}>
                        <ConnectedRoadwaysDetails formValues={project[0]} formfield={formField} />
                      </Grid>
                    </>
                  }

                  {/* Notes */}
                  <StyledHeadingGrid item xs={12} sx={{ paddingLeft: 2, paddingTop: 2, paddingRight: "14px" }}>
                    <InputField name={formField.notes.name} label={formField.notes.label} style={{ width: '100%' }} />
                  </StyledHeadingGrid>
                </Grid>

                <Grid item sx={{ marginRight: "10px", padding: "10px" }}>
                  {alert.open &&
                    <Alert severity="error" style={{ color: "#14375A" }}><strong> {alert.message}</strong></Alert>
                  }
                  {errors?.length > 0 && (

                    <Alert severity="error" style={{ color: "#14375A" }}>
                      <strong> Please enter the following required fields:</strong>

                      <ul>
                        {errors.map((error, index) => (
                          <li key={index}>
                            <strong>{error}</strong>
                          </li>

                        ))}
                      </ul>
                    </Alert>

                  )}

                </Grid>

                {/* Buttons */}
                {(project[0].status === "draft") &&
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <StyledButton variant="contained" color="primary" disabled={isSubmitting} onClick={() => _handleSubmit(values, "Save As Draft")}>
                      Save As Draft
                    </StyledButton>
                    <StyledButton variant="contained" color="primary" disabled={isSubmitting} onClick={() => _handleSubmit(values, "Publish")}>
                      Publish
                    </StyledButton>
                  </div>
                }

                {(project[0].status === "inactive" || project[0].status === "active") &&
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <StyledButton variant="contained" color="primary" disabled={isSubmitting} onClick={() => _handleSubmit(values, "Cancel")}>
                      Cancel
                    </StyledButton>
                    <StyledButton variant="contained" color="primary" disabled={isSubmitting} onClick={() => _handleSubmit(values, "Save")}>
                      Save
                    </StyledButton>
                  </div>}

              </Form>
            )
          }}
        </Formik>
      )}
    </Grid>);
}