import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Box,
  ListItemIcon,
  ListItemText,
  Collapse,
  Toolbar
} from "@mui/material";

import {
  Dashboard as DashboardIcon,
  SourceOutlined as ProjectsIcon,
  LocationOnOutlined as RegionsIcon,
  AddRoadOutlined as RoadwaysIcon,
  PersonOutlineOutlined as UsersIcon,
  ExpandLess,
  ExpandMore,
  Info as HelpIcon
} from "@mui/icons-material";

// context
import { useAuth } from "../../context/AuthContext";

//constants
import { CONTACT_US_MAIL_TO } from "../../Constants";

//Defining all Level 1 menu labels
const mainMenuLabels = [
  {
    id: 0,
    label: "Dashboard",
    link: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: 1,
    label: "Projects",
    link: "/view-projects",
    icon: <ProjectsIcon />,
  },
  {
    id: 2,
    label: "Regions",
    link: "/regions",
    icon: <RegionsIcon />,
  },
  {
    id: 3,
    label: "Roadways",
    link: "/view-roadways",
    icon: <RoadwaysIcon />,
  },
  {
    id: 4,
    label: "Users",
    link: "/users",
    icon: <UsersIcon />,
  },
  {
    id: 5,
    label: "Help",
    icon: <HelpIcon />
  }
];

//Defining Level 2 sub menu with strategy types for Projects label.
const subMenuWithStrategyTypes = [
  {
    id: 0,
    label: "View Projects",
    link: "/view-projects",
  },
  {
    id: 1,
    label: "Add New Project",
    link: "/add-new-project",
  },
  {
    id: 2,
    label: "Strategy Types",
    link: "/projects/strategy-types",
  },

];

//Defining Level 2 sub menu without strategy types for Projects label.
const subMenuWithoutStrategyTypes = [
  {
    id: 0,
    label: "View Projects",
    link: "/view-projects",
  },
  {
    id: 1,
    label: "Add New Project",
    link: "/add-new-project",
  }
];

//Defining Level 2 sub menu for Help label.
const subMenuHelp = [
  {
    id: 0,
    label: "Contact Us",
    onClick: () => window.location.href = CONTACT_US_MAIL_TO
  },
  {
    id: 1,
    label: "Resources",
    onClick: () => window.open("/resources/pdf-viewer.html", "_blank")
  }
];

//Renders Sidebar component.
export default function Sidebar() {
  const navigate = useNavigate();
  const [openSubMenus, setOpenSubMenus] = useState({});
  const { auth } = useAuth();
  const currentUser = auth?.user?.privileges || null;
  if (!currentUser) return;

  //Function to toggle sub-menu open state
  const handleSubMenuToggle = (id) => {
    setOpenSubMenus((prevOpenSubMenus) => ({
      ...prevOpenSubMenus,
      [id]: !prevOpenSubMenus[id],
    }));
  };

  //Display Main Menu with all details.
  function DisplayMainMenu(item) {
    return (
      <ListItem key={item.id}>
        <ListItemButton onClick={() => navigate(item.link)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItemButton>
      </ListItem>
    );
  }

  //Display Sub Menu with all details for a passed parent label.
  function DisplaySubMenu(item) {
    let subMenu;

    //for projects
    if (item.id === 1) {

      //Fetch sub menu options as per logged in user.
      subMenu = auth.user.roleName.toLowerCase() === "super admin"
        ? subMenuWithStrategyTypes
        : subMenuWithoutStrategyTypes;
    }
    else if (item.id === 5) {
      subMenu = subMenuHelp
    }

    return (
      <div key={item.id}>
        <ListItem onClick={() => handleSubMenuToggle(item.id)}>
          <ListItemButton>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
            {openSubMenus[item.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSubMenus[item.id]} timeout="auto" unmountOnExit>
          <List component="li">
            {subMenu.map((children) => (
              <ListItemButton
                key={children.id}
                sx={{ pl: 4 }}
                onClick={() => {
                  if (children.onClick) {
                    children.onClick();
                  }
                  else {
                    navigate(children.link);
                  }
                }}
              >
                <ListItemText key={children.id} primary={children.label} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </div>
    );
  }

  //Function to render sidebar.
  function DisplaySidebar() {
    return (
      mainMenuLabels.map((children) => {
        if (children.label === "Projects")
          return DisplaySubMenu(children);
        else if (children.label === "Dashboard" || children.label === "Roadways")
          return DisplayMainMenu(children);
        else if (children.label === "Regions" && auth.user.roleName.toLowerCase() === "super admin")
          return DisplayMainMenu(children);
        else if (children.label === "Users" && currentUser.users?.includes("view"))
          return DisplayMainMenu(children);
        else if (children.label === "Help")
          return DisplaySubMenu(children);
        else
          return null;
      }));
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        zIndex: 1100,
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto', height: '100vh' }}>
        <List>
          {DisplaySidebar()}
        </List>
      </Box>
    </Drawer>
  );
}