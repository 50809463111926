import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Grid, ListItemText, ListItem, List, TextareaAutosize, CircularProgress, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { StyledButton, StyledHeadingGrid, StyledGridContainer, StyledNotesButton, StyledPaper } from "./styles"

//Components
import RouterBreadcrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";
import { getFormattedDate } from '../../utils/getFormattedDateTime';
import mapProjectsData from '../../utils/mapProjectsData';

//cutom hooks
import { useFetch } from '../../hooks/useFetch';
import { useUpdate } from '../../hooks/useUpdate';

//constants
import { API_URLS } from '../../Constants';

//context
import { useAuth } from '../../context/AuthContext';
import { getCombRegImpactedRegs } from '../../utils/getCombRegImpactedRegs';

//Breadcrumb path items for review project.
const reviewPathItems = [
  "Dashboard",
  "Projects",
  "Review Project"
]

//Breadcrumb path items for view project.
const viewPathItems = [
  "Dashboard",
  "Projects",
  "View Project"
]

//Invoked for viewing or reviewing a selected project.
export default function ReviewProject(props) {
  const { auth } = useAuth();

  //React hooks
  const [project, setProject] = useState([]);
  const [edit, setEdit] = useState(false);
  const [notes, setNotes] = useState("");
  const [updatedNotes, setUpdatedNotes] = useState("");
  const [projectPhase, setProjectPhase] = useState([]);
  const [connectedRoadways, setConnectedRoadways] = useState([]);
  const fetcher = useFetch("get", API_URLS["API_GET_PROJECT_BY_ID"]);
  const postFetcher = useUpdate("post", API_URLS["API_ADD_UPDATE_PROJECT"]);
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId, component } = location.state;
  const isReview = component === 'Review'; //Set value as per page.

  const [errors, setErrors] = useState([]);

  //Fetch data from API when the component renders.
  useEffect(() => {
    const url = `${API_URLS["API_GET_PROJECT_BY_ID"]}?id=${projectId}`;
    fetcher.setRequest({ passedUrl: url });
  }, []);

  //Once response is received, set appropriate state variables.
  useEffect(() => {
    const response = fetcher.serverResponse;
    if (response?.length > 0) {
      setProject(response);
    }
 
  }, [fetcher.serverResponse]);

  useEffect(() => {
    if (project?.length > 0) {
      setProjectPhase(project[0].projectDetails);
      setConnectedRoadways(project[0].connectedRoadways);
      setNotes(project[0].notes);
      setUpdatedNotes(project[0].notes);
    }
  }, [project]);

  //Invoked when edit icon is clicked.
  const handleEditNotes = () => {
    setEdit(true);
  };

  //Imvoked when notes text area changes.
  const handleUpdateNotes = (e) => {
    setUpdatedNotes(e.target.value);
  };

  //Invoked when Save is clicked.
  const handleSaveNotes = () => {
    setNotes(updatedNotes);
    setEdit(false);
  };

  //Invoked when Cancel of Notes is clicked.
  const handleCancelEdit = () => {
    setUpdatedNotes(notes);
    setEdit(false);
  };

  //Invoked when Cancel button is clicked.
  const handleCancelBtnClick = () => {
    navigate('/view-projects');
  }

  //Invoked when "Good As Is" button is clicked.
  const handleGoodAsIs = async () => {
    const currentDateTime = getFormattedDate(new Date());

    //Update fields as applicable.
    if (project[0].notes !== updatedNotes) {
      project[0].notes = updatedNotes;
      project[0].lastModifiedDate = currentDateTime;
    }
    const reviewedBy = auth?.user?.username || "";

    //Map form object into database object
    const options = {
      projectData: project[0],
      projectStatus: project[0].status,
      passedCreatedDate: project[0].createdDate,
      passedYrFirstEntered: project[0].yearFirstEntered,
      passedYrPlanActive: project[0].yearPlanActive,
      passedQtrPlanActive: project[0].quarterPlanActive,
      passedReviewedDate: currentDateTime,
      passedReviewedBy: reviewedBy,
    }
    const mappedProjectsData = mapProjectsData(options);

    await postFetcher.executeUpdate(mappedProjectsData);
    if (postFetcher?.serverResponse?.error) {
      console.error('Error in POST request: ', postFetcher.serverResponse.error);
    }
  }


  useEffect(() => {

    if (postFetcher?.serverError && postFetcher?.serverError?.message?.validationErrors) {
      const errorSet = transformStrings(postFetcher.serverError.message.validationErrors);

      setErrors(errorSet);
    }


  }, [postFetcher.serverError]);

  function transformStrings(inputArray) {
    const transformedArray = inputArray.map(inputString => {
        if (inputString.field.includes("projectDetails")) {
            inputString.field = inputString.field.replace("projectDetails", "projectPhase");
        }
        const parts = inputString.field.split('/').map(part => part.replace(/\d+/, '-'));

        // Join with spaces before each camel case letter and capitalize the first letter
        return parts
            .map(part =>
                part.replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space before camel case letters
                    .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize each word
            )
            .join(' ');
    });

    // Ensure unique values in the final output array
    return Array.from(new Set(transformedArray));
}


  //Navigate to view projects is response received successfully
  useEffect(() => {
    if (postFetcher?.serverResponse)
      navigate('/view-projects');
    else if (postFetcher?.serverResponse?.error)
      console.error('Error in POST request: ', postFetcher.serverResponse.error);
  }, [postFetcher.serverResponse]);

  return (
    <Grid container spacing={2}>

      {/* Breadcrumbs for the page */}
      <Grid aria-label='Breadcrumbs' item xs={12} md={12}>
        <RouterBreadcrumbs pathItems={isReview ? reviewPathItems : viewPathItems} />
      </Grid>

      {/* Page Title for the page */}
      <Grid aria-label='Page Title' item xs={12} md={12}>
        <Title title={isReview ? "Review Project" : "View Project"} />
      </Grid>



      {fetcher.isLoading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
          <CircularProgress />
        </Grid>
      ) : project.length > 0 && (
        <>
          {/* Project Information Heading */}
          <StyledHeadingGrid aria-label='Project Information Heading' item xs={12} sx={{ marginTop: "0px" }}>
            <Typography variant="h6" gutterBottom>
              Project Information
            </Typography>
          </StyledHeadingGrid>

          {/* Display Project Information */}
          <Grid item xs={12} md={12}>
            <StyledPaper elevation={3}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <List >
                    <ListItem>
                      <ListItemText
                        aria-label="Project Title"
                        primary={<b>Project Title</b>}
                        secondary={project[0].projectTitle || "None"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        aria-label="Project From"
                        primary={<b>Project From</b>}
                        secondary={project[0].projectFrom || "None"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        aria-label="Strategy Type(s)"
                        primary={<b>Strategy Type(s)</b>}
                        secondary={project[0]?.strategyTypes?.map(type => type.name).join(",") || "None"}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} md={4}>
                  <List >
                    <ListItem>
                      <ListItemText
                        aria-label="Project Type"
                        primary={<b>Project Type</b>}
                        secondary={project[0].projectType || "None"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        aria-label="Is this project a study?"
                        primary={<b>Is this project a study?</b>}
                        secondary={project[0].isProjectStudy ? "Yes" : "No"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        aria-label="Planning Document"
                        primary={<b>Planning Document</b>}
                        secondary={project[0].planningDocument || "None"}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} md={4}>
                  <List >
                    <ListItem>
                      <ListItemText
                        aria-label="Project To"
                        primary={<b>Project To</b>}
                        secondary={project[0].projectTo || "None"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        aria-label="Project Website"
                        primary={<b>Project Website</b>}
                        secondary={project[0].website || "None"}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        aria-label="Impacted Region(s)"
                        primary={<b>Impacted Region(s)</b>}
                        secondary={getCombRegImpactedRegs(project[0]) || "None"}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>

          {/* Project Phase Heading */}
          <StyledHeadingGrid aria-label="Project Phase Heading" item xs={12}>
            <Typography variant="h6">
              Project Phase
            </Typography>
          </StyledHeadingGrid>

          {/* Display Project Phase */}
          <Grid item xs={12} md={12}>
            <StyledPaper elevation={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <List >
                    <ListItem>
                      <ListItemText
                        aria-label="Description"
                        primary={<b>Description</b>}
                        secondary={project[0].description || "None"}
                      />
                    </ListItem>
                  </List>
                </Grid>

                {projectPhase?.length > 0 && (
                  <>
                    <StyledGridContainer container spacing={2} sx={{ marginBottom: "25px", marginLeft: "16px" }}>
                      <Grid item xs={1} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                        Phase #
                      </Grid>
                      <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                        CSJ/Plan ID #
                      </Grid>
                      <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                        Let Date
                      </Grid>
                      <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                        Completion Date
                      </Grid>
                      <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                        Cost Estimate
                      </Grid>
                      {project[0].isProjectStudy && (
                        <Grid item xs={3} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                          Study Cost
                        </Grid>
                      )}
                    </StyledGridContainer>

                    {projectPhase.map((row, index) =>
                      <StyledGridContainer container spacing={2} sx={{ paddingBottom: "25px", marginLeft: "16px" }} key={index}>
                        <Grid item xs={1}>
                          <Typography variant="body2">
                            {row.phase}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            {row.csjNumber || "None"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            {row.letDate ? getFormattedDate(new Date(row.letDate)) : "None"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            {row.completionDate ? getFormattedDate(new Date(row.completionDate)) : "None"}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2">
                            {row.costEstimate || "None"}
                          </Typography>
                        </Grid>
                        {project[0].isProjectStudy && (
                          <Grid item xs={3}>
                            <Typography variant="body2">
                              {row.studyCost || "None"}
                            </Typography>
                          </Grid>
                        )}
                      </StyledGridContainer>

                    )}
                  </>
                )}
              </Grid>
            </StyledPaper>
          </Grid>

          {/* Project Status Heading */}
          <StyledHeadingGrid aria-label="Project Status Heading" item xs={12}>
            <Typography variant="h6" gutterBottom>
              Project Status
            </Typography>
          </StyledHeadingGrid>

          {/* Display Project Status */}
          <Grid item xs={12} md={12}>
            <StyledPaper elevation={3}>

              {/* When project is a study*/}
              {project[0].isProjectStudy && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <List style={{ display: 'flex', flexDirection: 'row' }}>
                      <ListItem>
                        <ListItemText
                          aria-label="Scope Identified"
                          primary={<b>Scope Identified</b>}
                          secondary={project[0].scopeIdentified || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="Funding Acquired"
                          primary={<b>Funding Acquired</b>}
                          secondary={project[0].fundingAcquired || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="Researcher Identified"
                          primary={<b>Researcher Identified</b>}
                          secondary={project[0].researcherIdentified || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="Study In Progress"
                          primary={<b>Study In Progress</b>}
                          secondary={project[0].studyInProgress || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="Study Complete"
                          primary={<b>Study Complete</b>}
                          secondary={project[0].studyComplete || "None"}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              )}

              {/* When project is not a study*/}
              {!project[0].isProjectStudy && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <List style={{ display: 'flex', flexDirection: 'row' }}>
                      <ListItem>
                        <ListItemText
                          aria-label="Feasibility Study"
                          primary={<b>Feasibility Study</b>}
                          secondary={project[0].feasibilityStudy || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="Preliminary Design"
                          primary={<b>Preliminary Design</b>}
                          secondary={project[0].preliminaryDesign || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="NEPA Review"
                          primary={<b>NEPA Review</b>}
                          secondary={project[0].nepaReview || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="Final Design"
                          primary={<b>Final Design</b>}
                          secondary={project[0].finalDesign || "None"}
                        />
                      </ListItem>
                    </List>
                    <List style={{ display: 'flex', flexDirection: 'row' }}>
                      <ListItem>
                        <ListItemText
                          aria-label="Funding"
                          primary={<b>Funding</b>}
                          secondary={project[0].funding || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="Row Acquisition"
                          primary={<b>Row Acquisition</b>}
                          secondary={project[0].rowAcquisition || "None"}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          aria-label="Construction"
                          primary={<b>Construction</b>}
                          secondary={project[0].construction || "None"}
                        />
                      </ListItem>
                      <ListItem />
                    </List>
                  </Grid>
                </Grid>
              )}
            </StyledPaper>
          </Grid>

          {/* Connected Roadways Heading */}
          <StyledHeadingGrid aria-label="Connected Roadways Heading" item xs={12}>
            <Typography variant="h6" gutterBottom>
              Connected Roadways
            </Typography>
          </StyledHeadingGrid>

          {/* Display connected roadways */}
          <Grid item xs={12} md={12}>
            <StyledPaper elevation={3}>
              {connectedRoadways && connectedRoadways.length > 0 ? (
                <>
                  <StyledGridContainer container spacing={2} sx={{ marginBottom: "25px" }}>
                    <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                      Roadway Name
                    </Grid>
                    <Grid item xs={3} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                      From Road
                    </Grid>
                    <Grid item xs={3} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                      To Road
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                      Impact Area
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                      Rank
                    </Grid>
                  </StyledGridContainer>
                  {connectedRoadways.map((row, index) =>
                    <StyledGridContainer container spacing={2} sx={{ paddingBottom: "25px" }} key={index}>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          {row.roadName || "None"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {row.fromRoad || "None"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {row.toRoad || "None"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          {row.impactArea || "None"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          {row.rank || "None"}
                        </Typography>
                      </Grid>
                    </StyledGridContainer>
                  )}
                </>
              ) : (
                <StyledGridContainer container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2">No connected roadways available.</Typography>
                  </Grid>
                </StyledGridContainer>
              )}
            </StyledPaper>
          </Grid>

          {/* Notes Heading */}
          <StyledHeadingGrid aria-label="Notes Heading" item xs={12}>
            <Typography variant="h6">
              Notes
              {isReview && !edit ? (
                <IconButton aria-label="Edit Notes Icon" onClick={handleEditNotes}>
                  <EditIcon />
                </IconButton>
              ) : isReview && (
                <>
                  <StyledNotesButton aria-label="Save Notes Button" variant="outlined" onClick={handleSaveNotes}>
                    Save
                  </StyledNotesButton>
                  <StyledNotesButton aria-label="Cancel Notes Button" variant="outlined" onClick={handleCancelEdit} sx={{ marginLeft: '8px' }}>
                    Cancel
                  </StyledNotesButton>
                </>
              )
              }
            </Typography>
            <Typography style={{ fontSize: 12, fontStyle: 'italic' }}>These notes won't be published</Typography>
          </StyledHeadingGrid>

          {/* Display Notes */}
          <Grid item xs={12} md={12}>
            <StyledPaper elevation={3}>
              <StyledGridContainer container spacing={2}>
                <Grid item xs={12}>
                  {!edit ? (
                    <Typography variant="body1">
                      {notes}
                    </Typography>
                  ) : (
                    <TextareaAutosize
                      aria-label="Notes Textarea"
                      value={updatedNotes}
                      onChange={handleUpdateNotes}
                      style={{ width: '96%' }}
                    />
                  )}
                </Grid>
              </StyledGridContainer>
            </StyledPaper>
          </Grid>

          <Grid aria-label='Error-Block' item xs={12} md={12}>
        {errors?.length > 0 && (
          <div className="error-messages">
            <Alert severity="error">
              <strong>Please enter the following required fields by navigating to Edit Project:</strong>
              <ul>
                {errors.map((error, index) => (

                  <li key={index}>
                    <strong>{error}</strong>
                  </li>

                ))}
              </ul>
            </Alert>
          </div>
        )}
      </Grid>

          {/* Display Buttons as per page*/}
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
            {isReview && (
              <>
                <StyledButton aria-label="Cancel Button" variant="contained" color="primary" onClick={handleCancelBtnClick}>
                  Cancel
                </StyledButton>
                <StyledButton aria-label="Good As Is Button" variant="contained" color="primary" onClick={handleGoodAsIs}>
                  Good As Is
                </StyledButton>
              </>
            )}
            {!isReview && (
              <StyledButton aria-label="Back Button" variant="contained" color="primary" onClick={handleCancelBtnClick}>
                Back
              </StyledButton>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}