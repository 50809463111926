import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  TextField,
  Avatar,
  Grid,
  Box,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";

//Import icons
import ResetPwdIcon from '@mui/icons-material/LockReset';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//Styles
import { StyledButton, StyledInputRequired } from "./styles";

//custom hooks
import { useUpdate } from "../../hooks/useUpdate";

//constants
import { API_URLS } from "../../Constants";

//components
import InformationDialog from "../../components/Dialog/InformationDialog";

export default function ResetPassword() {
  const location = useLocation();
  const type = location.state?.type || 'changePwd';
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const changePwdFetcher = useUpdate("put", API_URLS["API_CHANGE_PWD"]);
  const resetPwdFetcher = useUpdate("post", API_URLS["API_RESET_PWD"]);
  const [informationDialog, setInformationDialog] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => { },
  });
  const [loading, setLoading] = useState(false)

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {

    //set error as '' to remove precious error message
    setError('')

    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    //Validate user input data.
    const username = data.get("username");
    const password = data.get("password");
    const confirmPassword = data.get("confirmPassword");
    if (type === "changePwd" && username && password && confirmPassword) { //for change password page
      try {

        //check if password and confirm password are same or not
        if (password !== confirmPassword) {
          setLoading(false);
          setError('Passwords do not match');
        }
        else {

          //check for strong password
          const minLength = 8;
          const hasLowercase = /[a-z]/;
          const hasUppercase = /[A-Z]/;
          const hasSpecialChar = /[!@#$%^&*()\-_=+]/;
          const hasDigit = /[0-9]/;
          const isStrongPwd = password.length >= minLength
            && hasLowercase.test(password)
            && hasUppercase.test(password)
            && hasSpecialChar.test(password)
            && hasDigit.test(password);

          if (!isStrongPwd) {
            setLoading(false);
            setError('Please use a strong password. A strong password must be at least 8 characters long, contain at least one lowercase letter (a-z), one uppercase letter (A-Z), one digit (0-9), and one special character (e.g., !@#$%^&*()-_=+)');
          }
          else {
            const url = `${API_URLS["API_CHANGE_PWD"]}?token=${token}&username=${username}`;
            changePwdFetcher.executeUpdate({
              "newPassword": password
            }, url);
          }
        }
      } catch (error) {
        console.error(error);
        setError('Some error occured. Please try again.')
      }
    }
    else if (type === "forgotPwd" && username) { //for forgot password page
      try {
        await resetPwdFetcher.executeUpdate({
          "username": username
        })
      }
      catch (error) {
        setError('Some error occured. Please try again.');
      }
    }
  };

  //Once data from POST request is fetched successfully, show message
  useEffect(() => {
    if (changePwdFetcher?.serverResponse?.success) {
      setLoading(false);
      setInformationDialog({
        open: true,
        title: "Success",
        message: `Password changed successfully.`,
        onClose: () => {
          setInformationDialog((prevState) => ({ ...prevState, open: false }));
          navigate("/login")
        }
      });
    }
  }, [changePwdFetcher.serverResponse]);

  //Once error from POST request is received, show error message
  useEffect(() => {
    if (changePwdFetcher?.serverError?.message) {
      setLoading(false);
      setError(changePwdFetcher.serverError.message?.message || changePwdFetcher.serverError.message?.error || 'Some error occured. Please try again.');
    }
  }, [changePwdFetcher.serverError]);

  //Once data from POST request is fetched successfully, show message
  useEffect(() => {
    if (resetPwdFetcher?.serverResponse?.success) {
      setLoading(false);
      setInformationDialog({
        open: true,
        title: "Success",
        message: `Password reset link has been sent to your registered email.`,
        onClose: () => {
          setInformationDialog((prevState) => ({ ...prevState, open: false }));
          navigate("/login")
        }
      });
    }
  }, [resetPwdFetcher.serverResponse]);

  //Once error from POST request is received, show error message
  useEffect(() => {
    if (resetPwdFetcher?.serverError?.message) {
      setLoading(false);
      setError(resetPwdFetcher.serverError.message?.message || resetPwdFetcher.serverError.message?.error || 'Some error occured. Please try again');
    }
  }, [resetPwdFetcher.serverError]);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(images/mitigation-plan.png)",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 25,
            mx: 4,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <ResetPwdIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {type === 'changePwd' ? 'Change Password' : 'Forgot Password'}
            </Typography>
          </div>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              required
              InputProps={{
                autoComplete: "username",
              }}
            />
            {type === 'changePwd' && (
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setShowPassword(!showPassword) }}
                          onMouseDown={handleMouseDownPassword}
                          tabIndex={-1}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                          onMouseDown={handleMouseDownPassword}
                          tabIndex={-1}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </>
            )}

            {error && (
              <StyledInputRequired style={{ color: "red", fontWeight: 'bold' }}>
                {error}
              </StyledInputRequired>
            )}

            {loading ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 3,
                  marginLeft: 0
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, marginLeft: 0 }}
              >
                {type === "changePwd" ? "Change Password" : "Send Password Reset Link"}
              </StyledButton>
            )}
          </Box>
        </Box>
      </Grid>

      {/* Information Dialog component */}
      <InformationDialog
        open={informationDialog.open}
        title={informationDialog.title}
        message={informationDialog.message}
        onClose={informationDialog.onClose}
      />
    </Grid>
  );
}
