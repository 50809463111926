import React, { useState, useEffect } from "react";
import { Alert, Typography, Box, LinearProgress } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
    Delete as DeleteIcon
} from "@mui/icons-material";

//leaflet
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON, TileLayer, Popup } from "react-leaflet";
//components
import ConfirmationDialog from "../../../components/Dialog/ConfirmationDialog";
import Legend from "../../../components/Legend/Legend";
import { FitBounds } from "../../../components/Map/FitBounds";

//formik
import { useFormikContext } from 'formik';

//custom hook
import { useFetch } from "../../../hooks/useFetch";

//constants
import { API_URLS } from "../../../Constants";

//context
import { useAuth } from "../../../context/AuthContext";

export default function ConnectedRoadways(props) {
    const { auth } = useAuth();
    const loggedInUserRole = auth?.user?.roleName || null;
    const [connectedRoadwaysData, setConnectedRoadwaysData] = useState([]);
    const fetcher = useFetch("get", API_URLS["GET_TEXAS100_MONITORED_ROADWAYS"]);
    const [addedSegmentIds, setAddedSegmentIds] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [impactArea, setImpactArea] = useState("None");
    const [confirmationDialog, setConfirmationDialog] = useState({
        open: false,
        title: "",
        message: "",
        onConfirm: () => { }, // Default empty function
    });
    const { values } = props;

    //Fetch data from API when the component renders.
    useEffect(() => {

        //If loggedin user is super admin or TTI user, fetch all monitored roads
        if (loggedInUserRole.toLowerCase() === "super admin" || loggedInUserRole.toLowerCase() === "tti user")
            fetcher.setRequest({});
        else if (auth?.user?.regionAccessibleSet) {
            const userRegionsIds = auth.user.regionAccessibleSet.map(region => region.id);
            const projImpactedRegionIds = values?.impactedRegions?.length > 0
                ? values.impactedRegions.filter(reg => reg.id !== null)?.map(reg => reg.id)
                : [];
            const resRegions = [...userRegionsIds, ...projImpactedRegionIds]?.join(",");
            if (userRegionsIds?.length === 1) {
                const urlWithRegionIds = `${API_URLS["GET_TEXAS100_MONITORED_ROADWAYS"]}?district=${resRegions}`;
                fetcher.setRequest({ passedUrl: urlWithRegionIds });
            }
        }

    }, [loggedInUserRole]);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = fetcher.serverResponse;
        if (response?.length > 0) {
            setConnectedRoadwaysData(response || []);
        }
    }, [fetcher.serverResponse]);

    //updates table data when a new roadway is selected in edit/review page 
    useEffect(() => {

        if (values.connectedRoadways.length > 0) {

            setTableData(values.connectedRoadways);

            //Add previously selected segment Ids for geoJSONStyle to mark them green.

            setAddedSegmentIds(Array.from(new Set(addedSegmentIds.concat(values.connectedRoadways.map((i) => {
                return parseInt(i.segmentId)

            })))));
        
        }
    }, [values.connectedRoadways]);

    //Renders different events for each road segment.
    function onEachRoad(feature, layer) {
        layer.on({
            mouseover: handleMouseOver.bind(null, feature, layer),
            mouseout: handleMouseOut.bind(null, feature, layer),
            click: handleRoadClick.bind(null, feature, layer)
        });
    }

    //Highlights road segemnt on mouseOver.
    const handleMouseOver = (feature, layer) => {
        if (layer.options.color === '#029356') {
            layer.setStyle({
                weight: 3,
                color: '#029356',
                fillOpacity: 0.7,
            });

        }
        else {
            layer.setStyle({
                weight: 3,
                color: 'red',
                fillOpacity: 0.7,
            });
        }
    };

    //Highlights road segemnt on mouseOut.
    const handleMouseOut = (feature, layer) => {
        if (layer.options.color === '#029356') {
            layer.setStyle({
                weight: 3,
                color: '#029356',
                fillOpacity: 0.7,
            });
        }
        else if (feature.properties.arank >= 1 && feature.properties.arank <= 100) {
            layer.setStyle({
                weight: 3,
                color: '#FFC20A',
                fillOpacity: 0.7,
            });
        }
        else {
            layer.setStyle({
                weight: 3,
                color: '#0C7BDC',
                dashArray: "",
                fillOpacity: 0.7,
            });
        }
    };

    //Invoked when a road segment is selected.
    const handleRoadClick = (feature, e) => {
        const roadName = feature.properties.road;
        const fromRoad = feature.properties.from_road;
        const toRoad = feature.properties.to_road;
        const rank = feature.properties.arank;
        const segmentId = feature.id;

        //Store the selected segment in selectedSegment state.
        setSelectedSegment({
            segmentId,
            roadName,
            fromRoad,
            toRoad,
            rank,
        });
    };
    const formik = useFormikContext()

    //Invoked to update formik object for conencted roadways.
    function setRoadwayFields(selectedSegment, taskName) {
        if (taskName === "Add") {

            // Create a new segment object
            const newSegment = {
                'segmentId': selectedSegment.segmentId,
                'roadName': selectedSegment.roadName,
                'fromRoad': selectedSegment.fromRoad,
                'toRoad': selectedSegment.toRoad,
                'rank': selectedSegment.rank,
                'impactArea': selectedSegment.impactArea
            };

            // Create a new array with the existing segments and the new segment
            let updatedConnectedRoadways = [...(formik.values.connectedRoadways || []), newSegment];

            // Update the connectedRoadways field in the formik form object
            formik.setFieldValue('connectedRoadways', updatedConnectedRoadways);
        }
        else if (taskName === "Delete") {
            let updatedRoadways = [];
            if (formik.values && formik.values.connectedRoadways) {
                formik.values.connectedRoadways.forEach((roadway) => {
                    if (roadway && roadway.segmentId !== selectedSegment.segmentId)
                        updatedRoadways.push(roadway)
                })
            }
            formik.setFieldValue('connectedRoadways', updatedRoadways);
        }
        else {
            let updatedRoadways = [];
            if (formik.values && formik.values.connectedRoadways) {
                formik.values.connectedRoadways.forEach((roadway) => {
                    if (roadway && roadway.segmentId === selectedSegment.segmentId)
                        roadway.impactArea = selectedSegment.impactArea
                    updatedRoadways.push(roadway)
                })
            }
            formik.setFieldValue('connectedRoadways', updatedRoadways);
        }
    }

    //Road segment addition: Invoked when "Add Segment" is clicked.
    const handleAddSegment = () => {

        if (selectedSegment && impactArea !== "None") {

            // Check for duplicates before adding the selected segment.
            const isDuplicate = tableData.some(
                (segment) => segment.segmentId === selectedSegment.segmentId
            );

            if (!isDuplicate) {

                // Add impact area value from drop down
                const segmentWithImpactArea = {
                    ...selectedSegment,
                    impactArea: impactArea
                }

                // If not a duplicate, add the selected segment to the data table after sorting as per impact area.
                setTableData((prevTableData) => {
                    const newData = [...prevTableData, segmentWithImpactArea];
                    newData.sort((a, b) => {
                        if (a.impactArea === "On Roadway" && b.impactArea !== "On Roadway")
                            return -1;
                        else if (a.impactArea !== "On Roadway" && b.impactArea === "On Roadway")
                            return 1;
                        else
                            return 0;
                    });
                    return newData;
                });

                //Add selected segment Id for geoJSONStyle.
                setAddedSegmentIds((prevSegmentIds) => {
                    return [...prevSegmentIds, selectedSegment.segmentId];
                });

                //Add connected roadway details in form object.
                setRoadwayFields(segmentWithImpactArea, "Add");

                // Reset selected segment.
                setSelectedSegment(null);

                // Reset impact area field.
                setImpactArea("None");
            }

        }
    };

    //Defines rules for different road segment for different scenarios.
    const geoJSONStyle = (feature) => {
      
        if (addedSegmentIds.includes(feature.id)) //Set color to green if road segment corresponds to an added segment.
            return { color: '#029356' };
        else if (feature.properties.arank >= 1 && feature.properties.arank <= 100)   //Set color to yellow if it is a top 100 road.
            return { color: '#FFC20A' };
        else    //Set default road segment color to blue.
            return { color: '#0C7BDC' };
    }

    //Invoked when the "Impact Area" drop down is changed.
    function handleImpactAreaChange(selectedRow, newImpactArea) {

        //Update connected roadway details in form object.
        selectedRow.impactArea = newImpactArea;
        setRoadwayFields(selectedRow, "Update")

        // Update the impact area in the tableData state based on the selected value.
        setTableData((prevTableData) =>
            prevTableData.map((row) =>
                row.segmentId === selectedRow.segmentId ? { ...row, impactArea: newImpactArea } : row
            )
        );
    }

    //Road segment Deletion: Set action on clicking "Yes" for the selected road segment.
    const handleDeleteConfirm = () => {
        setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
    };

    //Road segment Deletion: Set ConfirmationDialog properties for selected road segment.
    const handleDeleteSelected = (selectedIds) => {
        if (selectedIds && selectedIds.length > 0) {
            setConfirmationDialog({
                open: true,
                title: "Delete Confirmation",
                message: `Are you sure you want to delete the selected road segment ?`,
                onConfirm: () => {
                    const updatedTableData = tableData.filter((row) => !selectedIds.includes(row.segmentId));
                    const deletedRow = tableData.filter((row) => selectedIds.includes(row.segmentId));

                    //Set table data with updated data.
                    setTableData(updatedTableData);

                    //Add selected segment Id for geoJSONStyle.
                    setAddedSegmentIds(() => {
                        const updatedSegmentIds = updatedTableData.map(data => data.segmentId)
                        return updatedSegmentIds;
                    });

                    //Delete selected row from formik object.
                    setRoadwayFields(deletedRow[0], "Delete");

                    setSelectedRows([]);
                    handleDeleteConfirm();
                }
            },
            );
        }
    };

    //Set columns.
    const columns = [
        { field: 'segmentId', headerName: 'Segment Id' },
        { field: 'roadName', headerName: 'Road Name', width: 250 },
        { field: 'fromRoad', headerName: 'From Road', width: 280 },
        { field: 'toRoad', headerName: 'To Road', width: 280 },
        { field: 'rank', headerName: 'Rank', width: 150 },
        {
            field: 'ImpactArea',
            headerName: 'Impact Area',
            width: 230,
            renderCell: (params) => (
                <select
                    value={params.row.impactArea}
                    onChange={(e) => handleImpactAreaChange(params.row, e.target.value)}
                >
                    <option value="None">Select impact area</option>
                    <option value="On Roadway">On Roadway</option>
                    <option value="Parallel">Parallel</option>
                    <option value="Perpendicular">Perpendicular</option>
                    <option value="Before/After Roadway">Before/After Roadway</option>

                </select>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: '120',
            getActions: (params) => [
                <GridActionsCellItem
                    key={params.row.segmentId}
                    aria-label="Delete Action"
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDeleteSelected([params.row.segmentId])}
                />
            ]
        }
    ];

    return (
        <>
            {/* Alert for loading. */}
            {fetcher.isLoading && (
                <div aria-label="Loading Map">
                    <Alert aria-label="Loading map with road segments. Thank you for your patience." style={{ marginBottom: '10px', marginRight: "10px" }} severity="info">Loading map with road segments. Thank you for your patience.</Alert>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress style={{ marginRight: "10px" }} />
                        <MapContainer style={{ height: "500px", marginRight: "10px" }} center={[31.9686, -99.9018]} minZoom={6} zoom={6}>
                            <TileLayer
                                attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            />
                        </MapContainer>
                    </Box>
                </div>
            )}

            {/* Display map */}
            {!fetcher.isLoading && connectedRoadwaysData && (
                <div aria-label="Map" style={{ marginBottom: '20px' }}>

                    {/* Information Alert */}
                    <Alert aria-label="Select all road segments on the map impacted by this project" style={{ marginBottom: '10px', marginRight: "10px" }} severity="info">Select all road segments on the map impacted by this project.</Alert>

                    <MapContainer style={{ height: "500px", marginRight: "10px" }} minZoom={6}>

                        <TileLayer
                            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        <GeoJSON
                            key={JSON.stringify({ connectedRoadwaysData, addedSegmentIds })}
                            data={connectedRoadwaysData}
                            onEachFeature={onEachRoad}
                            style={geoJSONStyle}
                        >
                            {selectedSegment && (
                                <Popup>
                                    <div>
                                        <p style={{ textAlign: "center" }}>
                                            <strong>Road Segment Details</strong>
                                        </p>

                                        <p>
                                            <strong>Roadname : </strong>
                                            {selectedSegment.roadName}
                                            <br />
                                            <strong>From Road : </strong>
                                            {selectedSegment.fromRoad}
                                            <br />
                                            <strong>To Road : </strong>
                                            {selectedSegment.toRoad}
                                            <br />
                                            <strong>Rank : </strong>
                                            {selectedSegment.rank}
                                        </p>

                                        {/* Display drop down only when segment has not been added */}
                                        {!addedSegmentIds.includes(selectedSegment.segmentId) && (
                                            <>
                                                <div>
                                                    This Road segment is <select value={impactArea} onChange={(e) => setImpactArea(e.target.value)}>
                                                        <option value="None">Select impact area</option>
                                                        <option value="On Roadway">On Roadway</option>
                                                        <option value="Parallel">Parallel</option>
                                                        <option value="Perpendicular">Perpendicular</option>
                                                        <option value="Before/After Roadway">Before/After Roadway</option>

                                                    </select> to the project.
                                                </div>

                                                {impactArea === "None" && (
                                                    <p style={{ fontStyle: "italic", color: "red", paddingBottom: '10px' }}>
                                                        Select impact area to add road segment.
                                                    </p>

                                                )}

                                                {impactArea !== "None" && (
                                                    <div style={{ textAlign: "center", paddingBottom: '10px', paddingTop: '10px' }}>
                                                        <button type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={handleAddSegment}
                                                        >
                                                            Add segment
                                                        </button>
                                                    </div>

                                                )}
                                            </>
                                        )}

                                        {/* Check if segment is added already */}
                                        {addedSegmentIds.includes(selectedSegment.segmentId) && (
                                            <>
                                                {addedSegmentIds.find(segment => segment.segmentId === selectedSegment.segmentId) && (
                                                    <p>
                                                        This road segment is <strong>{addedSegmentIds.find(segment => segment.segmentId === selectedSegment.segmentId).impactArea}</strong> to the project.
                                                    </p>
                                                )}

                                                <p style={{ fontStyle: "italic", color: "#029356", paddingBottom: '10px' }}>
                                                    This road segment is already added.
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </Popup>
                            )
                            }
                        </GeoJSON>
                        <FitBounds data={connectedRoadwaysData} />
                    </MapContainer>
                    <Legend />
                </div>
            )}

            {/* DataGrid containing Connected Roadways information */}
            {tableData != null && tableData.length > 0 && (
                <div aria-label="Connected Roadways Table" style={{ marginRight: 10 }}>
                    <Typography aria-label="Connected Roadways Heading" sx={{ fontWeight: "bold", marginBottom: "10px" }}> Connected Roadways </Typography>
                    <DataGrid
                        rows={tableData.slice().sort((a, b) => {
                            if (a.impactArea === "On Roadway" && b.impactArea !== "On Roadway")
                                return -1;
                            else if (a.impactArea !== "On Roadway" && b.impactArea === "On Roadway")
                                return 1;
                            else
                                return 0;
                        })}
                        columns={columns}
                        getRowId={(row) => row.segmentId}
                        style={{ width: '100%' }}
                        autoHeight
                        initialState={{
                            columns: {

                                // Hide segmentId column from data grid view.
                                columnVisibilityModel: {
                                    'segmentId': false
                                },
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15, 20]}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setSelectedRows(newRowSelectionModel);
                        }}
                        selectedRows={selectedRows}
                    />
                </div>
            )}

            {!fetcher.isLoading && tableData.length === 0 && (
                <Alert aria-label="Alert: No data to display. Select at least one road segment on map to proceed." style={{ marginBottom: '10px', marginRight: "10px" }} severity="error">No data to display. Select at least one road segment on map to proceed.</Alert>
            )}

            <ConfirmationDialog
                open={confirmationDialog.open}
                onClose={() => setConfirmationDialog((prevState) => ({ ...prevState, open: false }))}
                title={confirmationDialog.title}
                message={confirmationDialog.message}
                onConfirm={confirmationDialog.onConfirm}
            />
        </>
    );
}
