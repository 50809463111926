import { styled } from '@mui/system';
import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledButton = styled(Button)({
  textTransform: 'None',
  marginLeft: "10px",
  marginTop: "25px",
  marginBottom: "20px"
});

export const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%'
});

export const StyledHeadingGrid = styled(Grid)({
  marginTop: "14px"
});

export const StyledGridContainer = styled(Grid)({
  marginLeft: "2px"
});

export const StyledNotesButton = styled(Button)({
  textTransform: 'none',
  marginTop: "18px",
  marginLeft: '18px',
  marginBottom: '16px',
  padding: 2,
  fontSize: '0.525rem'
});

export const StyledStartegyTypesBtn = styled(Button)({
  textTransform: 'none',
  marginBottom: '15px',
  marginRight: '2px'
});

export const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold'
  }
});

export const StyledDataGridProjects = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold'
  }
});

export const StyledPaper = styled(Paper)({
  alignItems: "center",
  padding: "10px",
  marginRight: "14px"
});

export const StyledTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

export const StyledNoticeTypography = styled(Typography)({
  color: 'black'
});
