import React from 'react';

import { Typography, Grid } from '@mui/material';

import { useFormikContext } from 'formik';
import ProjectInfoDetails from './ProjectInfoDetails';
import ProjectPhaseDetails from './ProjectPhaseDetails';
import ProjectStatusDetails from './ProjectStatusDetails';
import ConnectedRoadwaysDetails from './ConnectedRoadwaysDetails';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import InputField from '../../FormFields/InputField';

//components
import EditProjectDialog from '../../../components/Dialog/EditProjectDialog';

//styles
import { StyledHeadingGrid } from '../styles';

export default function SubmitProject(props) {

  const {
    formField: {
      notes,
    }, values, render, setstep, steps } = props;


  const { values: formValues } = useFormikContext();
  const formfield = props;
  const [open, setOpen] = React.useState({
    open: false,
    activeStep: "",
    title: "Edit",
  });

  const handleClose = () => { setOpen((prev) => ({ ...prev, open: false })); }

  return (
    <Grid container spacing={1}>

      <EditProjectDialog
        title={open.title}
        open={open.open}
        activeStep={open.activeStep}
        handleClose={() => handleClose()}
        render={render}
        values={values}
        steps={steps}
        setstep={setstep}
        edit={"Edit New Project"} />

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
          Submit Project
        </Typography>
      </Grid>

      {/* Project Information Heading */}
      <StyledHeadingGrid aria-label='Project Information Heading' item xs={12} sx={{ display: 'flex', alignItems: 'center', marginBottom: 0, marginTop: 0 }}>
        <Typography variant="h6" gutterBottom>
          Project Information
        </Typography>
        <IconButton aria-label="edit" sx={{ paddingBottom: 2, paddingLeft: 2 }}
          onClick={() => { setOpen((prev) => ({ ...prev, open: true, activeStep: 0, title: "Edit Project Information" })) }}> <EditIcon>
          </EditIcon> </IconButton>
      </StyledHeadingGrid>
      <Grid item xs={12}>
        <ProjectInfoDetails formValues={formValues} formfield={formfield} type="submit" />
      </Grid>

      {/* Project Phase Heading */}
      <StyledHeadingGrid aria-label="Project Phase Heading" item xs={12} sx={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
        <Typography variant="h6">
          Project Phase
        </Typography>
        <IconButton aria-label="edit" sx={{ paddingBottom: 2, paddingLeft: 2 }}
          onClick={() => { setOpen((prev) => ({ ...prev, open: true, activeStep: 1, title: "Edit Project Phase" })) }}>
          <EditIcon /> </IconButton>
      </StyledHeadingGrid>
      <Grid item xs={12}>
        <ProjectPhaseDetails formValues={formValues} formfield={formfield} />
      </Grid>

      {/* Project Status Heading */}
      <StyledHeadingGrid aria-label="Project Status Heading" item xs={12} sx={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
        <Typography variant="h6" gutterBottom>
          Project Status
        </Typography>
        <IconButton aria-label="edit" sx={{ paddingBottom: 2, paddingLeft: 2 }}
          onClick={() => { setOpen((prev) => ({ ...prev, open: true, activeStep: 2, title: "Edit Project Status" })) }}>
          <EditIcon /> </IconButton>
      </StyledHeadingGrid>
      <Grid item xs={12}>
        <ProjectStatusDetails formValues={formValues} formfield={formfield} type="submit" />
      </Grid>

      {formValues.projectType !== 'Regional' && formValues.projectType !== 'Regional Transit' &&
        <>
          {/* Connected Roadways Heading */}
          <StyledHeadingGrid aria-label="Connected Roadways Heading" item xs={12} sx={{ display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
            <Typography variant="h6" gutterBottom>
              Connected Roadways
            </Typography>
            <IconButton aria-label="edit" sx={{ paddingBottom: 2, paddingLeft: 2 }}
              onClick={() => { setOpen((prev) => ({ ...prev, open: true, activeStep: 3, title: "Edit Connected Roadways" })) }}>
              <EditIcon /> </IconButton>
          </StyledHeadingGrid>
          <Grid item xs={12}>
            <ConnectedRoadwaysDetails formValues={formValues} formfield={formfield} />
          </Grid>
        </>
      }
      <StyledHeadingGrid item xs={12} sx={{ paddingLeft: 2, paddingTop: 2, paddingRight: "14px" }}>
        <InputField name={notes.name} label={notes.label} style={{ width: '100%' }} />
      </StyledHeadingGrid>
    </Grid>
  );
}
