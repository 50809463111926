import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Alert, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, gridStringOrNumberComparator } from "@mui/x-data-grid";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as ViewIcon,
  RateReview as ReviewIcon,
  EditRoadOutlined as RoadIcon,
  SouthAmericaOutlined as RegionalIcon,
  DirectionsTransitFilledOutlined as TransitIcon,
  CheckBox as CheckboxIcon,
  InfoOutlined as InfoIcon,
  Unpublished as UnpublishIcon
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { StyledDataGridProjects, StyledTypography, StyledNoticeTypography } from "./styles";

//context
import { useAuth } from "../../context/AuthContext";

//components
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import { getFormattedDate } from "../../utils/getFormattedDateTime";
import RouterBreadcrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";

//custom hook
import { useFetch } from "../../hooks/useFetch";
import { useUpdate } from "../../hooks/useUpdate";

//constants
import { API_URLS } from "../../Constants";

//utils
import { getQtrAndFY, getNextQtrStartDate, getReviewStartDate } from "../../utils/dateUtils";
import mapProjectsData from "../../utils/mapProjectsData";

const pathItems = [
  "Dashboard",
  "Projects",
  "View Projects"
]

export default function ViewProjects() {

  //Get loggedIn user details
  const { auth } = useAuth();
  const loggedInUser = auth?.user || null;

  //React hooks
  const [projectsData, setProjectsData] = useState([])
  const [reloadFlag, setReloadFlag] = useState(false);

  //API fetchers
  const fetcher = useFetch("get", API_URLS["API_GET_PROJECTS"]);
  const deleteFetcher = useUpdate("post", API_URLS["API_DELETE_PROJECTS"]);
  const getFetcher = useFetch("get", API_URLS["API_GET_PROJECT_BY_ID"]);
  const postFetcher = useUpdate("post", API_URLS["API_ADD_UPDATE_PROJECT"]);

  //Fetch data from API when the component renders.
  useEffect(() => {
    fetcher.setRequest({});
  }, [reloadFlag]);

  //Once response is received, set appropriate state variables.
  useEffect(() => {
    const response = fetcher.serverResponse;
    if (response) {

      // Sort the projects alphabetically by name
      const sortedProjects = response.toSorted((a, b) => a.projectTitle?.localeCompare(b.projectTitle));
      setProjectsData(sortedProjects);
      setRows(sortedProjects.filter(row => row.status?.toLowerCase() !== "archived"));
    }
  }, [fetcher.serverResponse]);

  //Once data from DELETE request is fetched, refetch the data 
  useEffect(() => {
    if (deleteFetcher?.serverResponse?.length > 0) {
      setReloadFlag(prev => !prev);
    }
  }, [deleteFetcher.serverResponse]);

  //Once data from GET request is fetched, update the project 
  useEffect(() => {
    if (getFetcher?.serverResponse?.length > 0) {
      const response = getFetcher.serverResponse;

      //Set options
      const options = {
        projectData: response[0],
        projectStatus: "draft",
        passedCreatedDate: response[0].createdDate,
        passedYrFirstEntered: response[0].yearFirstEntered,
        passedYrPlanActive: response[0].yearPlanActive,
        passedQtrPlanActive: response[0].quarterPlanActive,
        passedReviewedDate: response[0].reviewedDate,
        passedReviewedBy: response[0].reviewedBy
      }
      const mappedProjectData = mapProjectsData(options);

      // Update data in the database.
      postFetcher.executeUpdate(mappedProjectData);
    }
  }, [getFetcher.serverResponse]);

  //Once data from POST request is fetched, get all projects 
  useEffect(() => {
    if (postFetcher?.serverResponse)
      setReloadFlag(prev => !prev);
    else if (postFetcher?.serverResponse?.error)
      console.error('Error in POST request: ', postFetcher.serverResponse.error);
  }, [postFetcher.serverResponse]);

  //React Hooks
  const navigate = useNavigate();
  const [archiveChecked, setArchiveChecked] = useState(false);
  const [allProjectsChecked, setAllProjectsChecked] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
    onConfirm: () => { }, // Default empty function
  });

  //Show All Projects when checkbox is checked.
  const handleShowAllProjects = () => {
    if (archiveChecked) {
      setArchiveChecked(!archiveChecked)
    }
    setAllProjectsChecked(!allProjectsChecked)
    const filteredRows = allProjectsChecked
      ? projectsData.filter(row => row.status?.toLowerCase() !== "archived" && row.status?.toLowerCase() !== "deleted")
      : projectsData.filter((row) => row.status?.toLowerCase() !== "deleted")

    setRows(filteredRows);
  };

  //Show Archived Projects when checkbox is checked.
  const handleShowArchivedProjects = () => {
    if (allProjectsChecked) {
      setAllProjectsChecked(!allProjectsChecked)
    }
    setArchiveChecked(!archiveChecked)
    const filteredRows = !archiveChecked
      ? projectsData.filter((row) => row.status?.toLowerCase() === "archived")
      : projectsData.filter(row => row.status?.toLowerCase() !== "archived" && row.status?.toLowerCase() !== "deleted");
    setRows(filteredRows);
  };

  // Project Deletion: Set action on clicking "Confirm" for the selected project.
  const handleDeleteConfirm = () => {
    setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
  };

  // Project Deletion: Set ConfirmationDialog properties for selected project(s).
  const handleDeleteSelected = (selectedIds) => {
    if (selectedIds && selectedIds.length > 0) {
      setConfirmationDialog({
        open: true,
        title: "Delete Confirmation",
        message: `Are you sure you want to delete the selected project(s) ?`,
        onConfirm: () => {

          // Update data in the database.
          deleteFetcher.executeUpdate({ "ids": selectedIds });
          setSelectedRows([]);
          handleDeleteConfirm();
        },
      });
    }
  };

  // Project Unpublish: Set ConfirmationDialog properties for selected project(s).
  const handleUnpublishSelected = (selectedIds) => {
    if (selectedIds && selectedIds.length > 0) {
      setConfirmationDialog({
        open: true,
        title: "Unpublish Confirmation",
        message: `Are you sure you want to unpublish the selected project ?`,
        onConfirm: () => {

          //Get updated project details from database.
          const url = `${API_URLS["API_GET_PROJECT_BY_ID"]}?id=${selectedIds[0]}`;
          getFetcher.setRequest({ passedUrl: url });
          setSelectedRows([]);

          //Close the dialog
          setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
        },
      });
    }
  };

  //Check if ready for review is enabled or not.
  const readyForReview = (reviewedDate) => {

    if (reviewedDate)
      return false;

    const currentDate = new Date();
    const calendarYear = currentDate.getFullYear();

    //get current quarter
    const { quarter } = getQtrAndFY();

    //get next quarter start date
    const nextQtrStartDate = getNextQtrStartDate(calendarYear, quarter);

    //calculate review period
    const reviewStartDate = getReviewStartDate(nextQtrStartDate);

    //check if current date is within the review period
    const isReadyForReview = currentDate >= reviewStartDate && currentDate < nextQtrStartDate;
    return isReadyForReview;
  }

  //Custom Toolbar to display number of records.
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer aria-label="Total number of records">
        <div style={{ marginRight: '15px' }}>
          Total Records: {rows.length}
        </div>
        <GridToolbarFilterButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }

  let columns = [

    //Add status to projectTitle column.
    {
      field: "projectTitle",
      headerName: "Project Title",
      width: 290,
      renderCell: (params) => {
        const projectTitle = params.row.projectTitle
        const projectStatus = params.row.status?.toLowerCase()
        if (projectStatus === "draft") {
          return (
            <>
              <Tooltip title={projectTitle}>
                <StyledTypography aria-label="Project Title" variant="body2" key={params.id}>
                  {projectTitle}
                </StyledTypography>
              </Tooltip>
              <CheckboxIcon aria-label="Project Status - Draft" style={{ marginLeft: '8px', color: 'grey' }} />
            </>
          )
        }
        else if (projectStatus === "active") {
          const isReadyForReview = readyForReview(params.row.reviewedDate);
          return (
            <>
              <Tooltip title={projectTitle}>
                <StyledTypography aria-label="Project Title" variant="body2" key={params.id}>
                  {projectTitle}
                </StyledTypography>
              </Tooltip>
              <CheckboxIcon aria-label="Project Status - Active" style={{ marginLeft: '8px', color: '#029356' }} />

              {/* Check if project is ready for reviewed or not and display icon accordingly. */}
              {isReadyForReview && (
                <Tooltip aria-label="Tooltip for project review"
                  title="This project is due for review. Go to Actions to review.">
                  <Alert aria-label="Alert Icon"
                    variant="none"
                    severity="warning"
                    style={{ color: 'red' }} />
                </Tooltip>
              )}
            </>
          )
        }
        else if (projectStatus === "inactive") {
          const isReadyForReview = readyForReview(params.row.reviewedDate);
          return (
            <>
              <Tooltip title={projectTitle}>
                <StyledTypography aria-label="Project Title" variant="body2" key={params.id}>
                  {projectTitle}
                </StyledTypography>
              </Tooltip>
              <CheckboxIcon aria-label="Project Status - Inactive" style={{ marginLeft: '8px', color: 'orange' }} />

              {/* Check if project is ready for reviewed or not and display icon accordingly. */}
              {isReadyForReview && (
                <Tooltip aria-label="Tooltip for project review"
                  title="This project is due for review. Go to Actions to review.">
                  <Alert aria-label="Alert Icon"
                    variant="none"
                    severity="warning"
                    style={{ color: 'red' }} />
                </Tooltip>
              )}
            </>
          )
        }
        else {
          return (
            <Tooltip title={projectTitle}>
              <StyledTypography aria-label="Project Title" variant="body2" key={params.id}>
                {projectTitle}
              </StyledTypography>
            </Tooltip>
          );
        }
      }
    },

    //Show icons as per different project types.
    {
      field: "projectType",
      headerName: "Type",
      width: 80,
      renderCell: (params) => {
        const projectType = params.row.projectType?.toLowerCase();
        if (projectType === "road") {
          return <RoadIcon aria-label="Project Type - Road" />;
        }
        else if (projectType === "regional") {
          return <RegionalIcon aria-label="Project Type - Regional" />;
        }
        else if (projectType === "transit") {
          return <TransitIcon aria-label="Project Type - Transit" />;
        }
        else if (projectType === "regional transit") {
          return <TransitIcon aria-label="Project Type - Regional Transit" />;
        }
      }
    },

    //Show csj or mtp numbers.
    {
      field: "csjNumber",
      headerName: "CSJ or Plan ID",
      width: 150,
      valueGetter: (params) => {
        return params.row.csjNumbers?.join(", ") || "";
      },
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <StyledTypography aria-label="CSJ Numbers" variant="body2">
            {params.value || ""}
          </StyledTypography>
        </Tooltip>
      )
    },

    //Show strategy types.
    {
      field: "strategyTypesNames",
      headerName: "Strategy Type(s)",
      width: 150,
      valueGetter: (params) => {
        return params.row.strategyTypesNames?.join(", ") || "";
      },
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <StyledTypography aria-label="Strategy Types" variant="body2">
            {params.value || ""}
          </StyledTypography>
        </Tooltip>
      )
    },

    //Show regions.
    {
      field: "regionNames",
      headerName: "Impacted Region(s)",
      width: 190,
      valueGetter: (params) => {
        let regions = params.row.regionNames?.join(", ") || "";

        // Add impacted regions if present
        const impactedRegions = params.row.impactedRegions?.filter((reg) => reg.id !== null) || [];
        if (impactedRegions.length > 0) {
          const impactedRegionNames = impactedRegions.map((reg) => reg.name).join(", ");
          regions = regions ? `${regions}, ${impactedRegionNames}` : impactedRegionNames;
        }

        return regions;
      },
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <StyledTypography aria-label="Regions" variant="body2">
            {params.value || ""}
          </StyledTypography>
        </Tooltip>
      ),
    },

    //Add Good As Is column.
    {
      field: "reviewedDate",
      headerName: "Good As Is",
      width: 120,
      valueGetter: (params) => {
        return params.row.reviewedDate ? getFormattedDate(new Date(params.row.reviewedDate)) : null;
      },
      renderCell: (params) => (
        <Typography aria-label="Reviewed Date" variant="body2">
          {params.value || ""}
        </Typography>
      )
    }
  ];

  //Load columns from the database.
  columns = columns.concat(
    [
      {
        "field": "status",
        "headerName": "Project Status"
      }
    ]
  );

  //Add Last Modified column by combining last modified date and last modified by.
  columns = columns.concat(
    {
      field: "lastModified",
      headerName: "Last Modified",
      width: 120,
      filterable: false,
      renderCell: (params) => {

        const lastModifiedDate = params.row.lastModifiedDate ? getFormattedDate(new Date(params.row.lastModifiedDate)) : "";
        const lastModifiedBy = params.row.lastModifiedBy ? params.row.lastModifiedBy.split('@')[0] || params.row.lastModifiedBy : "";
        return (
          <div style={{ whiteSpace: 'normal' }}>
            <Typography aria-label="Last Modified Date" variant="body2">
              {lastModifiedDate}
            </Typography>
            <Typography aria-label="Last Modified By" variant="body2">
              {lastModifiedBy}
            </Typography>
          </div>
        );
      },
      valueGetter: (params) => {

        // Return a combined value that contains both date and last modified by
        const lastModifiedDate = new Date(params.row.lastModifiedDate);
        const lastModifiedBy = params.row.lastModifiedBy ? params.row.lastModifiedBy.split('@')[0] || params.row.lastModifiedBy : "";
        const formattedDate = getFormattedDate(lastModifiedDate);
        return `${formattedDate} ${lastModifiedBy}`;
      },
      sortComparator: (v1, v2) => {
        const [date1, user1] = v1.split(" "); // Split into date and user
        const [date2, user2] = v2.split(" "); // Split into date and user

        const parsedDate1 = new Date(date1); // Parse the date part for sorting
        const parsedDate2 = new Date(date2); // Parse the date part for sorting

        // First compare by date
        if (!isNaN(parsedDate1) && !isNaN(parsedDate2)) {
          if (parsedDate1.getTime() !== parsedDate2.getTime()) {
            return parsedDate1 - parsedDate2; // Sort by date
          }
        }

        // If dates are the same, compare by lastModifiedBy (username)
        if (user1 < user2) {
          return -1; // user1 comes before user2
        }
        if (user1 > user2) {
          return 1; // user1 comes after user2
        }

        return 0; // If both date and user are the same
      }
    },
  );

  //Ad actions column.
  columns = columns.concat(
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 180,
      getActions: (params) => {

        const { status, id } = params.row;
        const actions = [];
        const loggedInUserRegionIds = loggedInUser?.regionAccessibleSet
          ? loggedInUser.regionAccessibleSet.map(reg => reg.id)
          : [];
        const loggedinUserRole = loggedInUser.roleName?.toLowerCase();
        const projectImpactedRegions = params.row.impactedRegions?.length > 0 ? params.row.impactedRegions.filter(reg => reg.id !== null)?.map(reg => reg.id) : [];

        //Check if any of the loggedin user's region is one of the project's impacted region(s) 
        if ((loggedinUserRole !== "super admin" && loggedinUserRole !== "tti user"
          && projectImpactedRegions && loggedInUserRegionIds.some(userReg => projectImpactedRegions.includes(userReg)))
          || status?.toLowerCase() === "archived") {
          actions.push(
            <GridActionsCellItem
              aria-label="View Action"
              icon={<Typography>View</Typography>}
              label="View"
              onClick={() => navigate(`/review-project`, { state: { projectId: id, component: "View" } })}
            />
          );
        }
        else if (status?.toLowerCase() === "draft") {
          actions.push(
            <GridActionsCellItem
              aria-label="View Action"
              icon={<IconsWithTooltip title="View project details" icon={<ViewIcon sx={{ fontSize: 20 }} />} />}
              label="View"
              onClick={() => navigate(`/review-project`, { state: { projectId: id, component: "View" } })}
            />,
            <GridActionsCellItem
              aria-label="Edit Action"
              icon={<IconsWithTooltip title="Edit project" icon={<EditIcon sx={{ fontSize: 20 }} />} />}
              label="Edit"
              onClick={() => navigate(`/edit-project?`, { state: { projectId: id, component: "Edit" } })}
            />,
            <GridActionsCellItem
              aria-label="Delete Action"
              icon={<DeleteIcon sx={{ fontSize: 20 }} />}
              label="Delete"
              onClick={() => handleDeleteSelected([params.row.id])}
              showInMenu
            />);
        }
        else if (status?.toLowerCase() === "active" || status?.toLowerCase() === "inactive") {
          actions.push(
            <GridActionsCellItem
              aria-label="View Action"
              icon={<IconsWithTooltip title="View project details" icon={<ViewIcon sx={{ fontSize: 20 }} />} />}
              label="View"
              onClick={() => navigate(`/review-project`, { state: { projectId: id, component: "View" } })}
            />,
            <GridActionsCellItem
              aria-label="Edit Action"
              icon={<IconsWithTooltip title="Edit project" icon={<EditIcon sx={{ fontSize: 20 }} />} />}
              label="Edit"
              onClick={() => navigate(`/edit-project?`, { state: { projectId: id, component: "Edit" } })}
            />,
            <GridActionsCellItem
              aria-label="Review Project Action"
              icon={<ReviewIcon sx={{ fontSize: 20 }} />}
              label="Review"
              onClick={() => navigate(`/review-project`, { state: { projectId: id, component: "Review" } })}
              showInMenu
            />,
            <GridActionsCellItem
              aria-label="Unpublish Action"
              icon={<UnpublishIcon sx={{ fontSize: 20 }} />}
              label="Unpublish"
              onClick={() => handleUnpublishSelected([params.row.id])}
              showInMenu
            />
          );
        }
        return actions;
      }
    }
  );

  return (
    <>

      {/* Breadcrumbs for the page */}
      <Grid aria-label="Breadcrumbs for navigation" item xs={12} md={12}>
        <RouterBreadcrumbs pathItems={pathItems} />
      </Grid>

      {/* Banner with important notice */}
      <Grid aria-label="Banner - Grid" item xs={12} sx={{ paddingRight: 3, paddingTop: 3 }}>
        <Alert aria-label="Banner - Important Notice" severity="warning">
          <StyledNoticeTypography variant="h6" sx={{ fontWeight: 'bold' }}>Important:</StyledNoticeTypography>
          <StyledNoticeTypography variant="body2">
            Please ensure <strong>all</strong> draft projects have been reviewed, connected to a roadway, and published <strong>with a date stamp in the "Good As Is" column.</strong>
          </StyledNoticeTypography>
          <StyledNoticeTypography variant="body2">
            A project will not be displayed in a Mitigation Plan unless these steps have been taken.
          </StyledNoticeTypography>
          <StyledNoticeTypography variant="body2">
            Unnecessary draft projects may be deleted.
          </StyledNoticeTypography>
        </Alert>
      </Grid>

      {/* Page Title for the page */}
      <Grid aria-label="Page Title - View Projects" item xs={12} md={12}>
        <div aria-label="View Projects Label" style={{ display: 'flex' }}>
          <Title title="View Projects" />
          <Tooltip title="Hover over a legend item, icon, or text for more information" placement='right'>
            <InfoIcon fontSize="small" style={{ alignSelf: 'center', marginLeft: 8, marginTop: 8 }} />
          </Tooltip>
        </div>
      </Grid>

      {/* Project Status Labels */}
      <Grid container sx={{ marginBottom: "4px" }}>
        <Grid item lg={1} md={3} sm={3} xs={12}>
          <div aria-label="Draft Project Status Label" style={{ display: 'flex', color: 'grey' }}>
            <Tooltip title="Projects not yet published.">
              <Box display="flex" alignItems="center">
                <CheckboxIcon style={{ marginRight: '8px' }} />
                <Typography component="div">
                  Draft
                </Typography>
              </Box>
            </Tooltip>
          </div>
        </Grid>

        <Grid item lg={1} md={3} sm={3} xs={12}>
          <div aria-label="Active Project Status Label" style={{ display: 'flex', color: '#029356' }}>
            <Tooltip title="Published and viewable project connected to a top 105 roadway.">
              <Box display="flex" alignItems="center">
                <CheckboxIcon style={{ marginRight: '8px' }} />
                <Typography>
                  Active
                </Typography>
              </Box>
            </Tooltip>
          </div>
        </Grid>
        <Grid item lg={1} md={3} sm={3} xs={12}>
          <div aria-label="Inactive Project Status Label" style={{ display: 'flex', color: 'orange' }}>
            <Tooltip title="Published and viewable project not connected to a top 105 roadway.">
              <Box display="flex" alignItems="center">
                <CheckboxIcon style={{ marginRight: '8px' }} />
                <Typography>
                  Inactive
                </Typography>
              </Box>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      {/* Project Type Labels */}
      <Grid container sx={{ marginBottom: "20px" }}>
        <Grid item lg={1} md={3} sm={3} xs={12}>
          <div aria-label="Road Project Type Label" style={{ display: 'flex' }}>
            <Tooltip title="Projects relating primarily to roadway infrastructure.">
              <Box display="flex" alignItems="center">
                <RoadIcon style={{ marginRight: '8px' }} />
                <Typography>
                  Road
                </Typography>
              </Box>
            </Tooltip>
          </div>
        </Grid>

        <Grid item lg={1} md={3} sm={3} xs={12}>
          <div aria-label="Regional Project Type Label" style={{ display: 'flex' }}>
            <Tooltip title=" Projects or programs that apply through a region or are not specifically tied to specific locations.">
              <Box display="flex" alignItems="center">
                <RegionalIcon style={{ marginRight: '8px' }} />
                <Typography>
                  Regional
                </Typography>
              </Box>
            </Tooltip>
          </div>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={12}>
          <div aria-label="Transit or Regional Transit Project Type Label" style={{ display: 'flex' }}>
            <Tooltip title="Transit relates to specific and isolated transit projects whereas Regional Transit relates to transit projects with a regional scale unassociated with roadways.">
              <Box display="flex" alignItems="center">
                <TransitIcon style={{ marginRight: '8px' }} />
                <Typography>
                  Transit or Regional Transit
                </Typography>
              </Box>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      {/* Checkboxes and buttons */}
      <Grid item lg={3} md={4} sm={4} xs={12} style={{ display: 'flex' }}>
        <FormControlLabel aria-label="Show All Projects Checkbox"
          control={<Checkbox checked={allProjectsChecked} onChange={handleShowAllProjects} />}
          label="Show All Projects"
        />
        <div aria-label="Archived Projects Label" style={{ display: 'flex' }}>
          <Tooltip title="Archived Projects are completed projects that no longer appear on a mitigation plan. Projects are automatically archived 2 years after completion" placement='right'>
            <Box display="flex" alignItems="center">
              <FormControlLabel aria-label="Show Archived Projects Checkbox"
                control={<Checkbox checked={archiveChecked} onChange={handleShowArchivedProjects} />}
                label="Show Archived Projects"
                sx={{ marginRight: "5px" }}
              />
              <InfoIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
            </Box>
          </Tooltip>
        </div>
      </Grid>

      {/* Data Table */}
      <Grid container sx={{ paddingRight: 3 }}>
        <StyledDataGridProjects
          aria-label="Projects Data Table"
          rows={rows}
          columns={columns}
          autoHeight
          slots={{
            toolbar: CustomToolbar
          }}
          loading={fetcher.isLoading}
          initialState={{
            columns: {

              // Hide column status from data grid view.
              columnVisibilityModel: {
                'status': false
              },
              sorting: {
                sortModel: [
                  {
                    field: 'lastModified',
                  },
                ],
              }
            },
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 15, 20, 25]}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
          selectedRows={selectedRows}
        />
      </Grid>

      {/* ConfirmationDialog component */}
      <ConfirmationDialog
        open={confirmationDialog.open}
        onClose={() => setConfirmationDialog((prevState) => ({ ...prevState, open: false }))}
        title={confirmationDialog.title}
        message={confirmationDialog.message}
        onConfirm={confirmationDialog.onConfirm}
      />
    </>
  );
}