import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip
} from '@mui/material';

export default function MultiSelectField(props) {
  const { label, data, tooltip, onChange, type, helperText, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  useEffect(() => {
    if (data?.length === 1 && selectedValue?.length === 0) {
      helpers.setValue([data[0]]);

      //For regions call the passed onChange method
      if (onChange && type === "regions") {
        onChange([data[0].id]);
      }
    }
  }, [data, selectedValue, helpers]);

  const handleChange = (event) => {
    const { value } = event.target;

    //Map selected ids to their corresponding objects for form value.
    const selectedObjects = value.map(id => data.find(item => item.id === id));
    helpers.setValue(selectedObjects);

    //For regions call the passed onChange method
    if (onChange && type === "regions") {
      onChange(value);
    }
  };
  const displayValue = data.length === 1 ? [data[0].id] : selectedValue.map(item => item.id);


  return (
    <FormControl {...rest} error={isError}>
      <InputLabel>{label}</InputLabel>
      <Tooltip title={tooltip} placement='left'>
        <Select
          label={label}
          {...field}
          multiple
          value={displayValue}
          onChange={handleChange}
          disabled={data?.length === 1}
          renderValue={(selected) =>
            selected
              .map(id => {
                const item = data.find(item => item.id === id);
                return item ? item.name : '';
              })
              .join(', ')
          }>
          {data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
      <FormHelperText>{helperText}</FormHelperText>
      {_renderHelperText()}
    </FormControl>


  );
}

MultiSelectField.defaultProps = {
  data: []
};

MultiSelectField.propTypes = {
  data: PropTypes.array.isRequired
};