import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Grid, Alert, Typography, Box } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
    Delete as DeleteIcon,
    Edit as EditIcon
} from "@mui/icons-material";
import { StyledBottomButton, StyledDataGrid } from './styles';

//Leaflet
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON, TileLayer, useMap } from "react-leaflet";

//Components
import RouterBreadcrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";
import ProjectDropDown from '../../components/DropDown/ProjectDropDown';
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import InformationDialog from '../../components/Dialog/InformationDialog';

//custom hook
import { useFetch } from "../../hooks/useFetch";

//constants
import { API_URLS } from "../../Constants";

//context
import { useConnectedProjectsData } from '../../context/ConnectedProjectsDataContext';

const pathItems = [
    "Dashboard",
    "Edit Roadway"
]
export default function ConnectedProjects() {
    const { connectedProjectsData, updateConnectedProjectsData } = useConnectedProjectsData();
    const [informationDialog, setInformationDialog] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedRoadway } = location.state;
    const [projectsData, setProjectsData] = useState([]);
    const [projectsListDropDown, setProjectsListDropDown] = useState([]);
    const projectsFetcher = useFetch("get", API_URLS["API_GET_PROJECTS"]);
    const roadwayFetcher = useFetch("get",
        `${API_URLS["GET_TEXAS100_MONITORED_ROADWAYS"]}?minRank=${selectedRoadway[0].rank}&maxRank=${selectedRoadway[0].rank}`);

    //React Hooks
    const [selectedRoadwayCoordinates, setSelectedRoadwayCoordinates] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [connectedProjects, setConnectedProjects] = useState(connectedProjectsData);
    const [confirmationDialog, setConfirmationDialog] = useState({
        open: false,
        title: "",
        message: "",
        onConfirm: () => { },
    });

    //Fetch data from API when the component renders.
    useEffect(() => {
        projectsFetcher.setRequest({});
        roadwayFetcher.setRequest({});
    }, []);

    //Once project response is received, set appropriate state variables.
    useEffect(() => {
        const response = projectsFetcher.serverResponse;
        if (response && response.length > 0) {

            // Sort the projects alphabetically by name
            const sortedProjects = response.toSorted((a, b) => a.projectTitle?.localeCompare(b.projectTitle));

            //Set projects state
            setProjectsData(sortedProjects);

            //Filter active and inactive projects
            const activeInactiveProjects = sortedProjects.filter((project) => {
                return (project.status.toLowerCase() === "active" || project.status.toLowerCase() === "inactive")
                    && (project.projectType.toLowerCase() !== "regional" && project.projectType.toLowerCase() !== "regional transit");
            }).map((project) => ({
                id: project.id,
                title: project.projectTitle,
                projectStatus: project.status
            }));

            //set projects drop down
            setProjectsListDropDown(activeInactiveProjects);
        }
    }, [projectsFetcher.serverResponse]);

    //Once roadway response is received, set appropriate state variables.
    useEffect(() => {
        if (roadwayFetcher.serverResponse !== null) {
            setSelectedRoadwayCoordinates(roadwayFetcher.serverResponse);
        }
    }, [roadwayFetcher.serverResponse]);

    //If local conenxtedProjects change then change it in the context as well.
    useEffect(() => {
        updateConnectedProjectsData(connectedProjects);
    }, [connectedProjects]);

    //Set columns.
    const columns = [
        { field: 'projectTitle', headerName: 'Project Title', width: 400 },
        { field: 'projectType', headerName: 'Project Type', width: 200 },
        {
            field: 'ImpactArea',
            headerName: 'Impact Area',
            width: 250,
            renderCell: (params) => (
                <select
                    value={params.row.impactArea}
                    onChange={(e) => handleImpactAreaChange(params.row, e.target.value)}
                >
                    <option value="None">Select impact area</option>
                    <option value="Parallel">Parallel</option>
                    <option value="Perpendicular">Perpendicular</option>
                    <option value="Before/After Roadway">Before/After Roadway</option>
                    <option value="On Roadway">On Roadway</option>
                </select>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 150,
            getActions: (params) => {
                const actions = [];
                const status = params.row.projectStatus.toLowerCase();

                if (status === "archived") {
                    actions.push(
                        <GridActionsCellItem
                            aria-label="View Action"
                            icon={<Typography>View</Typography>}
                            label="View"
                            onClick={() => navigate(`/review-project`, { state: { projectId: params.row.id, component: "View" } })}
                        />
                    );
                }
                else {
                    actions.push(
                        <GridActionsCellItem
                            aria-label="Edit Action"
                            icon={<EditIcon />}
                            label="Edit"
                            onClick={() => navigate(`/edit-project?`, { state: { projectId: params.row.id, component: "Edit" } })}
                        />,
                        <GridActionsCellItem
                            aria-label="Delete Action"
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => handleDeleteSelected([params.row.id])}
                        />
                    );
                }
                return actions;
            }
        }
    ];

    //Invoked when "Select Projects" drop down is changed.
    const handleProjectsDropdownChange = (event) => {
        const selectedProjectId = event.target.value;
        const selectedProject = projectsData.filter((project) => project.id === selectedProjectId);
        if (selectedProject) {

            // Check for duplicates before adding the selected project.
            const isDuplicate = connectedProjects.some(
                (project) => project.id === selectedProjectId
            );
            if (!isDuplicate) {

                // If not a duplicate, add the selected project to the data table.
                setConnectedProjects((prevTableData) => {
                    const connectedProject = selectedProject.map((project) => {
                        return {
                            id: project.id,
                            projectTitle: project.projectTitle,
                            projectType: project.projectType,
                            impactArea: "None",
                            projectStatus: project.status
                        }
                    })
                    const newData = [...prevTableData, ...connectedProject];
                    return newData;
                });
            }
        }
    }

    //Invoked when "Impact Area" drop down is changed.
    function handleImpactAreaChange(selectedRow, newImpactArea) {

        selectedRow.impactArea = newImpactArea;

        // Update the impact area in the connectedProjects state based on the selected value.
        setConnectedProjects((prevConnectedProjects) =>
            prevConnectedProjects.map((row) =>
                row.id === selectedRow.id ? { ...row, impactArea: newImpactArea } : row
            )
        );
    }

    //Connected Project Deletion: Set action on clicking "Yes" for selected project.
    const handleDeleteConfirm = () => {
        setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
    };

    //Connected Project Deletion: Set ConfirmationDialog properties for selected project.
    const handleDeleteSelected = (selectedIds) => {
        if (selectedIds && selectedIds.length > 0) {
            setConfirmationDialog({
                open: true,
                title: "Delete Confirmation",
                message: `Are you sure you want to delete the selected project ?`,
                onConfirm: () => {
                    const updatedConnectedProjects = connectedProjects.filter((row) => !selectedIds.includes(row.id));

                    //Set table data with updated data.
                    setConnectedProjects(updatedConnectedProjects);

                    setSelectedRows([]);
                    handleDeleteConfirm();
                }
            },
            );
        }
    };

    const handleSaveBtn = () => {

        //check if any impact area is empty
        const projectsWithNoneImpact = connectedProjects.filter(proj => proj.impactArea.toLowerCase() === "none");
        if (projectsWithNoneImpact.length === 0) {
            //Update the context
            updateConnectedProjectsData(connectedProjects);

            //Navigate back to edit roadway page
            navigate(`/edit-roadway`, { state: { segmentId: selectedRoadway[0].segmentId, navFromView: false } })
        }
        else {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Impact area cannot be empty. Choose an impact area.`,
            });
        }
    };

    return (
        <>
            {/* Breadcrumbs for the page */}
            <Grid item xs={12}>
                <RouterBreadcrumbs pathItems={pathItems} />
            </Grid>

            {/* Page Title for the page */}
            <Grid item xs={12}>
                <Title title="Add Connected Projects" />
            </Grid>

            {/* Roadway name */}
            <Grid item xs={12}>
                <Typography variant="h6">
                    <strong>Roadway Name: </strong>
                    {selectedRoadway[0].roadwayTitle}
                </Typography>
            </Grid>

            {/* Display error if coordinates not loaded properly */}
            {selectedRoadwayCoordinates.length === 0 && (
                <>
                    <Grid item xs={12}>
                        <Alert aria-label="Loading map with roadway. Thank you for your patience." style={{ marginBottom: '10px', marginRight: "10px" }} severity="info">Loading map with roadway. Thank you for your patience.</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ width: '100%' }}>
                            <MapContainer style={{ height: "450px", marginRight: "10px" }} center={[31.9686, -99.9018]} minZoom={6} zoom={6}>
                                <TileLayer
                                    attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                />
                            </MapContainer>
                        </Box>
                    </Grid>
                </>
            )}

            {/* Display map if coordinates load properly */}
            {selectedRoadwayCoordinates.length > 0 && (
                <Grid item xs={12}>
                    <div aria-label="Map" style={{ marginBottom: '10px' }}>
                        <Box sx={{ width: '100%' }}>
                            <MapContainer style={{ height: "450px", marginRight: "10px" }} minZoom={6}>
                                <TileLayer
                                    attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                />
                                <GeoJSON
                                    data={selectedRoadwayCoordinates}
                                    style={{ color: '#0C7BDC' }}
                                />
                                {/* Dropdown to select project */}
                                {projectsListDropDown !== null && (
                                    <ProjectDropDown
                                        projectsList={projectsListDropDown}
                                        onChange={handleProjectsDropdownChange} />
                                )}

                                {/* Zoom to fit all coordinates */}
                                <CustomMapContainer selectedRoadwayCoordinates={selectedRoadwayCoordinates} />
                            </MapContainer>
                        </Box>
                    </div>
                </Grid>
            )}

            {/* Alert when no data in table */}
            {connectedProjects.length === 0 && (
                <>
                    <Grid item xs={12}>
                        <Alert aria-label="No data to display. Select project from drop down to add." style={{ marginBottom: '10px', marginRight: "10px" }} severity="error">No data to display. Select project from drop down to add.</Alert>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <StyledBottomButton aria-label="Back Button" variant="contained" color="primary" onClick={() => { navigate(`/edit-roadway`, { state: { segmentId: selectedRoadway[0].segmentId, navFromView: false } }) }}>
                            Back
                        </StyledBottomButton>
                    </Grid>
                </>
            )}

            {/* DataGrid containing Connected Projects information */}
            {connectedProjects != null && connectedProjects.length > 0 && (
                <>
                    <Grid item xs={12} style={{ marginRight: 10 }}>
                        <Typography aria-label="Connected Projects Heading" sx={{ fontWeight: "bold", marginBottom: "10px", marginTop: "25px" }}> Connected Projects</Typography>
                        <StyledDataGrid
                            aria-label="Connected Projects Table"
                            rows={connectedProjects.slice().sort((a, b) => {
                                if (a.impactArea === "On Roadway" && b.impactArea !== "On Roadway")
                                    return -1;
                                else if (a.impactArea !== "On Roadway" && b.impactArea === "On Roadway")
                                    return 1;
                                else
                                    return 0;
                            })}
                            columns={columns}
                            rowKey={(row) => row.id}
                            style={{ width: '100%' }}
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15, 20]}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setSelectedRows(newRowSelectionModel);
                            }}
                            selectedRows={selectedRows}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <StyledBottomButton aria-label="Save Button" variant="contained" color="primary" onClick={handleSaveBtn}>
                            Save
                        </StyledBottomButton>
                    </Grid>
                </>
            )}

            <ConfirmationDialog
                open={confirmationDialog.open}
                onClose={() => setConfirmationDialog((prevState) => ({ ...prevState, open: false }))}
                title={confirmationDialog.title}
                message={confirmationDialog.message}
                onConfirm={confirmationDialog.onConfirm}
            />

            {/* Information Dialog component */}
            <InformationDialog
                open={informationDialog.open}
                onClose={() =>
                    setInformationDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={informationDialog.title}
                message={informationDialog.message}
                onConfirm={informationDialog.onConfirm}
            />
        </>
    );
}

// Custom component to handle map zoom
function CustomMapContainer({ selectedRoadwayCoordinates }) {
    const map = useMap();

    useEffect(() => {
        if (selectedRoadwayCoordinates.length > 0) {
            const bounds = calculateBounds();
            map.fitBounds(bounds);
        }
    }, [map, selectedRoadwayCoordinates]);

    // Calculate bounding box
    const calculateBounds = () => {
        let minLat = Infinity, maxLat = -Infinity, minLng = Infinity, maxLng = -Infinity;
        selectedRoadwayCoordinates.forEach(feature => {
            feature.geometry.coordinates.forEach(coordinate => {
                coordinate.forEach(point => {
                    const [lng, lat] = point;
                    minLat = Math.min(minLat, lat);
                    maxLat = Math.max(maxLat, lat);
                    minLng = Math.min(minLng, lng);
                    maxLng = Math.max(maxLng, lng);
                });
            });
        });
        return [[minLat, minLng], [maxLat, maxLng]];
    }
    return null;
}