//utils
import { getQtrAndFY } from "./dateUtils";
import { getFormattedDate } from "./getFormattedDateTime";

export default function mapProjectsData(options) {

    //Destructure options
    const {
        projectData,
        projectStatus,
        passedCreatedDate = null,
        passedYrFirstEntered = null,
        passedYrPlanActive = null,
        passedQtrPlanActive = null,
        passedReviewedDate = null,
        passedReviewedBy = null,
    } = options;
    const currentDate = getFormattedDate(new Date());
    const { fiscalYear, quarter } = getQtrAndFY();
    if (projectData) {
        const mappedProjectData = {
            ...projectData,
            isProjectStudy: typeof (projectData.isProjectStudy) === "boolean" ? projectData.isProjectStudy : projectData.isProjectStudy.toLowerCase() === "yes",
            projectDetails: projectData.projectDetails?.map(details => ({
                ...details,
                letDate: details.letDate || null,
                completionDate: details.completionDate || null,
                costEstimate: details.costEstimate || null,
                studyCost: details.studyCost || null
            })) || [],
            yearFirstEntered: passedYrFirstEntered || fiscalYear,
            yearPlanActive: passedYrPlanActive || projectStatus === "active" ? fiscalYear : null,
            quarterPlanActive: passedQtrPlanActive || projectStatus === "active" ? quarter : "",
            regions: projectData?.regions && Array.isArray(projectData.regions) ? projectData.regions.map(region => region.id).join(",") : projectData.regions,
            impactedRegion: projectData?.impactedRegions && Array.isArray(projectData.impactedRegions) ? projectData.impactedRegions.map(region => region.id).join(",") : projectData.impactedRegions,
            strategyTypes: projectData?.strategyTypes && Array.isArray(projectData.strategyTypes) ? projectData.strategyTypes.map(type => type.id).join(",") : projectData.strategyTypes,
            status: projectStatus,
            createdDate: passedCreatedDate || currentDate,
            lastModifiedDate: currentDate,
            reviewedDate: passedReviewedDate,
            reviewedBy: passedReviewedBy
        };
        return mappedProjectData;
    }
    return null;
}