import React, { useState, useEffect } from "react";
import { StyledInputLabel } from "../../pages/Users/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  Grid,
  Button,
} from "@mui/material";

//constants
import { userAgencyOptions } from "../../Constants";

//Update regions list
const modifyRegionsLst = (userRegionsLst) => {
  if (userRegionsLst.length > 1) {
    // Remove "All" from the list if it exists.
    const indexOfAll = userRegionsLst.indexOf("All");
    if (indexOfAll > -1) userRegionsLst.splice(indexOfAll, 1);

    // Sort the regions alphabetically
    const sortedRegions = userRegionsLst.sort((a, b) => a.localeCompare(b));

    // Add "All" back to the top of the list
    return ["All", ...sortedRegions];
  }
  return userRegionsLst;
};

export default function UserInputDialog({
  open,
  onClose,
  onConfirm,
  firstName: initialFirstName,
  lastName: initialLastName,
  role: initialRole,
  region: initialRegion,
  agency: initialAgency,
  email: initialEmail,
  loggedInUser,
  dialogType,
}) {
  const [firstName, setFirstName] = useState(initialFirstName || "");
  const [lastName, setLastName] = useState(initialLastName || "");
  const [role, setRole] = useState(initialRole || "");
  const [region, setRegion] = useState(initialRegion || "");
  const [agency, setAgency] = useState(initialAgency || "");
  const [email, setEmail] = useState(initialEmail || "");
  const userRegionsLst = loggedInUser?.regions
    ? loggedInUser.regions.split(",")
    : [];
  const userRegionOptions = modifyRegionsLst(userRegionsLst);
  const userRoleOptions =
    loggedInUser?.roleName.toLowerCase() === "super admin"
      ? loggedInUser.allUserRoles?.split(",")
      : ["Regional User"];

  useEffect(() => {
    setFirstName(initialFirstName);
    setLastName(initialLastName);
    setRole(initialRole);
    setRegion(initialRegion);
    setAgency(initialAgency);
    setEmail(initialEmail);
  }, [
    initialFirstName,
    initialLastName,
    initialRole,
    initialRegion,
    initialAgency,
    initialEmail,
  ]);

  useEffect(() => {
    //Set first element as the default value if only one value is there
    if (userRegionOptions.length === 1) {
      setRegion(userRegionOptions[0]);
    }
    if (userRoleOptions.length === 1) {
      setRole(userRoleOptions[0]);
    }
  }, [open]);

  //Invoked when value in first name changes.
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  //Invoked when value in last name changes.
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  //Invoked when value in role changes.
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  //Invoked when value in region changes.
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  //Invoked when value in agency changes.
  const handleAgencyChange = (event) => {
    setAgency(event.target.value);
  };

  //Invoked when value in email changes.
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCancel = () => {
    if (dialogType === "add") {
      setFirstName("");
      setLastName("");
      setAgency("");
      setEmail("");
      setRegion("");
      setRole("");
    } else {
      setFirstName(firstName);
      setLastName(lastName);
      setRole(role);
      setRegion(region);
      setAgency(agency);
      setEmail(email);
    }
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(firstName, lastName, role, region, agency, email, () => {
      setFirstName("");
      setLastName("");
      setRole("");
      setRegion("");
      setAgency("");
      setEmail("");
    });
  };

  return (
    <Dialog aria-label="Confirmation Dialog" open={open} onClose={onClose}>
      <DialogTitle
        aria-label="Dialog Title"
        id="alert-dialog-title"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        {dialogType === "add" ? "Add User" : "Edit User"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Info */}
          <Grid item xs={12}>
            <StyledInputLabel aria-label="Fields marked in * are mandatory.">
              Fields marked in <em style={{ color: "red" }}>*</em> are
              mandatory.
            </StyledInputLabel>
          </Grid>

          {/* First Name label and Textfield */}
          <Grid item xs={6}>
            <InputLabel aria-label="First Name label">
              First Name <em style={{ color: "red" }}>*</em>
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="first-name"
              aria-label="First Name Text Field"
              fullWidth
              variant="outlined"
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </Grid>

          {/* Last Name label and Textfield */}
          <Grid item xs={6}>
            <InputLabel aria-label="Last Name label">Last Name</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="last-name"
              aria-label="Last Name Text Field"
              fullWidth
              variant="outlined"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </Grid>

          {/* Role label and Drop Down */}
          <Grid item xs={6}>
            <InputLabel aria-label="Role label">
              Role <em style={{ color: "red" }}>*</em>
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <Select
              id="role-dropdown"
              aria-label="Role Drop Down"
              fullWidth
              variant="outlined"
              value={role || ""}
              onChange={handleRoleChange}
              disabled={userRoleOptions?.length === 1}
            >
              {userRoleOptions?.map((role, index) => (
                <MenuItem key={index} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {/* Email label and Textfield */}
          <Grid item xs={6}>
            <InputLabel aria-label="Email label">
              Email <em style={{ color: "red" }}>*</em>
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="email"
              aria-label="Email Text Field"
              fullWidth
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>

          {/* Region label and Drop Down */}
          <Grid item xs={6}>
            <InputLabel aria-label="Region label">
              Region <em style={{ color: "red" }}>*</em>
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <Select
              id="region-dropdown"
              aria-label="Region Drop Down"
              fullWidth
              variant="outlined"
              value={region || ""}
              onChange={handleRegionChange}
              disabled={userRegionOptions?.length === 1}
            >
              {userRegionOptions?.length > 0 &&
                userRegionOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </Grid>

          {/* Agency label and Drop Down */}
          <Grid item xs={6}>
            <InputLabel aria-label="Agency label">Agency</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <Select
              id="agency-dropdown"
              aria-label="Agency Drop Down"
              fullWidth
              variant="outlined"
              value={agency || ""}
              onChange={handleAgencyChange}
            >
              {userAgencyOptions.length > 0 &&
                userAgencyOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button aria-label="Close" onClick={handleCancel} autoFocus>
          Cancel
        </Button>
        <Button aria-label="Confirm" onClick={handleConfirm} color="primary">
          {dialogType === "add" ? "Add" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
