import axios from "axios";

//Renders services for user authentication.
export default function AuthService() {

    //User login
    const login = async (username, password) => {

        //API call.
        const response = await axios.post("/api/auth/signin", {
            username: username,
            password: password
        });
        if (response?.data?.token) {
            let user = {}
            const userLoggedInTime = new Date().getTime();

            //Iterate through response.
            Object.entries(response.data).forEach(([key, value]) => {

                if (key !== "token" && key !== "success" && key !== "expiresInSeconds")
                    user[key] = value;
                else if (key === "token")
                    sessionStorage.setItem('token', value);
                else if (key === "expiresInSeconds")
                    sessionStorage.setItem('expiresInSeconds', value);
            });
            sessionStorage.setItem("user", JSON.stringify(user));
            sessionStorage.setItem("loggedInTime", userLoggedInTime.toString());
            return user;
        }
        else {
            console.error("No response");
            return null;
        }
    };

    return {
        login
    };
};