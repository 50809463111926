import * as Yup from 'yup';
import addProjectFormModel from './AddProjectFormModel';
const {
  formField: {
    projectTitle,
    planningDocument,
    isProjectStudy,
    regions,
    strategyTypes,
    projectType,
    projectTo,
    projectFrom,
    website,
    description,
    projectDetails,
    feasibilityStudy,
    preliminaryDesign,
    nepaReview,
    finalDesign,
    funding,
    rowAcquisition,
    construction,
    scopeIdentified,
    fundingAcquired,
    researcherIdentified,
    studyInProgress,
    studyComplete,
    connectedRoadways,
    notes,
    status,
    reviewedDate,
    reviewedBy,
    createdDate,
    lastModifiedDate,
    lastModifiedBy
  }
} = addProjectFormModel;


export default [
  Yup.object().shape({
    [projectTitle.name]: Yup.string().max(255).required(`${projectTitle.requiredErrorMsg}`),
    [planningDocument.name]: Yup.string().required(`${planningDocument.requiredErrorMsg}`),
    [isProjectStudy.name]: Yup.string().required(`${isProjectStudy.requiredErrorMsg}`),
    [regions.name]: Yup.array().min(1, `${regions.requiredErrorMsg}`),
    [strategyTypes.name]: Yup.array().min(1, `${strategyTypes.requiredErrorMsg}`),
    [projectType.name]: Yup.string().required(`${projectType.requiredErrorMsg}`),
    [projectTo.name]: Yup.string(),
    [projectFrom.name]: Yup.string(),
    [website.name]: Yup.string(),
  }),
  Yup.object().shape({
    [description.name]: Yup.string().required(`${description.requiredErrorMsg}`),
    projectDetails: Yup.array().of(
      Yup.object().shape({
        // [projectDetails.csjNumber.name]: Yup.string(),
        [projectDetails.csjNumber.name]: Yup.string(),
        [projectDetails.letDate.name]: Yup.date().nullable(),
        [projectDetails.completionDate.name]: Yup.string().nullable(),
        [projectDetails.costEstimate.name]: Yup.number().required(`${projectDetails.costEstimate.requiredErrorMsg}`),
        [projectDetails.studyCost.name]: Yup.number()
      })
    ).required('Must have at least one project details').min(1, 'Minimum 1 project details'),
  }),

  Yup.object().shape({
    [feasibilityStudy.name]: Yup.string(),
    [preliminaryDesign.name]: Yup.string(),
    [nepaReview.name]: Yup.string(),
    [finalDesign.name]: Yup.string(),
    [funding.name]: Yup.string(),
    [rowAcquisition.name]: Yup.string(),
    [construction.name]: Yup.string(),

    [scopeIdentified.name]: Yup.string(),
    [fundingAcquired.name]: Yup.string(),
    [researcherIdentified.name]: Yup.string(),
    [studyInProgress.name]: Yup.string(),
    [studyComplete.name]: Yup.string()
  }),

  Yup.object().shape({

    connectedRoadways: Yup.array().of(
      Yup.object().shape({
        [connectedRoadways.segmentId.name]: Yup.string(),
        [connectedRoadways.roadName.name]: Yup.string(),
        [connectedRoadways.fromRoad.name]: Yup.string(),
        [connectedRoadways.toRoad.name]: Yup.string(),
        [connectedRoadways.rank.name]: Yup.string(),
        [connectedRoadways.impactArea.name]: Yup.string(),
      })
    ).required('Must have at least one roadway details').min(1, 'Minimum 1 roadway details'),
  }),

  Yup.object().shape({
    [notes.name]: Yup.string(),
    [status.name]: Yup.string(),
    [reviewedDate.name]: Yup.date(),
    [reviewedBy.name]: Yup.string(),
    [createdDate.name]: Yup.date(),
    [lastModifiedDate.name]: Yup.date(),
    [lastModifiedBy.name]: Yup.string(),

  })


];

