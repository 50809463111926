import * as React from 'react';
import { Typography } from '@mui/material';

export default function Title({ title }) {
    return (
        <Typography color="inherit" variant="h5" component="h1" sx={{ marginTop: "20px", marginBottom: "15px", fontWeight: "bold" }}>
            {title}
        </Typography>
    );

}