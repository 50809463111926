import React from 'react';
import { Grid, ListItemText, ListItem, List } from '@mui/material';
import { StyledPaper } from "../styles"

import addProjectFormModel from "../AddNewProject/FormModel/AddProjectFormModel"

const { formField } = addProjectFormModel;

function ProjectStatusDetails(props) {

  let {
    formValues, type }
    = props;

  if (type === "edit") {
    formValues = formValues[0];
  }

  const isProjectStudy = typeof (formValues.isProjectStudy) === "boolean"
    ? formValues.isProjectStudy
    : formValues.isProjectStudy.toLowerCase() === "yes";

  return (
    <Grid item xs={12} md={12}>
      <StyledPaper elevation={3}>
        {isProjectStudy ?
          (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <List style={{ display: 'flex', flexDirection: 'row' }}>
                  <ListItem>
                    <ListItemText
                      aria-label="Scope Identified"
                      primary={<b>Scope Identified</b>}
                      secondary={formValues.scopeIdentified || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="Funding Acquired"
                      primary={<b>Funding Acquired</b>}
                      secondary={formValues.fundingAcquired || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="Researcher Identified"
                      primary={<b>Researcher Identified</b>}
                      secondary={formValues.researcherIdentified || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="Study In Progress"
                      primary={<b>Study In Progress</b>}
                      secondary={formValues.studyInProgress || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="Study Complete"
                      primary={<b>Study Complete</b>}
                      secondary={formValues.studyComplete || "None"}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>

          )
          :
          (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <List style={{ display: 'flex', flexDirection: 'row' }}>
                  <ListItem>
                    <ListItemText
                      aria-label="Feasibility Study"
                      primary={<b>Feasibility Study</b>}
                      secondary={formValues.feasibilityStudy || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="Preliminary Design"
                      primary={<b>Preliminary Design</b>}
                      secondary={formValues.preliminaryDesign || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="NEPA Review"
                      primary={<b>NEPA Review</b>}
                      secondary={formValues.nepaReview || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="Final Design"
                      primary={<b>Final Design</b>}
                      secondary={formValues.finalDesign || "None"}
                    />
                  </ListItem>
                </List>
                <List style={{ display: 'flex', flexDirection: 'row' }}>
                  <ListItem>
                    <ListItemText
                      aria-label="Funding"
                      primary={<b>Funding</b>}
                      secondary={formValues.funding || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="Row Acquisition"
                      primary={<b>Row Acquisition</b>}
                      secondary={formValues.rowAcquisition || "None"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      aria-label="Construction"
                      primary={<b>Construction</b>}
                      secondary={formValues.construction || "None"}
                    />
                  </ListItem>
                  <ListItem />
                </List>
              </Grid>
            </Grid>

          )
        }
      </StyledPaper>
    </Grid>
  );
}
export default ProjectStatusDetails;