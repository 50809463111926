import { styled } from '@mui/system';
import { Chip, Grid, Card, Typography } from '@mui/material';

//themes
import themes from "../../themes/defaultTheme";

export const StyledGridContainer = styled(Grid)({
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 35,
    paddingBottom: 10
});

export const StyledGrid = styled(Grid)({
    paddingBottom: 20,
});

export const StyledChip = styled(Chip)({
    backgroundColor: themes.default.palette.primary.main,
    color: themes.default.palette.primary.contrastText,
    paddingRight: '12px',
    paddingLeft: '4px',
    '&:hover': {
        backgroundColor: themes.default.palette.primary.light,
        color: themes.default.palette.primary.main,
    },
    transition: 'background-color 0.3s, color 0.3s',
    '& .MuiChip-label': {
        paddingRight: "2px"
    },
    '& .MuiChip-icon': {
        order: 1,
        fontSize: '14px',
        paddingRight: '6px',
        color: themes.default.palette.primary.contrastText
    },
});

export const StyledCard = styled(Card)({
    maxWidth: '100%',
    marginRight: '35px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2) !important'
});

export const StyledLabel = styled(Typography)({
    fontSize: "16px",
    fontWeight: "bold",
    paddingRight: "6px"
});

export const StyledText = styled(Typography)({
    fontSize: "14px",
    paddingTop: "2px"
});

export const StyledTop100Grid = styled(Grid)({
    display: 'flex',
    paddingLeft: "18px"
});