import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox
} from '@mui/material';

export default function MultiCheckboxField(props) {
    const { label, data, tooltip, onChange, type, ...rest } = props;
    const [field, meta, helpers] = useField(props);
    const { value: selectedValue } = field;

    const handleChange = (event) => {
        const { value, checked } = event.target;
        const newSelectedValues = checked
            ? [...selectedValue, data.find(item => item.id === value)]
            : selectedValue.filter(item => item.id !== value);
        helpers.setValue(newSelectedValues);
    };

    return (
        <FormControl {...rest} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
            <FormLabel sx={{ whiteSpace: 'nowrap', marginRight: 2 }}>{label}</FormLabel>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}>
                {data.map((item) => (
                    <FormControlLabel
                        key={item.id}
                        control={
                            <Checkbox
                                value={item.id}
                                checked={selectedValue.some(selectedItem => selectedItem.id === item.id)}
                                onChange={handleChange}
                            />
                        }
                        label={item.name}
                    />
                ))}
            </div>
        </FormControl>
    );
}

MultiCheckboxField.defaultProps = {
    data: []
};

MultiCheckboxField.propTypes = {
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    type: PropTypes.string
};
