export function getImpactAreaForRoadSegment(impactAreaString, segmentId) {
    const impactAreaLst = impactAreaString.split(',');
    
    const res = impactAreaLst.map(item => {
        const [segId, impact] = item.split(':');
        return {
            segmentId: segId,
            impactArea: impact || "None"
        }

    });
    if (res?.length > 0) {
        //filter the current segment id
        const filteredResult = res.filter(item => item.segmentId === segmentId+"");
        return filteredResult[0]?.impactArea;
    }
    return "None";
}