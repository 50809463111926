import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  LinearProgress,
  Alert,
  CardContent,
  Button,
} from "@mui/material";


//leaflet
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON, TileLayer, Popup } from "react-leaflet";

//components
import Title from "../../components/Typography/Title";
import { FitBounds } from "../../components/Map/FitBounds";
import SubHeader from "../../components/Header/SubHeader";

//custom hook
import { useFetch } from "../../hooks/useFetch";

//constants
import { API_URLS, CONTACT_CARDS } from "../../Constants";

//styles
import {
  StyledGridContainer,
  StyledGrid,
  StyledCard
} from "./styles";
import themes from "../../themes/defaultTheme";

export default function HomePage() {
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [top100RoadwaysData, setTop100RoadwaysData] = useState([]);
  const roadwaysFetcher = useFetch("get", `${API_URLS["GET_TEXAS100_MONITORED_ROADWAYS"]}?minRank=0&maxRank=100`);

  useEffect(() => {
    roadwaysFetcher.setRequest({});
  }, []);

  //Once response is received, set appropriate state variables.
  useEffect(() => {
    const response = roadwaysFetcher.serverResponse;
    if (response?.length > 0) {
      setTop100RoadwaysData(response || []);
    } else if (response?.error) {
      console.error("Error in GET roadways request", response.error);
    }
  }, [roadwaysFetcher.serverResponse]);

  //Set geoJSon style
  const geoJSONStyle = () => {
    return { color: "#0C7BDC" };
  };

  //Invoked when a road segment is selected.
  const handleRoadClick = (feature, layer) => {
    const roadName = feature.properties.road;
    const fromRoad = feature.properties.from_road;
    const toRoad = feature.properties.to_road;
    const rank = feature.properties.arank;
    const segmentId = feature.id;

    //Store the selected segment in selectedSegment state.
    setSelectedSegment({
      segmentId,
      roadName,
      fromRoad,
      toRoad,
      rank,
    });
  };

  //Highlights road segemnt on mouseOver.
  const handleMouseOver = (layer) => {
    layer.setStyle({
      weight: 3,
      color: "red",
      fillOpacity: 0.7,
    });
  };

  //Highlights road segemnt on mouseOut.
  const handleMouseOut = (layer) => {
    layer.setStyle({
      weight: 3,
      color: "#0C7BDC",
      dashArray: "",
      fillOpacity: 0.7,
    });
  };

  //Renders different events for each road segment.
  function onEachRoad(feature, layer) {
    layer.on({
      mouseover: handleMouseOver.bind(null, layer),
      mouseout: handleMouseOut.bind(null, layer),
      click: handleRoadClick.bind(null, feature),
    });
  }

  //triggers when View Related Plans button is clicked
  const handleViewRelatedPlansBtnClick = (segmentId) => {
    const backendPageUrl = `${window.location.origin}/pages/roadway/${segmentId}`;

    // Open the backend URL in a new tab
    window.open(backendPageUrl, "_blank");
  };

  return (
    <>
      <SubHeader displayBtns={["mitigationPlans", "tx100OfficialSite", "login"]} />
      <StyledGridContainer container>

        {/* About heading */}
        <Grid aria-label="About" item xs={12} sx={{ textAlign: "left" }}>
          <Title title="About" />
        </Grid>

        {/* About content */}
        <StyledGrid
          aria-label="About content"
          item
          xs={12}
          sx={{ textAlign: "left" }}
        >
          <Typography variant="body1">
            In response to increased roadway congestion throughout Texas, since 2009 the Texas Legislature has mandated that TxDOT annually produce a ranked list of the 100 most congested road segments in the state.
            As part of that mandate, the Texas Legislature also mandates that a congestion mitigation plan be drafted in coordination with the local transportation agencies for all road segments in the top 100. These plans, updated quarterly, include any projects that may improve congestion and mobility—including alternatives to highway construction or projects on other road segments that may have an impact on the top 100 segment.
          </Typography>
          <br />
          <Typography variant="body1">
            This site provides access to those mitigation plans by rank and region as well as the system used to create and manage those plans.
          </Typography>
        </StyledGrid>

        {/* For more information heading */}
        <Grid
          aria-label="For more information heading"
          item
          xs={12}
          sx={{ textAlign: "left" }}
        >
          <Title title="For more information" />
        </Grid>

        {/* Contact cards */}
        {CONTACT_CARDS?.length > 0 && CONTACT_CARDS.map((contact, index) => {
          return (
            <StyledGrid key={index} aria-label={`Contact card ${index + 1}`} item xs={4}>
              <StyledCard>
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {contact.name}
                  </Typography>
                  <Typography variant="h9" sx={{ fontWeight: "bold" }}>
                    {contact.agency}
                  </Typography>
                  <Typography
                    variant="h9"
                    sx={{ color: themes.default.palette.primary.contrastText }}
                  >
                    {contact.email}
                  </Typography>
                  <Typography variant="h9">
                    {contact.phoneNumber}
                  </Typography>
                </CardContent>
              </StyledCard>
            </StyledGrid>
          )
        })}

        {/* Map */}
        <StyledGrid item xs={12}>
          {/* Map Alert for loading. */}
          {roadwaysFetcher.isLoading && (
            <div aria-label="Loading Map">
              <Alert
                aria-label="Loading map with road segments. Thank you for your patience."
                severity="info"
                sx={{ marginBottom: "8px" }}
              >
                Loading map with road segments. Thank you for your patience.
              </Alert>
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
                <MapContainer
                  style={{ height: "700px" }}
                  center={[31.9686, -99.9018]}
                  minZoom={6}
                  zoom={6}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </MapContainer>
              </Box>
            </div>
          )}

          {/* Display map */}
          {!roadwaysFetcher.isLoading && top100RoadwaysData && (
            <div aria-label="Map">
              <Alert
                aria-label="Click on any road segment to view associated mitigation plans"
                severity="info"
                sx={{ marginBottom: "8px" }}
              >
                Click on any road segment to view associated mitigation plans
              </Alert>
              <MapContainer style={{ height: "700px" }} minZoom={6}>
                <TileLayer
                  attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <GeoJSON
                  key={JSON.stringify({ top100RoadwaysData })}
                  data={top100RoadwaysData}
                  onEachFeature={onEachRoad}
                  style={geoJSONStyle}
                >
                  {selectedSegment && (
                    <Popup>
                      <div>
                        <p style={{ textAlign: "center" }}>
                          <strong>Road Segment Details</strong>
                        </p>

                        <p>
                          <strong>Roadname : </strong>
                          {selectedSegment.roadName}
                          <br />
                          <strong>From Road : </strong>
                          {selectedSegment.fromRoad}
                          <br />
                          <strong>To Road : </strong>
                          {selectedSegment.toRoad}
                          <br />
                          <strong>Rank : </strong>
                          {selectedSegment.rank}
                          <br />

                          {/* Center the button using a div with textAlign center */}
                          <div style={{ textAlign: "center" }}>
                            <Button
                              aria-label="View Mitigation Plans Button"
                              color="secondary"
                              onClick={() => { handleViewRelatedPlansBtnClick(selectedSegment.segmentId) }}
                              sx={{ textTransform: "none" }}
                            >
                              View Related Plans
                            </Button>
                          </div>
                        </p>
                      </div>
                    </Popup>
                  )}
                </GeoJSON>
                <FitBounds data={top100RoadwaysData} />
              </MapContainer>
            </div>
          )}
        </StyledGrid>
      </StyledGridContainer>
    </>
  );
}
