import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledButton = styled(Button)({
    textTransform: 'none',
    marginBottom: '15px',
    marginRight: '2px'
});

export const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold'
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important'
    },
});