import { Select, MenuItem, Paper } from "@mui/material";

export default function ProjectDropDown({ projectsList, onChange }) {

    return (
        <Paper style={{ top: '12px', left: '55px', zIndex: 500, position: 'absolute', width: '275px' }}>
            <Select
                value="Select Project"
                fullWidth
                variant="outlined"
                sx={{ '& .MuiOutlinedInput-input': { padding: '10px' }, '& .MuiSelect-select': { paddingTop: '10px', paddingBottom: '10px' } }}
                onChange={onChange}
            >
                <MenuItem value="Select Project">Select Project</MenuItem>
                {projectsList.length > 0 && projectsList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </Paper>
    );
}