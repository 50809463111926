import { getFormattedPrevYrRank } from "./getFormattedPrevYrRank";

export const getLatestYearRank = (previousRanks) => {

    //Format previous ranks from string into an array of objects 
    const prevRanksArray = getFormattedPrevYrRank(previousRanks);

    //Find the latest year and rank
    const latestYearRank = prevRanksArray?.length > 0 ? prevRanksArray.reduce((latest, current) => {
        return current.year > latest.year ? current : latest;
    }) : null;
    
   // Check if the rank is NaN or invalid and return "None" if so
   return latestYearRank && !isNaN(latestYearRank.rank)
   ? latestYearRank.rank
   : "None";
};
