import { Button } from "@mui/material";
import themes from "../../themes/defaultTheme";

export default function RoadwayTitle({ road }) {

  const handleClick = () => {
    const backendPageUrl = `${window.location.origin}/pages/roadway/${road.segmentId}`;

    // Open the backend URL in a new tab
    window.open(backendPageUrl, "_blank");
  };
  return (
    <Button
      variant="text"
      sx={{
        fontSize: "18px",
        fontWeight: "bold",
        paddingLeft: "18px",
        textTransform: "none",
        color: themes.default.palette.primary.main,
      }}
      onClick={handleClick}
    >
      <span style={{ color: themes.default.palette.primary.contrastText }}>
        Rank {road.rank}
      </span>
      <span style={{ color: themes.default.palette.primary.contrastText, marginLeft: "4px", marginRight: "4px" }}>
        -
      </span>
      {road.title}
    </Button>
  );
}
