import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { debugHelper } from "../utils/debug";
import AuthHeader from "../services/AuthHeader";
import AuthToken from "../services/AuthToken";
import { BYPASS_AUTHORIZATION_LIST } from "../Constants";
/**
 * @typedef {Object} useFetchReturn
 * @property {function} setRequest - function to call api
 * @property {boolean} isLoading - loading status
 * @property {Object} serverResponse - server response
 * @property {Object} serverError - server error
 */

/**
 * Customized hook for calling api
 * @param {string} method - Http request method, could be get/post.
 * @param {string} url - Http request url
 * @returns {useFetchReturn} - returns a function to call api, loading status, server response and server error
 */

export const useFetch = (method, url) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [serverResponse, setServerResponse] = useState(null);
    const [serverError, setServerError] = useState(null);
    const batchRequest = useCallback(
        (bodies) => {
            if (!Array.isArray(bodies)) {
                debugHelper.log("Error-callAPIBatch(): The input is not valid.");
                setServerError("Error-callAPIBatch(): The input is not valid.");
                setIsLoading(false);
                return;
            }
            const checkStatus = (response) => {
                if (response.ok) {
                    debugHelper.log("useFetch():response.ok");
                    return Promise.resolve(response);
                } else {
                    debugHelper.log("useFetch():response.failed");
                    return Promise.reject(new Error(response.statusText));
                }
            };
            const parseJSON = (response) => {
                return response.json();
            };
            setIsLoading(true);
            Promise.all(
                bodies.map((body) => {
                    if (!validateJSON(body, handleError)) return false;
                    fetch(url, {
                        method: method,
                        body: body,
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                        },
                    })
                        .then(checkStatus)
                        .then(parseJSON)
                        .catch((e) => {
                            debugHelper.log("There was a problem while calling the api!", e);
                        });
                })
            ).then((data) => {
                setServerResponse(data);
                setIsLoading(false);
                setServerError(null);
            });
        },
        [url, method]
    );

    const handleError = (e) => {
        const { message = "There was a problem while calling the api!" } = e;
        debugHelper.log(message, e);
        setServerError(message);
        setIsLoading(false);
    };

    const validateJSON = (data, handleError) => {

        // sanitize the data
        const e = { message: "The data is not valid." };
        if (data === false) {
            handleError(e);
            return false;
        }
        if (data === null) {
            handleError(e);
            return false;
        }
        if (data === undefined) {
            handleError(e);
            return false;
        }
        if (data === "") {
            handleError(e);
            return false;
        }
        if (data === 0) {
            handleError(e);
            return false;
        }
        if (data === "0") {
            handleError(e);
            return false;
        }
        if (data === "false") {
            handleError(e);
            return false;
        }
        if (data === "true") {
            handleError(e);
            return false;
        }
        if (data === "null") {
            handleError(e);
            return false;
        }
        if (data === "undefined") {
            handleError(e);
            return false;
        }
    };

    const singleRequest = useCallback(
        async (body, credentials, requestUrl) => {
            const currentDate = new Date();

            //Check if token has expired or not.
            const isTokenValid = AuthToken(currentDate);
            if (isTokenValid || BYPASS_AUTHORIZATION_LIST.includes(requestUrl?.split('?')[0])) {
                await fetch(requestUrl, {
                    method: method,
                    body: body,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        ...AuthHeader()
                    },
                    credentials: credentials,
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then((data) => {
                                    if (response.ok) {
                                        debugHelper.log(
                                            "The request is successful and the response is ",
                                            data
                                        );
                                        setServerResponse(data);
                                        setServerError(null);
                                    } else {
                                        debugHelper.log("useFetch()-Something went wrong ", {
                                            response,
                                        });
                                        setServerError({ status: response.status, message: data });
                                        setServerResponse(null);
                                    }
                                    setIsLoading(false);
                                })
                                .catch((err) => {
                                    debugHelper.log("useFetch()-Something went wrong ", {
                                        response,
                                    });
                                    setServerError({
                                        status: response.status,
                                        message: "The response is not a valid JSON",
                                    });
                                    setServerResponse(null);
                                    setIsLoading(false);
                                });
                            return;
                        }
                        else if (response.status === 404) {
                            debugHelper.log("The request status is 404");
                            response
                                .json()
                                .then((data) => {
                                    debugHelper.log("404-data:", data);
                                    setServerError({ status: response.status, message: data });
                                })
                                .catch((err) => {
                                    setServerError({
                                        status: false,
                                        message: "Resource not found.",
                                    });
                                    setIsLoading(false);
                                });
                            setServerResponse(null);
                            setIsLoading(false);
                            return;
                        }
                        else if (response.status === 400) {
                            debugHelper.log("The request status is 400");
                            response
                                .json()
                                .then((data) => {
                                    setServerError({ status: response.status, message: data });
                                    debugHelper.log("400-data:", data);

                                })
                                .catch((err) => {
                                    setServerError({
                                        status: false,
                                        message: "Validation failed.",
                                    });
                                    setIsLoading(false);
                                });
                            setServerError({ status: response.status });
                            setServerResponse(null);
                            setIsLoading(false);
                            return;
                        }

                        else if (response.status === 500) {
                            debugHelper.log("The request status is 500");
                            response
                                .json()
                                .then((data) => {
                                    debugHelper.log("500-data:", data);
                                    setServerError({ status: response.status, message: data });
                                })
                                .catch((err) => {
                                    setServerError({
                                        status: false,
                                        message: "Something went wrong on the server.",
                                    });
                                    setIsLoading(false);
                                });
                            setServerResponse(null);
                            setIsLoading(false);
                            return;
                        }
                        else if (response.status === 403) {
                            debugHelper.log("The request status is 403");
                            response
                                .json()
                                .then((data) => {
                                    setServerError({ status: response.status, message: data });
                                    debugHelper.log("403-data:", data);

                                })
                                .catch((err) => {
                                    setServerError({
                                        status: false,
                                        message: "Validation failed.",
                                    });
                                    setIsLoading(false);
                                });
                            setServerError({ status: response.status });
                            setServerResponse(null);
                            setIsLoading(false);
                            return;
                        }
                        setServerError({
                            status: response.status,
                            message: response.statusText,
                        });
                        setServerResponse(null);
                        setIsLoading(false);

                        return;
                    })
                    .catch((e) => {
                        setServerError({
                            status: e.status,
                            message: "The server is not reachable, please contact the admin.",
                        });
                        setServerResponse(null);
                        setIsLoading(false);
                        debugHelper.log("Fetch Error:", e);
                    });

            }
            else {
                sessionStorage.clear();
                navigate('/login');
            }
        },
        [url, method]
    );

    const setRequest = useCallback(
        async ({ body, type = "single", credentials = "same-origin", passedUrl }) => {
            const requestUrl = passedUrl || url;
            debugHelper.log("Call api:", requestUrl);
            debugHelper.log("setRequest(body)-body:", body);
            setIsLoading(true);
            if (type === "multiple") {
                batchRequest(body, credentials);
                return;
            }

            if (type === "single") {
                singleRequest(body, credentials, requestUrl);
                return;
            }
        },
        [url, method]
    );

    return { setRequest, isLoading, serverResponse, serverError };
};