import { styled } from '@mui/system';
import { Button, Grid, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledTopButton = styled(Button)({
    textTransform: 'none',
    marginBottom: '15px !important',
    marginRight: '2px'
});

export const StyledBottomButton = styled(Button)({
    textTransform: 'none',
    marginTop: "15px",
    marginLeft: '10px',
    marginBottom: '20px'
});

export const StyledHeadingGrid = styled(Grid)({
    marginTop: "14px",
    marginBottom: "8px"
});

export const StyledGridContainer = styled(Grid)({
    marginLeft: "2px"
});

export const StyledNotesButton = styled(Button)({
    textTransform: 'none',
    marginTop: "18px",
    marginLeft: '18px',
    marginBottom: '16px',
    padding: 2,
    fontSize: '0.525rem'
});

export const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold'
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important'
    },
});

export const StyledPaper = styled(Paper)({
    alignItems: "center",
    padding: "10px",
    marginRight: "14px"
});