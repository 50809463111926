import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Grid, ListItemText, ListItem, List, CircularProgress } from '@mui/material';
import { StyledBottomButton, StyledHeadingGrid, StyledGridContainer, StyledPaper } from './styles';

//Components
import RouterBreadcrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";

//custom hook
import { useFetch } from "../../hooks/useFetch";

//constants
import { API_URLS } from "../../Constants";

//utils
import { getFormattedPrevYrRank } from '../../utils/getFormattedPrevYrRank';
import { getImpactAreaForRoadSegment } from '../../utils/getImpactAreaForRoadSegment';

//Breadcrumb path items to view roadway.
const roadwayPathItems = [
    "Dashboard",
    "Roadways",
    "View Roadway"
]

//Invoked for viewing a roadway.
export default function ViewSelectedRoadway() {
    const navigate = useNavigate();
    const location = useLocation();
    const { segmentId } = location.state;
    const fetcher = useFetch("get", `${API_URLS["API_GET_ROADWAY_BY_ID"]}?id=${segmentId}`);
    const [previousRanks, setPreviousRanks] = useState([]);

    //React hooks
    const [roadway, setRoadway] = useState([]);

    //Fetch data from API when the component renders.
    useEffect(() => {
        fetcher.setRequest({});
    }, []);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = fetcher.serverResponse;
        if (response?.success && response?.data?.length > 0) {
            setRoadway(response.data);
            setPreviousRanks(getFormattedPrevYrRank(response.data[0].previousRanks));
        }
    }, [fetcher.serverResponse]);

    return (
        <Grid container spacing={2}>

            {/* Breadcrumbs for the page */}
            <Grid aria-label='Breadcrumbs' item xs={12} md={12}>
                <RouterBreadcrumbs pathItems={roadwayPathItems} />
            </Grid>

            {/* Page Title for the page */}
            <Grid aria-label='Page Title' item xs={12} md={12}>
                <Title title="View Roadway" />
            </Grid>

            {fetcher.isLoading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
                    <CircularProgress />
                </Grid>
            ) : roadway.length > 0 && (
                <>
                    {/* Roadway Information Heading */}
                    < StyledHeadingGrid aria-label='Roadway Information Heading' item xs={12} sx={{ marginTop: "0px" }}>
                        <Typography variant="h6" gutterBottom>
                            Roadway Information
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Roadway Information */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="TTI Segment ID"
                                                primary={<b>TTI Segment ID</b>}
                                                secondary={roadway[0].segmentId || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Rank"
                                                primary={<b>Rank</b>}
                                                secondary={roadway[0].rank || "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Annual Hours of Delay Per Mile"
                                                primary={<b>Annual Hours of Delay Per Mile</b>}
                                                secondary={roadway[0].annualDelayMile || "None"}
                                            />
                                        </ListItem>
                                        {roadway[0].annualCongestionCost && (
                                            <ListItem>
                                                <ListItemText
                                                    aria-label="Annual Congestion Cost in $"
                                                    primary={<b>Annual Congestion Cost in $</b>}
                                                    secondary={roadway[0].annualCongestionCost || "None"}
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Year Plan Active"
                                                primary={<b>Year Plan Active</b>}
                                                secondary={roadway[0].yearPlanActive || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Quarter Plan Active"
                                                primary={<b>Quarter Plan Active</b>}
                                                secondary={roadway[0].quarterPlanActive || "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                    </Grid>

                    {/* Roadway Specifics Heading */}
                    <StyledHeadingGrid aria-label="Roadway Specifics Heading" item xs={12}>
                        <Typography variant="h6" >
                            Roadway Specifics
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Roadway Specifics */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Roadway Title"
                                                primary={<b>Roadway Title</b>}
                                                secondary={roadway[0].roadwayTitle || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Roadway Name"
                                                primary={<b>Roadway Name</b>}
                                                secondary={roadway[0].roadwayName || "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="From"
                                                primary={<b>From</b>}
                                                secondary={roadway[0].from || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="To"
                                                primary={<b>To</b>}
                                                secondary={roadway[0].to || "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <List >
                                        <ListItem>
                                            <ListItemText
                                                aria-label="County"
                                                primary={<b>County</b>}
                                                secondary={roadway[0].county || "None"}
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                aria-label="Region(s)"
                                                primary={<b>Region(s)</b>}
                                                secondary={roadway[0].region && ('name' in roadway[0].region) ? roadway[0].region['name'] : "None"}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </StyledPaper>
                    </Grid>

                    {/* Previous Ranks Heading */}
                    <StyledHeadingGrid aria-label="Previous Ranks Heading" item xs={12}>
                        <Typography variant="h6">
                            Previous Ranks
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Previous Ranks */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            {previousRanks.length === 0 && (
                                <StyledGridContainer container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">No data available.</Typography>
                                    </Grid>
                                </StyledGridContainer>
                            )}
                            {previousRanks?.length > 0 && (
                                <>
                                    {Array(Math.ceil(previousRanks.length / 6)).fill().map((_, i) => {
                                        const start = i * 6;
                                        const end = start + 6;
                                        const chunk = previousRanks.slice(start, end);
                                        return (
                                            <React.Fragment key={i}>

                                                {/* Years */}
                                                <StyledGridContainer container spacing={2}>
                                                    <Grid item xs={1} sx={{ marginTop: "20px" }}>
                                                        <b>Year</b>
                                                    </Grid>
                                                    {chunk.map((row, index) =>
                                                        <Grid item xs={1} key={index}>
                                                            <List style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <ListItem key={row.year}>
                                                                    <ListItemText
                                                                        aria-label="Year"
                                                                        primary={<i><b>{row.year}</b></i>}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Grid>
                                                    )}
                                                </StyledGridContainer>

                                                {/* Ranks */}
                                                <StyledGridContainer container spacing={2}>
                                                    <Grid item xs={1} sx={{ marginTop: "20px" }}>
                                                        <b>Ranks</b>
                                                    </Grid>
                                                    {chunk.map((row, index) =>
                                                        <Grid item xs={1} key={index}>
                                                            <List style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <ListItem key={row.rank}>
                                                                    <ListItemText
                                                                        aria-label="Rank"
                                                                        primary={row.rank}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Grid>
                                                    )}
                                                </StyledGridContainer>
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </StyledPaper>
                    </Grid>

                    {/* Connected Projects Heading */}
                    < StyledHeadingGrid aria-label="Connected Projects Heading" item xs={12}>
                        <Typography variant="h6">
                            Connected Projects
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Connected Projects */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            {roadway[0].connectedProjects && roadway[0].connectedProjects.length > 0 ? (
                                <>
                                    <StyledGridContainer container spacing={2} sx={{ marginBottom: "25px" }}>
                                        <Grid item xs={4} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                                            Project Title
                                        </Grid>
                                        <Grid item xs={4} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                                            Project Type
                                        </Grid>
                                        <Grid item xs={4} sx={{ marginTop: "20px", fontWeight: 'bold' }}>
                                            Impact Area
                                        </Grid>
                                    </StyledGridContainer>
                                    {roadway[0].connectedProjects.map((row, index) =>
                                        <StyledGridContainer container spacing={2} key={index} sx={{ paddingBottom: "25px" }}>
                                            <Grid item xs={4}>
                                                <Typography variant="body2">
                                                    {row.projectTitle || "None"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2">
                                                    {row.projectType || "None"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2">
                                                    {row.impactArea
                                                        ? getImpactAreaForRoadSegment(row.impactArea, roadway[0].segmentId)
                                                        : "None"}
                                                </Typography>
                                            </Grid>
                                        </StyledGridContainer>
                                    )}
                                </>
                            ) : (
                                <StyledGridContainer container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">No connected projects available.</Typography>
                                    </Grid>
                                </StyledGridContainer>
                            )}
                        </StyledPaper>
                    </Grid>

                    {/* Notes Heading */}
                    <StyledHeadingGrid aria-label="Notes Heading" item xs={12}>
                        <Typography variant="h6">
                            Notes
                            <Typography style={{ fontSize: 12, fontStyle: 'italic' }}>These notes won't be published</Typography>
                        </Typography>
                    </StyledHeadingGrid>

                    {/* Display Notes */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper elevation={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    {roadway[0].notes}
                                </Typography>
                            </Grid>
                        </StyledPaper>
                    </Grid>

                    {/* Display Buttons */}
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <StyledBottomButton aria-label="Back Button" variant="contained" color="primary" onClick={() => { navigate('/view-roadways') }}>
                            Back
                        </StyledBottomButton>
                    </Grid>
                </>
            )}
        </Grid >
    );
}