export function getQtrAndFY() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    //Months are 0-indexed
    const currentMonth = currentDate.getMonth() + 1;

    let fiscalYear, quarter;

    //Calculate FY
    if (currentMonth >= 8) {
        fiscalYear = currentYear + 1;
    }
    else {
        fiscalYear = currentYear;
    }

    //Calculate quarter
    if (currentMonth >= 8 && currentMonth <= 10)
        quarter = "Q1";
    else if (currentMonth >= 11 || currentMonth === 1)
        quarter = "Q2";
    else if (currentMonth >= 2 && currentMonth <= 4)
        quarter = "Q3";
    else if (currentMonth >= 5 && currentMonth <= 7)
        quarter = "Q4";

    return {
        fiscalYear: fiscalYear,
        quarter: quarter
    }
}

// Function to get the start date of a quarter
export function getNextQtrStartDate(calendarYear, quarter) {
    switch (quarter) {
        case "Q1": return new Date(calendarYear, 10, 1);  // November 1
        case "Q2": return new Date(calendarYear, 1, 1); // February 1
        case "Q3": return new Date(calendarYear, 4, 1);  // May 1
        case "Q4": return new Date(calendarYear, 7, 1);  // August 1
        default: throw new Error('Invalid quarter');
    }
}

// Function to get the review start date (two weeks before the next quarter start date)
export function getReviewStartDate(nextQtrStartDate) {
    const reviewStartDate = new Date(nextQtrStartDate);
    reviewStartDate.setDate(nextQtrStartDate.getDate() - 14);
    return reviewStartDate;
}