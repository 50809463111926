import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import Button from "@mui/material/Button";

export default function InformationDialog({
  open,
  onClose,
  title,
  message,
}) {
  let msgList = [];
  if (message) {
    msgList = message.split('\n')
  }
  return (
    <Dialog aria-label="Information Dialog" open={open}>
      <DialogTitle aria-label="Dialog Title" id="alert-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>{title}</DialogTitle>
      <DialogContent>
        {msgList?.length === 1 && (
          <Typography>
            {msgList[0]}
          </Typography>
        )}
        {msgList?.length > 1 && (
          <ul aria-label="Message List">
            {msgList.map((line, index) => (
              <li key={index} aria-label={line}>
                {line}
                <br />
              </li>
            ))}
          </ul>
        )}
      </DialogContent>
      <DialogActions>
        <Button aria-label="Okay" onClick={onClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
