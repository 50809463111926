import { styled } from '@mui/system';
import { Box, Typography, Grid, Paper } from '@mui/material';

export const StyledBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    padding: 2
});

export const StyledTypography = styled(Typography)({
    padding: 2
});

export const StyledGrid = styled(Grid)({
    paddingTop: 4
});

export const StyledPaper = styled(Paper)({
    height: '100px'
})