import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

//Component to fit the map bounds based on the data
export const FitBounds = ({ data }) => {
    const map = useMap();

    useEffect(() => {
        if (data?.length > 0) {
            const geoJsonLayer = L.geoJson(data);
            map.fitBounds(geoJsonLayer.getBounds());
        }
    }, [data]);

    return null;
};