import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import AuthService from '../services/AuthService';
import AuthToken from '../services/AuthToken';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [auth, setAuth] = useState({ user: null, token: null });
    const { login } = AuthService();

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (token) {
            const currentDate = new Date();

            // Retrieve user details from session storage
            const parsedUser = JSON.parse(sessionStorage.getItem("user"));
            if (AuthToken(currentDate)) {
                setAuth({ token, user: parsedUser });
            } else {
                handleLogout(); // Token expired, logout
            }
        }

    }, [location.pathname]);

    const handleLogin = async (username, password) => {
        const user = await login(username, password);
        if (user) {
            const token = sessionStorage.getItem("token");
            setAuth({ token, user });
        }
    };

    const handleLogout = () => {
        sessionStorage.clear();
        setAuth({ user: null, token: null });
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ auth, login: handleLogin, logout: handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};