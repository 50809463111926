import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Alert
} from '@mui/material';
import { Formik, Form } from 'formik';
import Title from '../../../components/Typography/Title'
import RouterBreadcrumbs from '../../../components/Breadcrumbs/RouterBreadcrumbs';
import ProjectInfo from './ProjectInfo';
import ProjectPhase from './ProjectPhase';
import ProjectStatus from './ProjectStatus';
import ConnectedRoadways from './ConnectedRoadways';
import SubmitProject from '../ReviewProject/SubmitProject';
import validationSchema from './FormModel/validationSchema';
import addProjectFormModel from './FormModel/AddProjectFormModel';
import formInitialValues from './FormModel/formInitialvalues';
import { useNavigate } from "react-router-dom";
import { StyledButton, StyledCircularProgress } from '../styles';

//utils
import mapProjectsData from '../../../utils/mapProjectsData';

//constants
import { API_URLS } from '../../../Constants';

//custom hooks
import { useUpdate } from '../../../hooks/useUpdate';


import { useAuth } from '../../../context/AuthContext';


const pathItems = [
  'Dashboard',
  'Projects',
  'Add New Project'
]
const { formId, formField } = addProjectFormModel;

export default function AddNewProject() {
  const { auth } = useAuth();
  const [steps, setSteps] = useState(['Project Information', 'Project Details/Phase', 'Project Status', 'Connected Roadways', 'Submit Project']);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const navigate = useNavigate();
  const addProjectsAPI = API_URLS["API_ADD_UPDATE_PROJECT"];


  const [errors, setErrors] = useState([]);
  //custom hooks
  const postFetcher = useUpdate('post', addProjectsAPI);

  const [alert, setAlert] = React.useState({
    open: false,
    message: ""
  });

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /*async function _submitForm(values, actions) {
    await _sleep(1000);

    actions.setSubmitting(false);

    setActiveStep(activeStep + 1);
  }*/

  function addProjectsData(formObject, isSaveForLater, submit) {

    //Add project status based on conditions.
    let newProjectStatus = "draft";

    //For regional and regional transit projects
    if (formObject.projectType === "Regional" || formObject.projectType === "Regional Transit") {
      formObject.connectedRoadways = [];
    }
    if (!isSaveForLater || submit) {//when submit is true for regional or regional transit project types
      const connectedRoadways = formObject.connectedRoadways;
      newProjectStatus = submit ? "active" : "inactive";

      //if any roadway is amongst top 105 then set project status as active 
      !submit && connectedRoadways?.length > 0 && connectedRoadways.some((roadway) => {
        if (roadway.rank <= 105) {
          newProjectStatus = "active";
          return true;
        }
        return false;
      })
    }

    //Map form object into database object
    const options = {
      projectData: formObject,
      projectStatus: newProjectStatus,
      passedReviewedDate: !isSaveForLater || submit ? new Date() : null,
      passedReviewedBy: !isSaveForLater || submit ? auth?.user?.username || "" : null
    }
    const mappedProjectsData = mapProjectsData(options);




    // Update data in the database.
    if (mappedProjectsData) {
      if (mappedProjectsData.projectType !== "Regional" && mappedProjectsData.projectType !== "Regional Transit" && mappedProjectsData.connectedRoadways.length == 0) {
        setAlert({ open: true, message: "Please select the connected roadways to proceed" });
      }
      else
        postFetcher.executeUpdate(mappedProjectsData);
    }

  }

  function _handleSubmit(values, actions, isSaveForLater = false, submit = false) {
    if (isLastStep) {


      //if (!isSaveForLater)
      //_submitForm(values, actions);

      //Invoke function to update projects data in database.
      addProjectsData(values, isSaveForLater, submit);
    }
    else {

      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);

    }
  }

  function _handleBack(values, actions) {
    if (isLastStep) {
      _handleSubmit(values, actions, true, false);
    }
    else {
      setActiveStep(activeStep - 1);
    }

  }

  function _renderStepContent(activeStep, values, steps, setSteps) {

    useEffect(() => {

      const connectedRoadwaysNeeded = values.projectType !== "Regional"
        && values.projectType !== 'Regional Transit';
      const connectedRoadwaysExists = steps.includes("Connected Roadways");

      if (connectedRoadwaysNeeded !== connectedRoadwaysExists) {
        setSteps(prevSteps => {
          if (connectedRoadwaysNeeded) {
            return ['Project Information', 'Project Details/Phase', 'Project Status', 'Connected Roadways', 'Submit Project'];
          } else {
            return prevSteps.filter(item => item !== "Connected Roadways");
          }
        });

        if (connectedRoadwaysNeeded && activeStep <= 4 && activeStep >= 3) {
          setActiveStep(prevStep => prevStep + 1);
        } else if (!connectedRoadwaysNeeded && activeStep > 0) {
          setActiveStep(prevStep => prevStep - 1);
        }
      }
    }, [values.projectType, steps, activeStep]);

    switch (activeStep) {
      case 0:
        return <ProjectInfo formField={formField} type="add" />;
      case 1:
        return <ProjectPhase formField={formField} values={values} />;
      case 2:
        return <ProjectStatus formField={formField} values={values} />;
      case 3:
        return steps.includes("Connected Roadways") ? <ConnectedRoadways formField={formField} values={values} edit={false} /> :
          <SubmitProject formField={formField} values={values} render={_renderStepContent} setstep={setSteps} steps={steps} />;
      case 4:
        return <SubmitProject formField={formField} values={values} render={_renderStepContent} setstep={setSteps} steps={steps} />;
      default:
        return <div>Not Found</div>;
    }

  }

  //Navigate to view projects is response received successfully
  useEffect(() => {
    if (postFetcher?.serverResponse) {
      navigate('/view-projects');
    }
    else if (postFetcher?.serverResponse?.error)
      console.error('Error in POST request: ', postFetcher.serverResponse.error);

  }, [postFetcher.serverResponse]);



  useEffect(() => {

    if (postFetcher?.serverError && postFetcher?.serverError?.message?.validationErrors) {
      const errorSet = transformStrings(postFetcher.serverError.message.validationErrors);

      setErrors(errorSet);
    }


  }, [postFetcher.serverError]);

  function transformStrings(inputArray) {
    const transformedArray = inputArray.map(inputString => {
        if (inputString.field.includes("projectDetails")) {
            inputString.field = inputString.field.replace("projectDetails", "projectPhase");
        }
        const parts = inputString.field.split('/').map(part => part.replace(/\d+/, '-'));

        // Join with spaces before each camel case letter and capitalize the first letter
        return parts
            .map(part =>
                part.replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space before camel case letters
                    .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize each word
            )
            .join(' ');
    });

    // Ensure unique values in the final output array
    return Array.from(new Set(transformedArray));
}

  return (
    <React.Fragment>
      <Grid item xs={12} md={12}>
        <RouterBreadcrumbs pathItems={pathItems} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Title title="Add new Project" />
      </Grid>
      <Stepper activeStep={activeStep} sx={{ marginTop: "15px", marginBottom: "30px", marginRight: "10px" }}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}

      </Stepper>



      <React.Fragment>
        {(
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting, values, actions }) => {
              return (
                <Form id={formId}>

                  <Grid item xs={12}>
                    {_renderStepContent(activeStep, values, steps, setSteps)}
                  </Grid>

                  <Grid item xs={12}>

                  </Grid>

                  <Grid item sx={{ marginRight: "10px", padding: "10px" }}>
                    {alert.open &&
                      <Alert severity="error" style={{ color: "#14375A" }}><strong> {alert.message}</strong></Alert>
                    }
                    {errors?.length > 0 && (

                      <Alert severity="error" style={{ color: "#14375A" }}>
                        <strong> Please enter the following required fields:</strong>

                        <ul>
                          {errors.map((error, index) => (
                            <li key={index}>
                              <strong>{error}</strong>
                            </li>

                          ))}
                        </ul>
                      </Alert>

                    )}

                  </Grid>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>

                    {/* Save For Later / Back button */}
                    {activeStep !== 0 && (
                      <StyledButton
                        sx={{ marginLeft: isLastStep ? 0 : 10 }}
                        variant="contained" aria-label="Save For Later / Back Button" onClick={() => activeStep > 0 && _handleBack(values, actions)}>
                        {isLastStep ? 'Save For Later' : 'Back'}
                      </StyledButton>
                    )}
                    <div style={{ position: 'relative' }}>
                      {((activeStep !== 3) || (values.connectedRoadways && values.connectedRoadways.length > 0) || (values.projectType === "Regional" || values.projectType === "Regional Transit")) && (<StyledButton
                        disabled={isSubmitting}
                        type="submit"
                        onClick={() => ((values.projectType === "Regional" || values.projectType === "Regional Transit") && isLastStep) && _handleSubmit(values, actions, true, true)}
                        variant="contained"
                        color="primary"
                      >
                        {isLastStep ? 'Publish' : 'Save & Next'}
                      </StyledButton>)}
                      {/* {isSubmitting && (
                        <StyledCircularProgress
                          size={24}
                        />
                      )} */}
                    </div>
                  </div>
                </Form>
              )
            }}

          </Formik>
        )}

      </React.Fragment>
    </React.Fragment>
  );
}