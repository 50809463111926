import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import {
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
} from "@mui/x-data-grid";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Add as AddIcon,
} from "@mui/icons-material";
import { StyledStartegyTypesBtn, StyledDataGrid } from "./styles";

//compoennts
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import InputDialog from "../../components/Dialog/InputDialog";
import InformationDialog from "../../components/Dialog/InformationDialog";
import RouterBreadcrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";

//custom hook
import { useFetch } from "../../hooks/useFetch";
import { useUpdate } from "../../hooks/useUpdate";

//constants
import { API_URLS } from "../../Constants";

//context
import { useAuth } from "../../context/AuthContext";

const pathItems = [
    "Dashboard",
    "Projects",
    "Strategy Types"
]

export default function StrategyTypes() {
    const { auth } = useAuth();
    const [reloadFlag, setReloadFlag] = useState(false);
    const [strategyTypesData, setStrategyTypesData] = useState([]);

    //fetchers
    const fetcher = useFetch("get", API_URLS["API_GET_ALL_STRATEGY_TYPES"]);
    const postFetcher = useUpdate('post', API_URLS["API_ADD_UPDATE_STRATEGY_TYPE"]);
    const deleteFetcher = useUpdate('delete', API_URLS["API_DELETE_STRATEGY_TYPES"]);
    const getFetcher = useFetch("get", API_URLS["API_GET_STRATEGY_TYPE_BY_ID"]);

    //Fetch data from API when the component renders.
    useEffect(() => {
        fetcher.setRequest({});
    }, [reloadFlag]);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = fetcher.serverResponse;
        if (response?.success && response.data) {
            const strategyTypes = response.data;

            // Sort the strategy types alphabetically by name
            const sortedStrategyTypes = strategyTypes.toSorted((a, b) => a.name.localeCompare(b.name));
            setStrategyTypesData(sortedStrategyTypes);
        }
        else if (response?.error)
            console.error('Error in GET request: ', response.error);
    }, [fetcher.serverResponse]);

    //Once data from POST request is fetched, refetch the data 
    useEffect(() => {
        if (postFetcher?.serverResponse) {
            setReloadFlag(prev => !prev);
        }
        else if (postFetcher?.serverResponse?.error)
            console.error('Error in POST request: ', postFetcher.serverResponse.error);
    }, [postFetcher.serverResponse]);

    //Once data from DELETE request is fetched, refetch the data 
    useEffect(() => {
        const response = fetcher.serverResponse;
        if (response?.success && response.data) {
            setReloadFlag(prev => !prev);
        }
        else if (response?.error)
            console.error('Error in DELETE request: ', response.error);
    }, [deleteFetcher.serverResponse]);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = getFetcher.serverResponse
        if (response?.success && response?.data.length > 0) {
            const selectedStrategyType = response.data[0];
            if (selectedStrategyType) {
                setSelectedRows([selectedStrategyType]);
                setEditDialog({
                    open: true,
                    title: "Edit Dialog",
                    type: "Strategy Types",
                    confirmationBtnName: "Save",
                    inputField1: selectedStrategyType.name,
                    inputField2: selectedStrategyType.description,
                    inputField3: selectedStrategyType.parentCategory && Object.keys(selectedStrategyType.parentCategory).length > 0
                        ? selectedStrategyType.parentCategory["name"]
                        : "",
                });
            }
        }
    }, [getFetcher.serverResponse]);

    //React Hooks
    const [selectedRows, setSelectedRows] = useState([]);
    const [confirmationDialog, setConfirmationDialog] = useState({
        open: false,
        title: "",
        message: "",
        onConfirm: () => { },
    });
    const [editDialog, setEditDialog] = useState({
        open: false,
        onClose: () => { },
        title: "",
        type: "",
        confirmationBtnName: "",
        onConfirm: () => { },
        inputField1: "",
        inputField2: "",
        inputField3: "",
    });
    const [addDialog, setAddDialog] = useState({
        open: false,
        onClose: () => { },
        title: "",
        type: "",
        confirmationBtnName: "",
        onConfirm: () => { },
        inputField1: "",
        inputField2: "",
        inputField3: "",
    });
    const [informationDialog, setInformationDialog] = useState({
        open: false,
        title: "",
        message: "",
        onConfirm: () => { },
    });

    //Get loggedIn user details
    const loggedInUsername = auth?.user?.username || null;
    if (!loggedInUsername) return;

    //Add columns.
    const columns = [
        {
            field: "id",
            filterable: false
        },
        {
            field: "name",
            headerName: "Strategy Name",
            width: 300,
        },
        {
            field: "description",
            headerName: "Description",
            width: 250,
        },
        {
            field: "parentCategory",
            headerName: "Parent Category",
            width: 270,
            valueGetter: (params) => {
                return params.row.parentCategory?.name || "";
            },
            renderCell: (params) => (
                <Typography aria-label="Parent Category" variant="body2" key={params.id}>
                    {params.value || ""}
                </Typography>
            )
        },
        {
            field: "numOfProjects",
            headerName: "Number of Projects",
            width: 200,
            valueGetter: (params) => parseInt(params.row.numOfProjects) || 0
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 80,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        aria-label="Edit Action"
                        icon={<IconsWithTooltip title="Update strategy type" icon={<EditIcon />} />}
                        label="Edit"
                        onClick={() => handleEditSelected(params.row.id)}
                    />,
                    <GridActionsCellItem
                        aria-label="Delete Action"
                        icon={<IconsWithTooltip title="Delete strategy type" icon={<DeleteIcon />} />}
                        label="Delete"
                        onClick={() => handleDeleteSelected([params.row.id])}
                    />,
                ];
            },
        },
    ];

    //Custom toolbar to return number of records alongwith filter and export options.
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div style={{ marginRight: "15px" }}>Total Records: {strategyTypesData.length}</div>
                <GridToolbarFilterButton />
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            </GridToolbarContainer>
        );
    }

    //Checks if startegy name already exists or not. Returns true if it exists else returns false.
    const checkIfStrategyExists = (strategyName) => {

        //Check if strategy name is empty or not.
        if (strategyName) {
            const findRow =
                strategyTypesData != null && strategyTypesData.length > 0
                    ? strategyTypesData.find((row) => row.name.toLowerCase() === strategyName.toLowerCase())
                    : null;
            if (findRow != null)
                return true;
        }
        return false;
    };

    //Invoked when user clicks "Add" button in Add Dialog.
    const handleAddConfirm = (
        strategyName,
        description,
        parentCategory,
        callback = () => { }
    ) => {

        //Check if strategy name already exists or not in the table.
        const strategyNameFound = checkIfStrategyExists(strategyName);
        if (strategyName && !strategyNameFound) {

            const addParentCategory = parentCategory !== "None" ? strategyTypesData.find(type => type.name === parentCategory) : "";

            //Create a new row with the data.
            const newRow = {
                name: strategyName,
                description: description,
                parentCategory: addParentCategory ? addParentCategory.id : null,
                numOfProjects: 0,
                lastModifiedBy: loggedInUsername,
                lastModifiedDate: new Date()
            };

            // Update data in the database.
            postFetcher.executeUpdate(newRow);

            //Close AddDialog.
            setAddDialog((prevState) => ({ ...prevState, open: false }));
            callback();
        }
        else if (strategyName) //Check if user input name is empty or not.
        {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Strategy name already exists. Choose a different name.`,
            });
        }
        else {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Strategy Name cannot be empty.`,
            });
        }
    };

    //Strategy Type Addition: Invoked to show Add dialog when user clicks "Add New Strategy Type" button.
    const handleAdd = () => {
        setAddDialog({
            open: true,
            onClose: () => { },
            title: "Add Dialog",
            type: "Strategy Types",
            confirmationBtnName: "Add",
            onConfirm: () => { },
            inputField1: "",
            inputField2: "",
            inputField3: "",
        });
    };

    //Invoked when user clicks "Edit" button in Edit Dialog.
    const handleEditConfirm = (
        editedName,
        editedDescription,
        editedParentCategory,
        callback = () => { }
    ) => {
        const originalStartegyName = strategyTypesData.find(
            (row) => row.id === selectedRows[0].id
        ).name;

        //Check if strategy name already exists or not in the table.
        const strategyNameFound =
            originalStartegyName !== editedName
                ? checkIfStrategyExists(editedName)
                : false;

        if (editedName && !strategyNameFound) {

            const existingNumOfProjects = strategyTypesData.find(
                (row) => row.id === selectedRows[0].id
            ).numOfProjects;

            const updatedParentCat = editedParentCategory !== "None" ? strategyTypesData.find(type => type.name === editedParentCategory) : "";

            //Create a new row with the data.
            const updatedRow = {
                id: selectedRows[0].id,
                name: editedName,
                description: editedDescription,
                parentCategory: updatedParentCat ? updatedParentCat.id : null,
                numOfProjects: existingNumOfProjects,
                lastModifiedBy: loggedInUsername,
                lastModifiedDate: new Date()
            };

            // Update data in the database.
            postFetcher.executeUpdate(updatedRow);

            //Close EditDialog.
            setEditDialog((prevState) => ({ ...prevState, open: false }));
            callback();
        }
        else if (editedName) //Check if user input name is empty or not.
        {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Strategy name already exists. Choose a different name.`,
            });
        }
        else {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Strategy Name cannot be empty.`,
            });
        }
    };

    //Strategy Type Edit: Invoked to show Edit Dialog when user clicks edit icon for the selected row.
    const handleEditSelected = (selectedRowId) => {
        const url = `${API_URLS["API_GET_STRATEGY_TYPE_BY_ID"]}?id=${selectedRowId}`;
        getFetcher.setRequest({ passedUrl: url });
    };

    //Strategy Type Deletion: Set action on clicking "Yes" for selected strategy type(s).
    const handleDeleteConfirm = () => {
        setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
    };

    //Strategy Type Deletion: Set ConfirmationDialog properties for selected strategy type(s).
    const handleDeleteSelected = (selectedIds) => {
        if (selectedIds && selectedIds.length > 0) {
            setConfirmationDialog({
                open: true,
                title: "Delete Confirmation",
                message: `Are you sure you want to delete the selected strategy type(s) ?`,
                onConfirm: () => {

                    // Update data in the database.
                    deleteFetcher.executeUpdate({ "ids": selectedIds });
                    setSelectedRows([]);
                    handleDeleteConfirm();
                },
            });
        }
    };

    return (
        <>
            {/* Breadcrumbs for the page */}
            <Grid item xs={12} md={12}>
                <RouterBreadcrumbs pathItems={pathItems} />
            </Grid>

            {/* Page Title for the page */}
            <Grid item xs={12} md={12}>
                <Title title="View Strategy Types" />
            </Grid>

            <Grid item xs={12}>

                {/* Add New Strategy Type Button */}
                <StyledStartegyTypesBtn
                    aria-label="Add New Button"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleAdd}
                >
                    Add New
                </StyledStartegyTypesBtn>

                {/* Render Delete All button */}
                {selectedRows.length > 1 && (
                    <StyledStartegyTypesBtn
                        aria-label="Delete All Button"
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteSelected(selectedRows)}
                    >
                        Delete All
                    </StyledStartegyTypesBtn>
                )}
            </Grid>

            {/* Data Table */}
            <Grid container sx={{ paddingRight: 3 }}>
                <StyledDataGrid
                    aria-label="Strategy Types Data Table"
                    rows={strategyTypesData}
                    columns={columns}
                    loading={fetcher.isLoading}
                    autoHeight
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    initialState={{
                        columns: {
                            // Hide column id from data grid view.
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 15, 20, 25]}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    selectedRows={selectedRows}
                />
            </Grid>

            {/* ConfirmationDialog component */}
            <ConfirmationDialog
                open={confirmationDialog.open}
                onClose={() =>
                    setConfirmationDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={confirmationDialog.title}
                message={confirmationDialog.message}
                onConfirm={confirmationDialog.onConfirm}
            />

            {/* Edit Dialog component */}
            <InputDialog
                open={editDialog.open}
                onClose={() =>
                    setEditDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={editDialog.title}
                type={editDialog.type}
                confirmationBtnName={editDialog.confirmationBtnName}
                onConfirm={handleEditConfirm}
                inputField1={editDialog.inputField1 || ""}
                inputField2={editDialog.inputField2 || ""}
                inputField3={editDialog.inputField3 || ""}
                strategyTypesDrpDwnVal={strategyTypesData}
            />

            {/* Add Dialog component */}
            <InputDialog
                open={addDialog.open}
                onClose={() =>
                    setAddDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={addDialog.title}
                type={addDialog.type}
                confirmationBtnName={addDialog.confirmationBtnName}
                onConfirm={handleAddConfirm}
                inputField1={addDialog.inputField1 || ""}
                inputField2={addDialog.inputField2 || ""}
                inputField3={addDialog.inputField3 || ""}
                strategyTypesDrpDwnVal={strategyTypesData}
            />

            {/* Information Dialog component */}
            <InformationDialog
                open={informationDialog.open}
                onClose={() =>
                    setInformationDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={informationDialog.title}
                message={informationDialog.message}
                onConfirm={informationDialog.onConfirm}
            />
        </>
    );
}
