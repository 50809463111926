import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ActiveLastBreadcrumb({ pathItems }) {
  const navigate = useNavigate();
  const handleClick = (value) => {
    let path = "/";
    if (value === "projects")
      path = "/view-projects";
    else if (value === "roadways")
      path = "/view-roadways";
    else if (value === "dashboard")
      path = "/dashboard"
    navigate(path);
  }
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {pathItems.map((value, index) => {
        const isLast = index === pathItems.length - 1;
        return isLast ? (
          <Typography color="textPrimary" key={index}>
            {value}
          </Typography>
        ) : (
          <Link color="inherit" key={index} onClick={() => { handleClick(value.toLowerCase()) }} style={{ cursor: 'pointer' }}>
            {value}
          </Link>
        );
      })}

    </Breadcrumbs>
  );
}
