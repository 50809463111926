import { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { StyledButton, StyledDataGrid } from "./styles";
import {
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
} from "@mui/x-data-grid";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Add as AddIcon,
} from "@mui/icons-material";

//Components
import RouterBreadrumbs from "../../components/Breadcrumbs/RouterBreadcrumbs";
import Title from "../../components/Typography/Title";
import InputDialog from "../../components/Dialog/InputDialog";
import InformationDialog from "../../components/Dialog/InformationDialog";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";

//custom hook
import { useFetch } from "../../hooks/useFetch";
import { useUpdate } from "../../hooks/useUpdate";

//constants
import { API_URLS } from "../../Constants";

//context
import { useAuth } from "../../context/AuthContext";

const pathItems = ["Dashboard", "Regions"];

export default function Regions() {
    const { auth } = useAuth();
    const [reloadFlag, setReloadFlag] = useState(false);
    const [regionsData, setRegionsData] = useState([]);

    //fetchers
    const fetcher = useFetch("get", API_URLS["API_GET_ALL_REGIONS"]);
    const postFetcher = useUpdate('post', API_URLS["API_ADD_UPDATE_REGION"]);
    const deleteFetcher = useUpdate('delete', API_URLS["API_DELETE_REGIONS"]);
    const getFetcher = useFetch("get", API_URLS["API_GET_REGION_BY_ID"]);

    //Fetch data from API when the component renders.
    useEffect(() => {
        fetcher.setRequest({});
    }, [reloadFlag]);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = fetcher.serverResponse;
        if (response) {

            //Remove all from regions
            const updatedResponse = response.filter(region => region.id !== "0");

            // Sort the regions alphabetically by name
            const sortedRegions = updatedResponse.toSorted((a, b) => a.name.localeCompare(b.name));
            setRegionsData(sortedRegions || []);
        }
    }, [fetcher.serverResponse]);

    //Once response is received, set appropriate state variables.
    useEffect(() => {
        const response = getFetcher.serverResponse
        if (response?.length > 0) {
            const selectedRegion = response[0];
            if (selectedRegion) {
                const sisterRegions = selectedRegion.sisterRegion.length > 1
                    ? selectedRegion.sisterRegion.filter(reg => reg.id !== selectedRegion.id).map(reg => reg.name)
                    : null;
                setSelectedRows([selectedRegion]);
                setEditDialog({
                    open: true,
                    title: "Edit Dialog",
                    type: "Region",
                    confirmationBtnName: "Save",
                    inputField1: selectedRegion.name,
                    inputField2: selectedRegion.description,
                    inputField3: sisterRegions
                });
            }
        }
    }, [getFetcher.serverResponse]);

    //Once data from POST request is fetched, refetch the data 
    useEffect(() => {
        const response = postFetcher.serverResponse
        if (response?.success) {
            setReloadFlag(prev => !prev);
        }
        else if (response?.error)
            console.error('Error in POST request: ', response.error);
    }, [postFetcher.serverResponse]);

    //Once data from DELETE request is fetched, refetch the data 
    useEffect(() => {
        const response = deleteFetcher.serverResponse;
        if (response?.success && response.data) {
            setReloadFlag(prev => !prev);
        }
        else if (response?.error)
            console.error('Error in DELETE request: ', response.error);
    }, [deleteFetcher.serverResponse]);

    //React Hooks
    const [selectedRows, setSelectedRows] = useState([]);
    const [confirmationDialog, setConfirmationDialog] = useState({
        open: false,
        title: "",
        message: "",
        onConfirm: () => { },
    });
    const [editDialog, setEditDialog] = useState({ open: false });
    const [addDialog, setAddDialog] = useState({ open: false });
    const [informationDialog, setInformationDialog] = useState({ open: false });

    //Get loggedIn user details
    const loggedInUsername = auth?.user?.username || null;
    if (!loggedInUsername) return;

    //Data Grid columns
    const columns = [
        { field: 'name', headerName: 'Region Name', width: 220, filterable: true },
        { field: 'description', headerName: 'Description', width: 260, filterable: true },
        {
            field: 'sisterRegion',
            headerName: 'Sister Region',
            width: 150,
            filterable: true,
            valueGetter: (params) => {
                const regionId = params.row.id;
                const sisterRegionLst = params.row.sisterRegion?.filter(region => region.id !== regionId) || [];
                return sisterRegionLst.map(region => region.name).join(", ");
            },
            renderCell: (params) => {
                return (
                    <Typography aria-label="Sister Region" variant="body2" key={params.id}>
                        {params.value || ""}
                    </Typography>
                );
            }
        },
        { field: 'numOfRoadways', headerName: 'Number of Roadways', width: 190, valueGetter: (params) => parseInt(params.row.numOfRoadways) || 0 },
        { field: 'numOfProjects', headerName: 'Number of Projects', width: 190, valueGetter: (params) => parseInt(params.row.numOfProjects) || 0 },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 80,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        aria-label="Edit Action"
                        icon={<IconsWithTooltip title="Update region" icon={<EditIcon />} />}
                        label="Edit"
                        onClick={() => handleEditSelected(params.row.id)}
                    />,
                    <GridActionsCellItem
                        aria-label="Delete Action"
                        icon={<IconsWithTooltip title="Delete region" icon={<DeleteIcon />} />}
                        label="Delete"
                        onClick={() => handleDeleteSelected([params.row.id])}
                    />,
                ];
            },
        },
    ];

    //Custom toolbar to return number of records alongwith filter and export options.
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div style={{ marginRight: "15px" }}>Total Records: {regionsData.length}</div>
                <GridToolbarFilterButton />
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            </GridToolbarContainer>
        );
    }
    //Checks if region name already exists or not. Returns true if it exists else returns false.
    const checkIfRegionNameExists = (regionName) => {

        //Check if region name is empty or not.
        if (regionName) {
            const findRow =
                regionsData != null && regionsData.length > 0
                    ? regionsData.find((row) => row.name.toLowerCase() === regionName.toLowerCase())
                    : null;
            if (findRow != null)
                return true;
        }
        return false;
    };

    //Invoked when user clicks "Add" button in Add Dialog.
    const handleAddConfirm = (
        regionName,
        description,
        sisterRegion,
        callback = () => { }
    ) => {

        //Check if region name already exists or not in the table.
        const regionNameFound = checkIfRegionNameExists(regionName);
        if (regionName && !regionNameFound) {
            let accessibles = regionName;
            if (sisterRegion)
                accessibles = accessibles + "," + sisterRegion;

            //Create a new row with the data.
            const newRow = {
                name: regionName,
                description: description,
                accessibles: accessibles,
                numOfProjects: 0,
                numOfRoadways: 0,
                lastModifiedBy: loggedInUsername,
                lastModifiedDate: new Date()
            };

            // Update data in the database.
            postFetcher.executeUpdate(newRow);

            //Close AddDialog.
            setAddDialog((prevState) => ({ ...prevState, open: false }));
            callback();
        }
        else if (regionName) //Check if user input name is empty or not.
        {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Region name already exists. Choose a different name.`,
            });
        }
        else {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Region Name cannot be empty.`,
            });
        }
    };

    //Region Addition: Invoked to show Add dialog when user clicks "Add New Region" button.
    const handleAdd = () => {
        setAddDialog({
            open: true,
            onClose: () => { },
            title: "Add Dialog",
            type: "Region",
            confirmationBtnName: "Add",
            onConfirm: () => { },
            inputField1: "",
            inputField2: "",
            inputField3: ""
        });
    };

    //Invoked when user clicks "Edit" button in Edit Dialog.
    const handleEditConfirm = (
        editedRegionName,
        editedDescription,
        editedSisterRegion,
        callback = () => { }
    ) => {
        const originalRegionName = regionsData.find(
            (row) => row.id === selectedRows[0].id
        ).name;

        //Check if region name already exists or not in the table.
        const regionNameFound =
            originalRegionName !== editedRegionName
                ? checkIfRegionNameExists(editedRegionName)
                : false;

        if (editedRegionName && !regionNameFound) {
            let updatedAccessibles = selectedRows[0].name;
            if (editedSisterRegion !== "None")
                updatedAccessibles = updatedAccessibles + "," + editedSisterRegion;
            const existingNumOfProjects = regionsData.find(
                (row) => row.id === selectedRows[0].id
            ).numOfProjects;
            const existingNumOfRoadways = regionsData.find(
                (row) => row.id === selectedRows[0].id
            ).numOfRoadways;

            //Create a new row with the data.
            const updatedRow = {
                id: selectedRows[0].id,
                name: editedRegionName,
                description: editedDescription,
                accessibles: updatedAccessibles,
                numOfRoadways: existingNumOfRoadways,
                numOfProjects: existingNumOfProjects,
                lastModifiedBy: loggedInUsername,
                lastModifiedDate: new Date()
            };

            // Update data in the database.
            postFetcher.executeUpdate(updatedRow);

            //Close EditDialog.
            setEditDialog((prevState) => ({ ...prevState, open: false }));
            callback();
        }
        else if (editedRegionName) //Check if user input name is empty or not.
        {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Region name already exists. Choose a different name.`,
            });
        }
        else {
            setInformationDialog({
                open: true,
                title: "Information Dialog",
                message: `Region Name cannot be empty.`,
            });
        }
    };

    //Region Edit: Invoked to show Edit Dialog when user clicks edit icon for the selected row.
    const handleEditSelected = (selectedRowId) => {
        const url = `${API_URLS["API_GET_REGION_BY_ID"]}?id=${selectedRowId}`;
        getFetcher.setRequest({ passedUrl: url });
    };

    //Region Deletion: Set action on clicking "Yes" for selected region(s).
    const handleDeleteConfirm = () => {
        setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
    };

    //Region Deletion: Set ConfirmationDialog properties for selected region(s).
    const handleDeleteSelected = (selectedIds) => {
        if (selectedIds && selectedIds.length > 0) {
            setConfirmationDialog({
                open: true,
                title: "Delete Confirmation",
                message: `Are you sure you want to delete the selected region(s) ?`,
                onConfirm: () => {

                    // Update data in the database.
                    deleteFetcher.executeUpdate({ "ids": selectedIds });
                    setSelectedRows([]);
                    handleDeleteConfirm();
                },
            });
        }
    };

    return (
        <>
            {/* Breadcrumbs */}
            <Grid item xs={12}>
                <RouterBreadrumbs pathItems={pathItems} />
            </Grid>

            {/* Page Title */}
            <Grid item xs={12}>
                <Title title={"Regions"} />
            </Grid>

            {/* Add New Region Button */}
            <Grid item xs={12}>
                <StyledButton
                    startIcon={<AddIcon />}
                    variant="outlined"
                    onClick={handleAdd}
                >
                    Add New Region
                </StyledButton>

                {/* Render Delete All button */}
                {selectedRows.length > 1 && (
                    <StyledButton
                        aria-label="Delete All Button"
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteSelected(selectedRows)}
                    >
                        Delete All
                    </StyledButton>
                )}
            </Grid>

            {/* Display Data Table */}
            <Grid container sx={{ paddingRight: 3 }}>
                <StyledDataGrid
                    aria-label="Regions Data Table"
                    rows={regionsData}
                    columns={columns}
                    loading={fetcher.isLoading}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 15, 20, 25]}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    selectedRows={selectedRows}
                />
            </Grid>

            {/* ConfirmationDialog component */}
            <ConfirmationDialog
                open={confirmationDialog.open}
                onClose={() =>
                    setConfirmationDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={confirmationDialog.title}
                message={confirmationDialog.message}
                onConfirm={confirmationDialog.onConfirm}
            />

            {/* Add New Region Dialog */}
            <InputDialog
                open={addDialog.open}
                onClose={() =>
                    setAddDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={addDialog.title}
                type={addDialog.type}
                confirmationBtnName={addDialog.confirmationBtnName}
                onConfirm={handleAddConfirm}
                inputField1={addDialog.regionName || ""}
                inputField2={addDialog.description || ""}
                inputField3={addDialog.sisterRegion || ""}
                regionsDrpDwnVal={regionsData}
            />

            {/* Edit Dialog component */}
            <InputDialog
                open={editDialog.open}
                onClose={() =>
                    setEditDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={editDialog.title}
                type={editDialog.type}
                confirmationBtnName={editDialog.confirmationBtnName}
                onConfirm={handleEditConfirm}
                inputField1={editDialog.inputField1 || ""}
                inputField2={editDialog.inputField2 || ""}
                inputField3={editDialog.inputField3 || ""}
                regionsDrpDwnVal={regionsData}
            />

            {/* Information Dialog component */}
            <InformationDialog
                open={informationDialog.open}
                onClose={() =>
                    setInformationDialog((prevState) => ({ ...prevState, open: false }))
                }
                title={informationDialog.title}
                message={informationDialog.message}
                onConfirm={informationDialog.onConfirm}
            />
        </>
    );
}