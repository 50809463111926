export default {
  formId: 'addprojectform',
  formField: {
    projectTitle: {
      name: 'projectTitle',
      label: 'Project Title*',
      requiredErrorMsg: 'Project Title is required'
    },
    planningDocument: {
      name: 'planningDocument',
      label: 'Planning Document*',
      requiredErrorMsg: 'Planning Document is required'
    },
    isProjectStudy: {
      name: 'isProjectStudy',
      label: 'Is this project a study?*',
      requiredErrorMsg: 'Required field'
    },
    regions: {
      name: 'regions',
      label: 'Regions*',
      requiredErrorMsg: 'Select atleast one region'
    },
    impactedRegions: {
      name: 'impactedRegions',
      label: 'Impacted Region(s)',
    },
    strategyTypes: {
      name: 'strategyTypes',
      label: 'Strategy Types*',
      requiredErrorMsg: 'Select atleast one strategy type'
    },
    projectType: {
      name: 'projectType',
      label: 'Project Type*',
      requiredErrorMsg: 'Select the Project Type.'
    },
    projectTo: {
      name: 'projectTo',
      label: 'Project To',

    },
    projectFrom: {
      name: 'projectFrom',
      label: 'Project From',
    },
    website: {
      name: 'website',
      label: 'Project Website'
    },
    description: {
      name: 'description',
      label: 'Description*',
      requiredErrorMsg: 'Please enter the description'
    },
    projectDetails: {
      phaseNumber: {
        name: 'phase',
        label: 'Phase No.'
      },
      csjNumber: {
        name: 'csjNumber',
        label: 'CSJ Number or Plan ID Number',
        requiredErrorMsg: 'Please enter the CSJ Number'

      },
      letDate:
      {
        name: 'letDate',
        label: 'Let Date',
        requiredErrorMsg: 'Please enter the Let Date'
      },
      completionDate:
      {
        name: 'completionDate',
        label: 'Completion Date',
        requiredErrorMsg: 'Please enter the Completion Date'
      },
      costEstimate:
      {
        name: 'costEstimate',
        label: 'Cost Estimate/Annual Cost (Enter Dollar value as numerals only)*',
        requiredErrorMsg: 'Please enter the Cost Estimate'
      },
      studyCost:
      {
        name: 'studyCost',
        label: 'Study Cost',
        requiredErrorMsg: 'Please enter the Study Cost'
      },
    },

    feasibilityStudy: {
      name: 'feasibilityStudy',
      label: 'Feasibility Study*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    preliminaryDesign: {
      name: 'preliminaryDesign',
      label: 'Preliminary Design*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    nepaReview: {
      name: 'nepaReview',
      label: 'NEPA Review*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    finalDesign: {
      name: 'finalDesign',
      label: 'Final Design*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    funding: {
      name: 'funding',
      label: 'Funding*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    rowAcquisition: {
      name: 'rowAcquisition',
      label: 'ROW Acquisition*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    construction: {
      name: 'construction',
      label: 'Construction*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },

    //if project is a study
    scopeIdentified: {
      name: 'scopeIdentified',
      label: 'Scope Identified*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    fundingAcquired: {
      name: 'fundingAcquired',
      label: 'Funding Acquired*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    researcherIdentified: {
      name: 'researcherIdentified',
      label: 'Researcher Identified*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    studyInProgress: {
      name: 'studyInProgress',
      label: 'Study In Progress*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },
    studyComplete: {
      name: 'studyComplete',
      label: 'Study Complete*',
      requiredErrorMsg: 'Please select the status of this project phase'
    },


    connectedRoadways: {
      roadName: {
        name: 'roadName',
        label: 'Roadway Name',
        requiredErrorMsg: 'Please enter the Roadway Name'
      },
      segmentId: {
        name: 'segmentId',
      },
      fromRoad: {
        name: 'fromRoad',
      },
      toRoad: {
        name: 'toRoad',
      },
      rank: {
        name: 'rank',
        label: 'Rank'
      },
      impactArea: {
        name: 'impactArea',
        label: "Impact Area"
      },
    },
    notes: {
      name: 'notes',
      label: "Notes : These notes won't be published"
    },
    status: {
      name: 'status',
    },
    reviewedDate: {
      name: 'reviewedDate',
    },
    reviewedBy: {
      name: 'reviewedBy',
    },
    createdDate: {
      name: 'createdDate',
    },
    lastModifiedDate: {
      name: 'lastModifiedDate',
    },
    lastModifiedBy: {
      name: 'lastModifiedBy',
    },

  }
};
