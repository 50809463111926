import { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Paper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Alert
} from "@mui/material";

//components
import Title from "../../components/Typography/Title";
import RoadwayTitle from "../../components/Typography/RoadwayTitle";
import SubHeader from "../../components/Header/SubHeader";

//custom hook
import { useFetch } from "../../hooks/useFetch";

//constants
import { API_URLS } from "../../Constants";

import { StyledGridContainer, StyledGrid, StyledLabel, StyledText, StyledTop100Grid } from "./styles";

export default function Top100RoadwaysPage() {
  const [top100RoadwaysData, setTop100RoadwaysData] = useState([]);
  const [selectedRoadwaysData, setSelectedRoadwaysData] = useState([]);
  const [allDistricts, setAllDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const fetcher = useFetch("get", API_URLS["GET_TOP_100_ROADWAYS_DETAILS"]);

  useEffect(() => {
    setLoading(true);
    fetcher.setRequest({});
  }, []);

  //Once response is received, set appropriate state variables.
  useEffect(() => {
    const response = fetcher.serverResponse;
    if (response && Object.keys(response)?.length > 0) {

      //sort by distict in alphabetical order
      const allDistricts = response?.allDistricts?.sort((a, b) => a.localeCompare(b)) || [];
      setAllDistricts(allDistricts);
      setSelectedDistrict("all");

      //sort by rank in ascending order
      const top100Roadways = response?.top100Roadways?.sort((a, b) => a.rank - b.rank) || [];
      setTop100RoadwaysData(top100Roadways);
      setSelectedRoadwaysData(top100Roadways);
      setLoading(false);
    } else if (response?.error) {
      console.error("Error in GET roadways request", response.error);
    }
  }, [fetcher.serverResponse]);

  //when district drop down changes
  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);

    if (event.target.value.toLowerCase() === "all")
      setSelectedRoadwaysData(top100RoadwaysData);
    else {
      const filteredRoadsByDistrict = top100RoadwaysData?.filter(
        (road) => road.region === event.target.value
      );
      setSelectedRoadwaysData(filteredRoadsByDistrict);
    }
  };

  return (
    <>
      <SubHeader displayBtns={["home", "login"]} />
      <StyledGridContainer container>

        {/* Heading */}
        <StyledGrid
          aria-label="Mitigation Plans for the Top 100"
          item
          xs={12}
          sx={{ textAlign: "left" }}
        >
          <Title title="Mitigation Plans for the Top 100" />
        </StyledGrid>

        {loading ? (
          <StyledGrid item xs={12} sx={{ textAlign: "center" }}>
            <CircularProgress />
          </StyledGrid>
        ) : (
          <>

            {/* Dropdown menu for selecting district */}
            <StyledGrid item xs={12} sx={{ textAlign: "left", marginBottom: 2 }}>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="district-select-label">
                  Filter by district
                </InputLabel>
                <Select
                  labelId="district-select-label"
                  id="district-select"
                  value={selectedDistrict}
                  label="Filter by District"
                  onChange={handleDistrictChange}
                >
                  <MenuItem key="all" value="all">
                    All
                  </MenuItem>
                  {allDistricts.map((district) => (
                    <MenuItem key={district} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledGrid>
            <StyledGrid container>
              {selectedRoadwaysData?.length > 0 ? (
                selectedRoadwaysData.map((road) => (
                  <StyledGrid item xs={12} key={road.segmentId}>
                    <Paper elevation={1}>
                      <Grid
                        container
                        sx={{ marginBottom: "15px", paddingTop: "20px", paddingBottom: "25px" }}                      >
                        <Grid item xs={12}>
                          <RoadwayTitle road={road} />
                        </Grid>
                        <StyledTop100Grid item xs={3}>
                          <StyledLabel aria-label="Region">Region:</StyledLabel>
                          <StyledText aria-label={road.region}>{road.region}</StyledText>
                        </StyledTop100Grid>
                        <StyledTop100Grid item xs={6}>
                          <StyledLabel aria-label="Annual Hours of Delay Per Mile">Annual Hours of Delay Per Mile:</StyledLabel>
                          <StyledText aria-label={road?.delayMile?.toLocaleString() || ""}>{road?.delayMile?.toLocaleString() || ""}</StyledText>
                        </StyledTop100Grid>
                        <StyledTop100Grid item xs={3}>
                          <StyledLabel aria-label="Previous Rank">Previous Rank:</StyledLabel>
                          <StyledText aria-label={road.previousRank}>{road.previousRank}</StyledText>
                        </StyledTop100Grid>
                        <StyledTop100Grid item xs={3}>
                          <StyledLabel aria-label="County">County:</StyledLabel>
                          <StyledText aria-label={road.county}>{road.county}</StyledText>
                        </StyledTop100Grid>
                        <StyledTop100Grid item xs={6}>
                          <StyledLabel aria-label="Annual Congestion Cost">Annual Congestion Cost:</StyledLabel>
                          <StyledText aria-label={`$${road?.congestionCost?.toLocaleString() || ""}`}>{`$${road?.congestionCost?.toLocaleString() || ""}`}</StyledText>
                        </StyledTop100Grid>
                      </Grid>
                    </Paper>
                  </StyledGrid>
                ))
              ) : (
                <StyledGrid item xs={12}>
                  <Alert severity="error">
                    No roadways found. Please select another district.
                  </Alert>
                </StyledGrid>
              )}
            </StyledGrid>
          </>
        )}
      </StyledGridContainer>
    </>
  );
}
