import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from "@mui/material";
import Button from "@mui/material/Button";

export default function UserAccessDialog({ open, onClose, title, userAccessData }) {
    return (
        <Dialog aria-label="User Access Dialog" open={open} fullWidth maxWidth="md">
            <DialogTitle aria-label="Dialog Title" id="alert-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>
                {title}
            </DialogTitle>
            <DialogContent>
                {userAccessData && userAccessData.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table aria-label="user-access-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            View
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Add
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Update
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Delete
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {["Projects", "Strategy Types", "Roadways", "Regions", "Users"].map(
                                    (rowHeader, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                    {rowHeader}
                                                </Typography>
                                            </TableCell>
                                            {userAccessData[rowIndex].map((cell, colIndex) => (
                                                <TableCell key={colIndex}>{cell}</TableCell>
                                            ))}
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="body1" color="textSecondary" align="center">
                        No data
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button aria-label="Okay" onClick={onClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}