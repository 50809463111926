import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";

//icons
import { Launch as ExternalLinkIcon } from "@mui/icons-material";

//constants
import { NAV_BUTTON_GROUP, EXTERNAL_LINKS } from "../../Constants";

//styles
import themes from "../../themes/defaultTheme";
import { StyledButton } from "./styles";

//Renders the header component.
export default function SubHeader({ displayBtns }) {
  const navigate = useNavigate();
  return (
    <>
      {displayBtns?.length > 0 && (
        <AppBar position="fixed" sx={{ backgroundColor: themes.default.palette.primary.light, zIndex: 1300, top: '64px' }}>
          <Toolbar variant="dense">
            {displayBtns.map((btnId, index) => {
              const btnName = NAV_BUTTON_GROUP?.length > 0 ? NAV_BUTTON_GROUP.find(obj => Object.keys(obj)[0] === btnId)[btnId] : '';
              return (
                <div key={btnId} style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledButton
                    aria-label={btnName}
                    label={btnName}
                    variant="text"
                    size="small"
                    endIcon={btnId === "tx100OfficialSite" ? <ExternalLinkIcon fontSize="small" /> : null}
                    onClick={() => {
                      if (btnId === "home") {
                        navigate("/");
                      } else if (btnId === "login") {
                        navigate("/login");
                      } else if (btnId === "mitigationPlans") {
                        navigate("/top100Roadways");
                      } else if (btnId === "tx100OfficialSite") {
                        window.open(EXTERNAL_LINKS[btnId], "_blank");
                      }
                    }}
                  >
                    {btnName}
                  </StyledButton>

                  {/* Add vertical bar only if it's not the last button */}
                  {index < displayBtns.length - 1 && (
                    <div style={{
                      width: '1px',
                      height: '24px',
                      backgroundColor: themes.default.palette.primary.main,
                      margin: '0 8px'
                    }} />
                  )}
                </div>
              );
            })}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}