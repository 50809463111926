import React, { useEffect } from 'react';

import addProjectFormModel from './FormModel/AddProjectFormModel';

import ProjectInfo from './ProjectInfo';
import ProjectPhase from './ProjectPhase';
import ProjectStatus from './ProjectStatus';
import ConnectedRoadways from './ConnectedRoadways';
import SubmitProject from '../ReviewProject/SubmitProject';

const { formField } = addProjectFormModel;

export default function RenderComponent({ activeStep, values, steps, setSteps }) {

  useEffect(() => {

    if (steps.includes("Connected Roadways") && (values.projectType === "Regional" || values.projectType === "Regional Transit"))
      setSteps(prev => prev.filter(item => item !== "Connected Roadways"));
    else if ((!steps.includes("Connected Roadways")) && values.projectType !== "Regional" && values.projectType !== "Regional Transit")
      setSteps(['Project Information', 'Project Details/Phase', 'Project Status', 'Connected Roadways', 'Submit Project']);

  }, [values.projectType]);

  if (values.projectType !== "Regional" && values.projectType !== "Regional Transit") {
    switch (activeStep) {
      case 0:
        return <ProjectInfo formField={formField} />;
      case 1:
        return <ProjectPhase formField={formField} values={values} />;
      case 2:
        return <ProjectStatus formField={formField} values={values} />;
      case 3:
        return <ConnectedRoadways formField={formField} values={values} edit={true} />;
      case 4:
        return <SubmitProject formField={formField} values={values} render={RenderComponent} />;
      default:
        return <div>Not Found</div>;
    }
  }
  else {
    switch (activeStep) {
      case 0:
        return <ProjectInfo formField={formField} />;
      case 1:
        return <ProjectPhase formField={formField} values={values} />;
      case 2:
        return <ProjectStatus formField={formField} values={values} />;
      case 3:
        return <SubmitProject formField={formField} values={values} render={RenderComponent} />;
      default:
        return <div>Not Found</div>;
    }

  }

}