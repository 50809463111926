import { styled } from '@mui/system';
import { Button } from "@mui/material";

//themes
import themes from "../../themes/defaultTheme";

export const StyledButton = styled(Button)({
    textTransform: 'none',
    color: themes.default.palette.primary.main,
    fontWeight: 'bold',
    paddingLeft: 0
});