import React from 'react';
import { useField } from 'formik';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Tooltip,
  Box,
} from '@mui/material';

export default function RadioButtonsGroup(props) {
  const { label, data, tooltip, ...rest } = props;
  const [field, meta, helpers] = useField(props); // Use Formik's useField hook
  const { setValue } = helpers;
  const { value: selectedValue } = field;
  const isError = meta.touched && Boolean(meta.error); // Check if there's an error

  // Transform boolean values to "Yes" or "No" for the RadioGroup
  const transformedValue =
    selectedValue === true ? 'Yes' : selectedValue === false ? 'No' : selectedValue;

  const handleChange = (event) => {
    const newValue = event.target.value === 'Yes' ? true : event.target.value === 'No' ? false : event.target.value;
    setValue(newValue); // Update Formik's state with the boolean value
  };

  
  return (
    <FormControl component="fieldset" {...rest} error={isError}>
      <Box display="flex" alignItems="center">
        <FormLabel sx={{ paddingRight: '10px' }}>{label}</FormLabel>
        <Tooltip title={tooltip || ''} placement="right">
          <RadioGroup
            row
            name={field.name} // Use Formik's field name
            value={transformedValue || ''} // Controlled value
            onChange={handleChange} // Handle changes
          >
            {data.map((item, index) => (
              <FormControlLabel
                key={item.label + index}
                value={item.value} // Bind value to the item's value
                control={<Radio sx={{ transform: 'scale(0.8)' }} />}
                label={<span style={{ fontSize: '0.9rem' }}>{item.label}</span>}
              />
            ))}
          </RadioGroup>
        </Tooltip>
      </Box>
      {/* {isError && <FormHelperText>{meta.error}</FormHelperText>} */}
    </FormControl>
  );
}
