import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const StyledTypography = styled(Typography)({
    marginTop: "2px"
});

export const StyledOuterDiv = styled('div')({
    display: 'flex',
    padding: '0 10px'
});

export const StyledInnerDiv = styled('div')({
    width: '20px',
    height: '20px',
    marginRight: '10px',
    border: '1px solid'
});