import Box from '@mui/material/Box';
import { Typography, Button } from '@mui/material';

import RenderComponent from "../../pages/Projects/AddNewProject/RenderComponent";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

export default function EditProjectDialog({
  open,
  setOpen,
  title,
  activeStep,
  handleClose,
  render,
  values,
  steps,
  setstep,
  edit
}) {

  // When close is clicked
  const handleCloseClick = () => {
    if (edit === "Edit Existing Project") {
      handleClose(values);
    }
    else
      handleClose();
  }
  return (
    <Dialog aria-label="Edit project Dialog" open={open}   maxWidth={false} // Removes the maxWidth restriction
    fullWidth={true} // Ensures the dialog uses the full width
    PaperProps={{
      style: {
        width: '100% !important', // Ensures the paper uses the full width
        maxWidth: 'none !important', // Removes any maxWidth restriction
        overflowX: 'hidden', // Hides the horizontal overflow
      },
    }} >
      <DialogTitle aria-label="Dialog Title" id="alert-dialog-title" style={{ textAlign: "center", fontWeight: "bold" }}>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography sx={{ mt: 2 }}>

            {edit === "Edit New Project" && render(activeStep, values, steps, setstep)}

            {edit === "Edit Existing Project" && <RenderComponent activeStep={activeStep} values={values} steps={steps} setSteps={setstep} />}

          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button aria-label="Close" onClick={() => handleCloseClick()} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}